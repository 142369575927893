import Vue from "vue";
import Component from "vue-class-component";
import { IRequest } from "@/models";
import { RequestStatuses } from "@/enums";
import { requestStatusService } from "@/services";

@Component
export class RequestStatusMixin extends Vue {
    isRequestPending(request: IRequest): boolean {
        return request.requestStatusId === RequestStatuses.Pending;
    }

    isRequestValidated(request: IRequest): boolean {
        return request.requestStatusId === RequestStatuses.Validated;
    }

    isRequestRefused(request: IRequest): boolean {
        return request.requestStatusId === RequestStatuses.Refused;
    }

    getStatusName(requestStatusId: number): string {
        return requestStatusService.getStatusName(requestStatusId);
    }

    getStatusColor(requestStatusId: number): string {
        return requestStatusService.getStatusColor(requestStatusId);
    }
}
