import { IApp } from "@/models";

import { BaseRepository } from "@/repositories";

class AppRepository extends BaseRepository<IApp> {
    protected getAllErrorMessage = "Applications cannot be retrieved.";

    protected getOneErrorMessage = "This Application cannot be retrieved.";

    protected addErrorMessage = "This Application cannot be created, please verify fields and code unicity.";

    protected updateErrorMessage = "This Application cannot be saved, please verify fields and code unicity.";

    protected replaceErrorMessage = "This Application cannot be replaced, please verify fields and code unicity.";

    protected removeErrorMessage = "This Application cannot be removed.";

    constructor() {
        super("/core/apps");
    }

    public async getAllApplications(includeInactive: boolean): Promise<IApp[]> {
        return await this.getAll({
            includeInactive,
        });
    }

    public async replaceImage(appId: number, image: File): Promise<IApp> {
        const formData = new FormData();
        formData.append("file", image);

        return await this.replace(formData, `/${appId}`, "multipart/form-data") as IApp;
    }
}

const appRepository = new AppRepository();

export { AppRepository, appRepository };
