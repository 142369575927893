import { IMdhUserRole } from "@/models";

import { BaseRepository } from "@/repositories";

class MdhValidatorRepository extends BaseRepository<IMdhUserRole> {
    protected getAllErrorMessage = "Validators cannot be retrieved.";

    protected getOneErrorMessage = "This Validator cannot be retrieved.";

    protected addErrorMessage = "This Validator cannot be created, please verify fields.";

    protected updateErrorMessage = "This Validator cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Validator cannot be removed.";

    constructor() {
        super("/mdh/validators");
    }
}

const mdhValidatorRepository = new MdhValidatorRepository();

export { MdhValidatorRepository, mdhValidatorRepository };
