export enum MasterRoles {
    MasterDataCollaborator = 1,
    MasterDataValidator = 2,
    MasterDataOwner = 3,
    AffiliateCollaborator = 4,
    AffiliateValidator = 5,
    TransactionalDataUploader = 6,
    MasterDataCollaboratorValidator = 7,
    ReadOnlyLocal = 8,
    MasterDataReader = 9,
    AffiliateMasterDataChampion = 10,
    SuperReaderOnly = 11,
}
