import Vue from "vue";
import Component from "vue-class-component";
import { IRequest } from "@/models";

@Component
export class RequestCommentsMixin extends Vue {
    public requestCommentsPopup = false;

    public requestForComments: IRequest | null = null;

    openRequestComments(request: IRequest): void {
        this.requestForComments = request;
        this.requestCommentsPopup = true;
    }

    closeRequestComments(): void {
        this.requestCommentsPopup = false;
        this.requestForComments = null;
    }
}
