import { IPnl } from "@/models";

import { BaseRepository } from "@/repositories";

class PnlRepository extends BaseRepository<IPnl> {
    protected getAllErrorMessage = "P&L cannot be retrieved.";

    protected getOneErrorMessage = "This P&L cannot be retrieved.";

    protected addErrorMessage = "This P&L cannot be created, please verify fields.";

    protected updateErrorMessage = "This P&L cannot be updated, please verify fields.";

    protected removeErrorMessage = "This P&L cannot be removed.";

    constructor() {
        super("/dls/pnls");
    }
}

const pnlRepository = new PnlRepository();

export { PnlRepository, pnlRepository };
