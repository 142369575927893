import { ActionTree } from "vuex";
import {
    polarisBiCoreProjectRepository,
    polarisBiCostCenterRepository,
    polarisBiEntityRepository,
    polarisBiFunctionRepository,
    polarisBiMarketRepository,
    polarisBiModuleRepository,
    polarisBiPermissionRepository,
    polarisBiPnlRepository,
    polarisBiPrGroupValidatorRepository,
    polarisBiProductRepository,
    polarisBiProductValidatorRepository,
    polarisBiProjectRepository,
    polarisBiValidatorRepository,
} from "@/repositories";
import { IPolarisBiState } from "./state";
import * as mutation from "./mutations-types";
import {
    IEntity,
    IPolarisBiCoreProject,
    IPolarisBiFunction,
    IPolarisBiPermissionCreate,
    IPolarisBiProduct,
    IPolarisBiRequest,
    IUserGraph,
} from "@/models";
import { RequestStatuses } from "@/enums";
import * as appMutation from "@/store/app/mutations-types";

export const actions: ActionTree<IPolarisBiState, any> = {

    addEntity: async (context, payload: IEntity): Promise<IEntity> => {
        const entity = await polarisBiEntityRepository.add(payload);
        context.commit(`app/${appMutation.ADD_ENTITY}`, entity, { root: true });
        return entity as IEntity;
    },

    addFunction: async (context, payload: IPolarisBiFunction): Promise<void> => {
        const func = await polarisBiFunctionRepository.add(payload);
        context.commit(mutation.ADD_FUNCTION, func);
    },

    addPermission: async (context, payload: IPolarisBiPermissionCreate): Promise<IPolarisBiRequest[]> => {
        const requests = await polarisBiPermissionRepository.add(payload) as IPolarisBiRequest[];
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
        return requests;
    },

    addRequestFiles: async (context, payload: { requestIds: number[]; files: File[] }): Promise<void> => {
        const requests = await polarisBiPermissionRepository.updateRequestFiles(payload.requestIds, payload.files);
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
    },

    deleteEntity: async (context, payload: IEntity): Promise<void> => {
        const entities = await polarisBiEntityRepository.remove(payload.entityId);
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    exportHierarchy: async (): Promise<Blob> => await polarisBiEntityRepository.download("export"),

    exportUserAccess: async (): Promise<Blob> => await polarisBiPermissionRepository.download("export"),

    importValidators: async (context, payload: File): Promise<number> => {
        const validators = await polarisBiValidatorRepository.importValidators(payload);
        context.commit(mutation.SET_POLARIS_USER_ROLES, validators);
        return validators.filter(v => v.isDirectPermission).length;
    },

    loadCoreProjects: async (context): Promise<void> => {
        const projects = await polarisBiCoreProjectRepository.getAll();
        context.commit(mutation.SET_CORE_PROJECTS, projects);
    },

    loadCostCenters: async (context, payload: { entityIds: number[] }): Promise<void> => {
        const params = new URLSearchParams();

        payload.entityIds.forEach((id, index) => {
            params.set(`entityIds[${index}]`, id.toString());
        });

        const costCenters = await polarisBiCostCenterRepository.getAll(params);
        context.commit(mutation.SET_COST_CENTERS, costCenters);
    },

    loadEntities: async (context): Promise<void> => {
        const entities = await polarisBiEntityRepository.getAll();
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    loadFunctions: async (context): Promise<void> => {
        const functions = await polarisBiFunctionRepository.getAll();
        context.commit(mutation.SET_FUNCTIONS, functions);
    },

    loadMarkets: async (context, payload: { entityIds: number[] }): Promise<void> => {

        const params = new URLSearchParams();

        payload.entityIds.forEach((id, index) => {
            params.set(`entityIds[${index}]`, id.toString());
        });

        const markets = await polarisBiMarketRepository.getAll(params);
        context.commit(mutation.SET_MARKETS, markets);
    },

    loadModules: async (context): Promise<void> => {
        const modules = await polarisBiModuleRepository.getAll();
        context.commit(mutation.SET_MODULES, modules);
    },

    loadMyRequests: async (context): Promise<void> => {
        const myRequests = await polarisBiPermissionRepository.getAll({}, "my-requests") as IPolarisBiRequest[];
        context.commit(mutation.SET_MY_REQUESTS, myRequests);
    },

    loadPendingRequests: async (context): Promise<void> => {
        const pendingRequests = await polarisBiPermissionRepository.getAll({}, "pending-requests") as IPolarisBiRequest[];
        context.commit(mutation.SET_PENDING_REQUESTS, pendingRequests);
    },

    loadPnls: async (context): Promise<void> => {
        const pnls = await polarisBiPnlRepository.getAll();
        context.commit(mutation.SET_PNLS, pnls.filter(p => p.isVisible));
    },

    loadPrGroupValidators: async (context): Promise<void> => {
        const validators = await polarisBiPrGroupValidatorRepository.getAll();
        context.commit(mutation.SET_POLARIS_PR_GROUP_VALIDATORS, validators);
    },

    loadProducts: async (context): Promise<void> => {
        const products = await polarisBiProductRepository.getAll();
        context.commit(mutation.SET_PRODUCTS, products);
    },

    loadProductValidators: async (context): Promise<void> => {
        const validators = await polarisBiProductValidatorRepository.getAll();
        context.commit(mutation.SET_POLARIS_PRODUCT_USER_ROLES, validators);
    },

    loadProjects: async (context, payload: { entityIds: number[]; source: string }): Promise<void> => {

        const params = new URLSearchParams();

        params.set("source", payload.source);

        if(payload.entityIds) {
            payload.entityIds.forEach((id, index) => {
                params.set(`entityIds[${index}]`, id.toString());
            });
        }

        const projects = await polarisBiProjectRepository.getAll(params);
        context.commit(mutation.SET_PROJECTS, projects);
    },

    loadValidatedRequests: async (context): Promise<void> => {
        const validatedRequests = await polarisBiPermissionRepository.getAll({}, "validated-requests") as IPolarisBiRequest[];
        context.commit(mutation.SET_VALIDATED_REQUESTS, validatedRequests);
    },

    loadValidators: async (context): Promise<void> => {
        const validators = await polarisBiValidatorRepository.getAll();
        context.commit(mutation.SET_POLARIS_USER_ROLES, validators);
    },

    rejectRequests: async (context, payload: { requests: IPolarisBiRequest[]; comment: string | null; fromUserAccess: boolean | null }): Promise<void> => {
        const rejectedRequests = await polarisBiPermissionRepository.add({
            requests: payload.requests,
            comment: payload.comment,
            fromUserAccess: payload.fromUserAccess,
        }, "refuse") as IPolarisBiRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, rejectedRequests);
    },

    setEntityValidators: async (context, payload: { entityId: number | null; users: IUserGraph[] }): Promise<void> => {
        const validators = await polarisBiValidatorRepository.setValidators(payload.entityId, payload.users);
        context.commit(mutation.SET_POLARIS_USER_ROLES, validators);
    },

    setPrGroupValidators: async (context, payload: { users: IUserGraph[] }): Promise<void> => {
        const validators = await polarisBiPrGroupValidatorRepository.setPrGroupValidators(payload.users);
        context.commit(mutation.SET_POLARIS_PR_GROUP_VALIDATORS, validators);
    },

    setProductValidators: async (context, payload: { productId: number | null; users: IUserGraph[] }): Promise<void> => {
        const validators = await polarisBiProductValidatorRepository.setProductValidators(payload.productId, payload.users);
        context.commit(mutation.SET_POLARIS_PRODUCT_USER_ROLES, validators);
    },

    updateCoreProject: async (context, payload: IPolarisBiCoreProject): Promise<IPolarisBiCoreProject> => {
        const updatedCoreProject = await polarisBiCoreProjectRepository.update(payload, `/${payload.coreProjectId}`);
        context.commit(mutation.UPDATE_CORE_PROJECT, updatedCoreProject);
        return updatedCoreProject;
    },

    updateEntity: async (context, payload: IEntity): Promise<IEntity> => {
        const updatedEntity = await polarisBiEntityRepository.update(payload, `/${payload.entityId}`);
        context.commit(`app/${appMutation.UPDATE_ENTITY}`, updatedEntity, { root: true });
        return updatedEntity;
    },

    updateFunction: async (context, func: IPolarisBiFunction): Promise<void> => {
        const updatedFunction = await polarisBiFunctionRepository.update(func, `/${func.functionId}`);
        context.commit(mutation.UPDATE_FUNCTION, updatedFunction);
    },

    updatePnlByFunction: async (context, functions: IPolarisBiFunction[]): Promise<void> => {
        const payload = functions.map(func => {
            const pnlIds: number[] = [];

            for (const pnlId in func.tablePnls) {
                if (func.tablePnls[pnlId] && func.tablePnls[pnlId] === true) {
                    pnlIds.push(parseInt(pnlId, 10));
                }
            }

            return {
                functionId: func.functionId,
                pnlIds,
            };
        });
        const updatedFunctions = await polarisBiFunctionRepository.replace(payload);
        context.commit(mutation.SET_FUNCTIONS, updatedFunctions);
    },

    updateProduct: async (context, payload: IPolarisBiProduct): Promise<IPolarisBiProduct> => {
        const updatedProduct = await polarisBiProductRepository.update(payload, `/${payload.productId}`);
        context.commit(mutation.UPDATE_PRODUCT, updatedProduct);
        return updatedProduct;
    },

    validateRequests: async (context, payload: { requests: IPolarisBiRequest[]; comment: string | null }): Promise<void> => {
        const validatedRequests = await polarisBiPermissionRepository.add({
            requests: payload.requests,
            comment: payload.comment,
        }, "validate") as IPolarisBiRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, validatedRequests);
    },
};
