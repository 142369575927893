import Component, { mixins } from "vue-class-component";
import { ApplicationCodeMixin } from "@/mixins";
import { IPolarisBiRequest } from "@/models";
import { CommentOptions, RequestStatuses } from "@/enums";
import { WaitKeys } from "@/helpers";

@Component
export class PolarisBiValidateRequestMixin extends mixins(ApplicationCodeMixin) {
    public validateModal = false;

    public isValidating = false;

    public isRejecting = false;

    public fromUserAccess = false;

    public validateComment: string | null = null;

    public selectedRequests: IPolarisBiRequest[] = [];

    get loadingRequests(): boolean {
        return this.$wait.is(WaitKeys.polarisBiRequests);
    }

    get isCommentDisplayed(): boolean {
        if (this.isValidating) {
            return this.isValidateCommentDisplayed;
        }
        else if (this.isRejecting) {
            return this.isRejectCommentDisplayed;
        }

        return false;
    }

    get isCommentRequired(): boolean {
        if (this.isValidating) {
            return this.isValidateCommentRequired;
        }
        else if (this.isRejecting) {
            return this.isRejectCommentRequired;
        }

        return false;
    }

    get isValidateCommentDisplayed(): boolean {
        return this.application?.validateCommentOptionId !== CommentOptions.None ?? false;
    }

    get isValidateCommentRequired(): boolean {
        return this.application?.validateCommentOptionId === CommentOptions.Required ?? false;
    }

    get isRejectCommentDisplayed(): boolean {
        return this.application?.refuseCommentOptionId !== CommentOptions.None ?? false;
    }

    get isRejectCommentRequired(): boolean {
        return this.application?.refuseCommentOptionId === CommentOptions.Required ?? false;
    }

    closeValidateModal(): void {
        this.isValidating = false;
        this.isRejecting = false;
        this.validateModal = false;
    }

    confirmValidateModal(comment: string): void {
        const requestIds: number[] = [];
        let havePnlsOnError = false;
        let haveCoreProjectsOnError = false;

        // Do not use pnls or coreProjects : It's the value of the request in DB and not the one that you will update
        this.selectedRequests.forEach(r => {
            const isModulePrGroupOrMU = r.module.code === "PRGROUP" || r.module.code === "MU";
            const pnlsIsNotEmpty = r.pnlIds?.length < 1;
            const coreProjectsIsNotEmpty = r.coreProjectIds?.length < 1;

            if (isModulePrGroupOrMU && (pnlsIsNotEmpty || coreProjectsIsNotEmpty)) {
                requestIds.push(r.requestId);
                havePnlsOnError = pnlsIsNotEmpty;
                haveCoreProjectsOnError = coreProjectsIsNotEmpty;
            }
        });

        if (requestIds.length === 0) {
            const status = this.isValidating ? RequestStatuses.Validated : RequestStatuses.Refused;
            this.validateComment = comment;
            this.confirmValidateRequests(status);
        }
        else {
            const message: string = requestIds.toString();
            this.$emit("error", `Error on request(s) id : ${message}`);
            if (havePnlsOnError) {
                this.$emit("error", "Pnls must be provided and not empty");
            }
            if (haveCoreProjectsOnError) {
                this.$emit("error", "Core projects must be provided and not empty");
            }
        }
    }

    validateRequest(request: IPolarisBiRequest): void {
        this.selectedRequests = [request];
        this.validateRequests();
    }

    rejectRequest(request: IPolarisBiRequest, fromUserAccess = false): void {
        this.selectedRequests = [request];
        this.rejectRequests(fromUserAccess);
    }

    validateRequests(): void {
        if (this.selectedRequests.length === 1 && !this.isValidateCommentDisplayed)  {
            this.confirmValidateRequests(RequestStatuses.Validated);
        }
        else {
            this.isValidating = true;
            this.isRejecting = false;
            this.validateModal = true;
        }
    }

    rejectRequests(fromUserAccess = false): void {
        if (this.selectedRequests.length === 1 && !this.isRejectCommentDisplayed)  {
            this.fromUserAccess = fromUserAccess;
            this.confirmValidateRequests(RequestStatuses.Refused);
        }
        else {
            this.isValidating = false;
            this.isRejecting = true;
            this.validateModal = true;
            this.fromUserAccess = fromUserAccess;
        }
    }

    async confirmValidateRequests(status: RequestStatuses): Promise<void> {
        this.$wait.start(WaitKeys.polarisBiRequests);
        const action = status === RequestStatuses.Validated ? "validateRequests" : "rejectRequests";
        const message = status === RequestStatuses.Validated ? "validated" : "removed";
        const numberOfRequests = this.selectedRequests.length;
        try {
            await this.$store.dispatch(`polarisbi/${action}`, {
                requests: this.selectedRequests,
                comment: this.validateComment,
                fromUserAccess: this.fromUserAccess,
            });
            this.selectedRequests = [];
            this.validateComment = null;
            this.isValidating = false;
            this.isRejecting = false;
            this.validateModal = false;
            this.$emit("success", `You have successfully ${message} ${numberOfRequests} request(s).`);
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.$wait.end(WaitKeys.polarisBiRequests);
    }
}
