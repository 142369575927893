import Component, { mixins } from "vue-class-component";
import { ApplicationCodeMixin } from "@/mixins";
import { IXtrtRequest } from "@/models/xtrt";
import { CommentOptions, RequestStatuses } from "@/enums";
import { WaitKeys } from "@/helpers";

@Component
export class XtrtValidateRequestMixin extends mixins(ApplicationCodeMixin) {
    public validateModal = false;

    public rejectModal = false;

    public isValidating = false;

    public isRejecting = false;

    public fromUserAccess = false;

    public validateComment: string | null = null;

    public selectedRequests: IXtrtRequest[] = [];

    get loadingRequests(): boolean {
        return this.$wait.is(WaitKeys.xtrtRequests);
    }

    get isCommentDisplayed(): boolean {
        if (this.isValidating) {
            return this.isValidateCommentDisplayed;
        }
        else if (this.isRejecting) {
            return this.isRejectCommentDisplayed;
        }

        return false;
    }

    get isCommentRequired(): boolean {
        if (this.isValidating) {
            return this.isValidateCommentRequired;
        }
        else if (this.isRejecting) {
            return this.isRejectCommentRequired;
        }

        return false;
    }

    get isValidateCommentDisplayed(): boolean {
        return this.application?.validateCommentOptionId !== CommentOptions.None ?? false;
    }

    get isValidateCommentRequired(): boolean {
        return this.application?.validateCommentOptionId === CommentOptions.Required ?? false;
    }

    get isRejectCommentDisplayed(): boolean {
        return this.application?.refuseCommentOptionId !== CommentOptions.None ?? false;
    }

    get isRejectCommentRequired(): boolean {
        return this.application?.refuseCommentOptionId === CommentOptions.Required ?? false;
    }

    get newInternalLabel(): string {
        const length = this.selectedRequests?.length ?? 0;
        return this.selectedRequests[length - 1]?.isInternal ? "internal" : "external";
    }

    get newRoleLabel(): string {
        const length = this.selectedRequests?.length ?? 0;
        return this.selectedRequests[length - 1]?.role?.name.toLowerCase();
    }

    closeValidateModal(): void {
        this.isValidating = false;
        this.isRejecting = false;
        this.validateModal = false;
    }

    confirmValidateModal(comment: string): void {
        const status = this.isValidating ? RequestStatuses.Validated : RequestStatuses.Refused;
        this.validateComment = comment;
        this.confirmValidateRequests(status);
    }

    validateRequest(request: IXtrtRequest): void {
        this.selectedRequests = [request];
        this.validateRequests();
    }

    rejectRequest(request: IXtrtRequest, fromUserAccess = false): void {
        this.selectedRequests = [request];
        this.rejectRequests(fromUserAccess);
    }

    validateRequests(): void {
        if (this.selectedRequests.length === 1 && !this.isValidateCommentDisplayed)  {
            this.confirmValidateRequests(RequestStatuses.Validated);
        }
        else {
            this.isValidating = true;
            this.isRejecting = false;
            this.validateModal = true;
        }
    }

    rejectRequests(fromUserAccess = false): void {
        if (this.selectedRequests.length === 1 && !this.isRejectCommentDisplayed)  {
            this.fromUserAccess = fromUserAccess;
            this.confirmValidateRequests(RequestStatuses.Refused);
        }
        else {
            this.isValidating = false;
            this.isRejecting = true;
            this.validateModal = true;
            this.fromUserAccess = fromUserAccess;
        }
    }

    async confirmValidateRequests(status: RequestStatuses): Promise<void> {
        this.$wait.start(WaitKeys.xtrtRequests);
        const action = status === RequestStatuses.Validated ? "validateRequests" : "rejectRequests";
        const message = status === RequestStatuses.Validated ? "validated" : "removed";
        const numberOfRequests = this.selectedRequests.length;

        try {
            await this.$store.dispatch(`xtrt/${action}`, {
                appCode: this.application.code,
                requestIds: this.selectedRequests.map(r => r.requestId),
                comment: this.validateComment,
                fromUserAccess: this.fromUserAccess,
            });

            this.selectedRequests = [];
            this.validateComment = null;
            this.isValidating = false;
            this.isRejecting = false;
            this.validateModal = false;
            this.$emit("success", `You have successfully ${message} ${numberOfRequests} request(s).`);
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.$wait.end(WaitKeys.xtrtRequests);
    }
}
