import Vue from "vue";
import "vue-class-component/hooks";
import VueWait from "vue-wait";
import { VueDateFilter } from "@/filters";
import { DataTableMixin } from "@/mixins";
import { vueAppInsights, vuetify } from "@/plugins";
import { router } from "@/router";
import "@/router/registerHooks";
import { store } from "@/store";
import App from "./App.vue";
import "./plugins/vee-validate.ts";
import "./plugins/vue-treeselect";
import "./plugins/notifications";

Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV !== "production";

// Setup App Insights
vueAppInsights();

Vue.use(VueWait);

Vue.filter("dateFns", VueDateFilter);

Vue.mixin(DataTableMixin);

new Vue({
    router,
    store,
    wait: new VueWait({ useVuex: true }),
    vuetify,
    render: h => h(App),
}).$mount("#app");
