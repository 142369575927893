import { ISharePointAccount } from "@/models";

import { BaseRepository } from "@/repositories";

class SharePointAccountRepository extends BaseRepository<ISharePointAccount> {
    protected getAllErrorMessage = "SharePoint Accounts cannot be retrieved.";

    protected getOneErrorMessage = "This SharePoint Account cannot be retrieved.";

    protected addErrorMessage = "This SharePoint Account cannot be created, please verify fields.";

    protected updateErrorMessage = "This SharePoint Account cannot be updated, please verify fields.";

    protected removeErrorMessage = "This SharePoint Account cannot be removed.";

    constructor() {
        super("/sgp/share-point-accounts");
    }
}

const sharePointAccountRepository = new SharePointAccountRepository();

export { SharePointAccountRepository, sharePointAccountRepository };
