import { IPolarisBiRequest } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiPermissionRepository extends BaseRepository<IPolarisBiRequest> {
    protected getAllErrorMessage = "Requests cannot be retrieved.";

    protected getOneErrorMessage = "Request cannot be retrieved.";

    protected addErrorMessage = "Request cannot be created or validated/removed, please verify fields.";

    protected replaceErrorMessage = "Request files cannot be added to your request(s).";

    constructor() {
        super("/polarisbi/permissions");
    }

    public async updateRequestFiles(requestIds: number[], files: File[]): Promise<IPolarisBiRequest[]> {
        const formData = new FormData();
        requestIds.forEach((requestId, index) => formData.append(`requestIds[${index}]`, requestId.toString()));
        files.forEach((file, index) => formData.append(`files[${index}]`, file));

        return await this.replace(formData, "/", "multipart/form-data") as IPolarisBiRequest[];
    }
}

const polarisBiPermissionRepository = new PolarisBiPermissionRepository();

export { PolarisBiPermissionRepository, polarisBiPermissionRepository };
