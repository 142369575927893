import { IEntityHierarchy } from "@/models";

import { BaseRepository } from "@/repositories";

class EntityHierarchyRepository extends BaseRepository<IEntityHierarchy> {
    protected getAllErrorMessage = "Entity Hierarchies cannot be retrieved.";

    protected getOneErrorMessage = "This Entity Hierarchy cannot be retrieved.";

    protected addErrorMessage = "This Entity Hierarchy cannot be created, please verify fields.";

    protected updateErrorMessage = "This Entity Hierarchy cannot be updated, please verify fields.";

    protected replaceErrorMessage = "This Entity Hierarchy cannot be replaced, please verify fields.";

    protected removeErrorMessage = "This Entity Hierarchy cannot be removed, please verify it has neither Entities or Slaves.";

    constructor() {
        super("/core/entity-hierarchies");
    }
}

const entityHierarchyRepository = new EntityHierarchyRepository();

export { EntityHierarchyRepository, entityHierarchyRepository };
