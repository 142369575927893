import { Module } from "vuex";
import { initialState, IDlsState } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const dls: Module<IDlsState, any> = {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

export { dls };
