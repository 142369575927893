import { IPolarisBiPnl } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiPnlRepository extends BaseRepository<IPolarisBiPnl> {
    protected getAllErrorMessage = "P&L cannot be retrieved.";

    protected getOneErrorMessage = "This P&L cannot be retrieved.";

    protected addErrorMessage = "This P&L cannot be created, please verify fields.";

    protected updateErrorMessage = "This P&L cannot be updated, please verify fields.";

    protected removeErrorMessage = "This P&L cannot be removed.";

    constructor() {
        super("/polarisbi/pnls");
    }
}

const polarisBiPnlRepository = new PolarisBiPnlRepository();

export { PolarisBiPnlRepository, polarisBiPnlRepository };
