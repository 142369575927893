import Vue from "vue";
import Component from "vue-class-component";
import { IMasterData } from "@/models";
import { buildLookup } from "@/helpers";

@Component
export class MdhHierarchyMasterDatasMixin extends Vue {
    public getMasterDatasHierarchy(flattenMasterDatas: IMasterData[]): IMasterData[] {
        const childHashes = buildLookup(flattenMasterDatas, md => md.parentMasterDataId);
        let rootMasterDatas = flattenMasterDatas.filter(e => e.parentMasterDataId === null);

        if (rootMasterDatas.length === 0) {
            const masterDataIds = flattenMasterDatas.map(e => e.masterDataId);
            rootMasterDatas = flattenMasterDatas.filter(e => !masterDataIds.includes(e.parentMasterDataId));
        }

        rootMasterDatas.forEach(md => {
            md.children = childHashes[md.masterDataId]?.flatMap(child => this.getChildren(childHashes, child)) ?? [];
        });

        return rootMasterDatas.flatMap(e => e.isActive ? [e] : e.children);
    }

    protected getChildren(lookup: Record<number, IMasterData[]>, child: IMasterData): IMasterData[] {
        if (child.isActive) {
            child.children = lookup[child.masterDataId]?.flatMap(subChild => this.getChildren(lookup, subChild)) ?? [];
            return [child];
        }
        else {
            return lookup[child.masterDataId]?.flatMap(subChild => this.getChildren(lookup, subChild)) ?? [];
        }
    }
}
