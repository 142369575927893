import {
    IAppAzureAdGroup,
    IEntity,
    IFunction,
    IFunctionCategory,
    IFunctionGroup,
    IMasterData,
    IMasterRole,
    IUser,
    IXtrtAgency,
    IXtrtRole,
} from "@/models";

class SortService {
    public static sort<T>(a: T, b: T, property: keyof T): number {
        if (a[property] > b[property]) {
            return 1;
        }
        else if (a[property] < b[property]) {
            return -1;
        }

        return 0;
    }

    public sortBoolean(a: boolean, b: boolean): number {
        if (a === b) {
            return 0;
        }

        return a > b ? 1 : -1;
    }

    public sortEntity(a: IEntity, b: IEntity): number {
        if (!a) {
            return -1;
        }
        else if (!b) {
            return 1;
        }

        return SortService.sort(a, b, "name");
    }

    public sortFunction(a: IFunction, b: IFunction): number {
        return SortService.sort(a, b, "name");
    }

    public sortFunctionCategory(a: IFunctionCategory, b: IFunctionCategory): number {
        if (!a) {
            return -1;
        }
        else if (!b) {
            return 1;
        }

        return SortService.sort(a, b, "name");
    }

    public sortFunctionGroup(a: IFunctionGroup, b: IFunctionGroup): number {
        if (!a) {
            return -1;
        }
        else if (!b) {
            return 1;
        }

        return SortService.sort(a, b, "name");
    }

    public sortGroup(a: IAppAzureAdGroup, b: IAppAzureAdGroup): number {
        return SortService.sort(a, b, "name");
    }

    public sortMasterData(a: IMasterData, b: IMasterData): number {
        if (!a) {
            return -1;
        }
        else if (!b) {
            return 1;
        }

        return SortService.sort(a, b, "name");
    }

    public sortMasterRole(a: IMasterRole, b: IMasterRole): number {
        return SortService.sort(a, b, "name");
    }

    public sortUser(a: IUser, b: IUser): number {
        if (!a) {
            return -1;
        }
        else if (!b) {
            return 1;
        }

        return SortService.sort(a, b, "email");
    }

    public sortXtrtAgency(a: IXtrtAgency, b: IXtrtAgency): number {
        if (!a) {
            return -1;
        }
        else if (!b) {
            return 1;
        }

        return SortService.sort(a, b, "name");
    }

    public sortXtrtRole(a: IXtrtRole, b: IXtrtRole): number {
        if (!a) {
            return -1;
        }
        else if (!b) {
            return 1;
        }

        return SortService.sort(a, b, "name");
    }
}

const sortService = new SortService();

export { SortService, sortService };
