import Vue from "vue";
import Component from "vue-class-component";
import { IRequest } from "@/models";

@Component
export class RequestAttachmentsMixin extends Vue {
    public requestAttachmentsPopup = false;

    public requestForAttachments: IRequest | null = null;

    openRequestAttachments(request: IRequest): void {
        this.requestForAttachments = request;
        this.requestAttachmentsPopup = true;
    }

    closeRequestAttachments(): void {
        this.requestAttachmentsPopup = false;
        this.requestForAttachments = null;
    }
}
