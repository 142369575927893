import { GetterTree } from "vuex";
import { IXtrtState } from "@/store/xtrt/state";
import { IRequest, IXtrtUserRole, IXtrtValidatedPermission } from "@/models";

const getters: GetterTree<IXtrtState, any> = {
    myPermissions: (state): IXtrtValidatedPermission[] => state.myPermissions,

    myRequests: (state): IRequest[] => state.myRequests,

    pendingRequests: (state): IRequest[] => state.pendingRequests,

    userRoles: (state): IXtrtUserRole[] => state.userRoles,

    validatedRequests: (state): IRequest[] => state.validatedRequests,
};

export { getters };
