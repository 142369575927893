import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const dlsRoutes: RouteConfig[] = [
    {
        path: "/dls",
        component: (): any => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "manage/functions",
                name: RouteNames.dlsSettingsFunctions,
                component: () => import(/* webpackChunkName: "DlsSettingsFunction" */ "@/views/dls/settings/DlsSettingsFunction.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        dls: true,
                    },
                },
            },
            {
                path: "manage/groups",
                name: RouteNames.dlsSettingsFunctionGroups,
                component: () => import(/* webpackChunkName: "DlsSettingsFunctionGroup" */ "@/views/dls/settings/DlsSettingsFunctionGroup.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        dls: true,
                    },
                },
            },
            {
                path: "manage/hierarchy",
                name: RouteNames.dlsSettingsHierarchy,
                component: () => import(/* webpackChunkName: "DlsSettingsHierarchy" */ "@/views/dls/settings/DlsSettingsHierarchy.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        dls: true,
                    },
                },
            },
            {
                path: "manage/pnls",
                name: RouteNames.dlsSettingsPnl,
                component: () => import(/* webpackChunkName: "DlsSettingsPnl" */ "@/views/dls/settings/DlsSettingsPnl.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        dls: true,
                    },
                },
            },
            {
                path: "manage/accounts",
                name: RouteNames.dlsSettingsAccounts,
                component: () => import(/* webpackChunkName: "DlsSettingsAccount" */ "@/views/dls/settings/DlsSettingsAccount.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        dls: true,
                    },
                },
            },
            {
                path: "manage/options",
                name: RouteNames.dlsSettingsOptions,
                component: () => import(/* webpackChunkName: "DlsSettingsOptions" */ "@/views/dls/settings/DlsSettingsOptions.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        dls: true,
                    },
                },
            },
            {
                path: "permissions",
                name: RouteNames.dlsHome,
                component: () => import(/* webpackChunkName: "DlsHome" */ "@/views/dls/home/DlsHome.vue"),
                meta: {
                    sidebar: {
                        logo: true,
                        description: true,
                        dls: true,
                    },
                },
            },
            {
                path: "pending-requests",
                name: RouteNames.dlsPendingRequests,
                component: () => import(/* webpackChunkName: "DlsPendingRequest" */ "@/views/dls/pendingRequest/DlsPendingRequest.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        dls: true,
                    },
                },
            },
            {
                path: "user-access",
                name: RouteNames.dlsUserAccess,
                component: () => import(/* webpackChunkName: "DlsUserAccess" */ "@/views/dls/userAccess/DlsUserAccess.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        dls: true,
                    },
                },
            },
        ],
        meta: {
            code: "dls",
            requiresMinRole: {
                roleId: 1,
                appId: 2,
            },
        },
    },
];
