import { IUserGraph } from "@/models";

import { BaseRepository } from "@/repositories";

class GroupMemberRepository extends BaseRepository<IUserGraph> {
    protected removeErrorMessage = "This User cannot be removed from this Group.";

    constructor() {
        super("/group/members");
    }

    async removeUserFromGroup(appCode: string, appAzureAdGroupId: number, azureUser: IUserGraph): Promise<IUserGraph[]> {
        return await this.remove(appAzureAdGroupId, `/${appCode}/${azureUser.id}`) as IUserGraph[];
    }
}

const groupMemberRepository = new GroupMemberRepository();

export { GroupMemberRepository, groupMemberRepository };
