import { BaseRepository } from "./BaseRepository";

export { BaseRepository };
export * from "./core";
export * from "./dls";
export * from "./group";
export * from "./mdh";
export * from "./polarisBi";
export * from "./powerBi";
export * from "./sgp";
export * from "./simple";
