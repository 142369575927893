const ADD_APPLICATION = "ADD_APPLICATION";
const ADD_CATEGORY = "ADD_CATEGORY";
const ADD_ENTITY = "ADD_ENTITY";
const ADD_GROUP = "ADD_GROUP";
const ADD_HIERARCHY = "ADD_HIERARCHY";
const ADD_ROUTE_HISTORY = "ADD_ROUTE_HISTORY";
const REMOVE_COMPANY_NAMES = "REMOVE_COMPANY_NAMES";
const REMOVE_ROUTE_HISTORY = "REMOVE_ROUTE_HISTORY";
const SET_ADMINISTRATORS = "SET_ADMINISTRATORS";
const SET_APPS = "SET_APPS";
const SET_CATEGORIES = "SET_CATEGORIES";
const SET_COMPANY_NAMES = "SET_COMPANY_NAMES";
const SET_ENTITIES = "SET_ENTITIES";
const SET_ENTITY_TYPES = "SET_ENTITY_TYPES";
const SET_GROUPS = "SET_GROUPS";
const SET_HIERARCHIES = "SET_HIERARCHIES";
const SET_HIERARCHY_TYPES = "SET_HIERARCHY_TYPES";
const SET_MODELS = "SET_MODELS";
const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
const UPDATE_APPLICATION = "UPDATE_APPLICATION";
const UPDATE_CATEGORY = "UPDATE_CATEGORY";
const UPDATE_ENTITY = "UPDATE_ENTITY";
const UPDATE_GROUP = "UPDATE_GROUP";
const UPDATE_HIERARCHY = "UPDATE_HIERARCHY";
const UPSERT_ADMINISTRATORS = "UPSERT_ADMINISTRATORS";
const UPSERT_COMPANY_NAMES = "UPSERT_COMPANY_NAMES";

export {
    ADD_APPLICATION,
    ADD_CATEGORY,
    ADD_ENTITY,
    ADD_GROUP,
    ADD_HIERARCHY,
    ADD_ROUTE_HISTORY,
    REMOVE_COMPANY_NAMES,
    REMOVE_ROUTE_HISTORY,
    SET_ADMINISTRATORS,
    SET_APPS,
    SET_CATEGORIES,
    SET_COMPANY_NAMES,
    SET_MODELS,
    SET_ENTITIES,
    SET_ENTITY_TYPES,
    SET_GROUPS,
    SET_HIERARCHIES,
    SET_HIERARCHY_TYPES,
    TOGGLE_SIDEBAR,
    UPDATE_APPLICATION,
    UPDATE_CATEGORY,
    UPDATE_ENTITY,
    UPDATE_GROUP,
    UPDATE_HIERARCHY,
    UPSERT_ADMINISTRATORS,
    UPSERT_COMPANY_NAMES,
};
