import { Module } from "vuex";
import { initialState, ISgpState } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const sgp: Module<ISgpState, any> = {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

export { sgp };
