import { IUser } from "@/models";
import { GetterTree } from "vuex";
import { IAccountState } from "./state";

const getters: GetterTree<IAccountState, any> = {
    isAuthenticated: (state): boolean => !(!state.msalAccount),
    user: (state): IUser | null => state.appAccount,
    userEmail: (state): string => (state.msalAccount?.idTokenClaims as any).email,
    userName: (state): string => (state.msalAccount?.idTokenClaims as any).name,
    userPhoto: (state): string => state.userPhoto ? state.userPhoto: "",
};

export { getters };
