import { MutationTree } from "vuex";
import { ISimpleState } from "./state";
import * as mutationTypes from "./mutations-types";
import { IRequest, ISimpleUserRole, ISimpleValidatedPermission } from "@/models";

export const mutations: MutationTree<ISimpleState> = {
    [mutationTypes.ADD_MY_REQUESTS]: (state, payload: IRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.myRequests = state.myRequests.filter(r => !removedIds.includes(r.requestId));
        state.myRequests.unshift(...payload);
    },

    [mutationTypes.CLEAR_SIMPLE_STORE]: (state) => {
        state.myPermissions = [];
        state.myRequests = [];
        state.pendingRequests = [];
        state.userRoles = [];
        state.validatedRequests = [];
    },

    [mutationTypes.REMOVE_PENDING_REQUESTS]: (state, payload: IRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.pendingRequests = state.pendingRequests.filter(r => !removedIds.includes(r.requestId));
        state.validatedRequests = state.validatedRequests.filter(r => !removedIds.includes(r.requestId));
    },

    [mutationTypes.SET_MY_PERMISSIONS]: (state, payload: ISimpleValidatedPermission[]) => {
        state.myPermissions = payload;
    },

    [mutationTypes.SET_MY_REQUESTS]: (state, payload: IRequest[]) => {
        state.myRequests = payload;
    },

    [mutationTypes.SET_PENDING_REQUESTS]: (state, payload: IRequest[]) => {
        state.pendingRequests = payload;
    },

    [mutationTypes.SET_SIMPLE_USER_ROLES]: (state, payload: ISimpleUserRole[]) => {
        state.userRoles = payload;
    },

    [mutationTypes.SET_VALIDATED_REQUESTS]: (state, payload: IRequest[]) => {
        state.validatedRequests = payload;
    },
};
