import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { store } from "@/store";
import { RouteNames } from "./RouteNames";

export const simpleRoutes: RouteConfig[] = [
    {
        path: "/simple/:code",
        component: (): any => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "manage/hierarchy",
                name: RouteNames.simpleSettingsHierarchy,
                component: () => import(/* webpackChunkName: "SimpleSettingsHierarchy" */ "@/views/simple/settings/SimpleSettingsHierarchy.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        simple: true,
                    },
                },
            },
            {
                path: "manage/global-validators",
                name: RouteNames.simpleSettingsGlobalValidators,
                component: () => import(/* webpackChunkName: "SimpleSettingsGlobalValidator" */ "@/views/simple/settings/SimpleSettingsGlobalValidator.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        simple: true,
                    },
                },
            },
            {
                path: "manage/options",
                name: RouteNames.simpleSettingsOptions,
                component: () => import(/* webpackChunkName: "SimpleSettingsOptions" */ "@/views/simple/settings/SimpleSettingsOptions.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        simple: true,
                    },
                },
            },
            {
                path: "user-access",
                name: RouteNames.simpleUserAccess,
                component: () => import(/* webpackChunkName: "SimpleUserAccess" */ "@/views/simple/userAccess/SimpleUserAccess.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        simple: true,
                    },
                },
            },
            {
                path: "pending-requests",
                name: RouteNames.simplePendingRequests,
                component: () => import(/* webpackChunkName: "SimplePendingRequest" */ "@/views/simple/pendingRequest/SimplePendingRequest.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        simple: true,
                    },
                },
            },
            {
                path: "permissions",
                name: RouteNames.simpleHome,
                component: () => import(/* webpackChunkName: "SimpleHome" */ "@/views/simple/home/SimpleHome.vue"),
                meta: {
                    sidebar: {
                        logo: true,
                        description: true,
                        simple: true,
                    },
                },
            },
        ],
        meta: {
            requiresMinRole: {
                roleId: 1,
            },
        },
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
            store.dispatch("simple/clearSimpleStore");
            next();
        },
    },
];
