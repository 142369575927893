import { IAppModel } from "@/models";

import { BaseRepository } from "@/repositories";

class AppModelRepository extends BaseRepository<IAppModel> {
    protected getAllErrorMessage = "App Models cannot be retrieved.";

    protected getOneErrorMessage = "This App Model cannot be retrieved.";

    constructor() {
        super("/core/app-models");
    }
}

const appModelRepository = new AppModelRepository();

export { AppModelRepository, appModelRepository };
