import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Vue from "vue";
import VueAppInsights from "vue-application-insights";
import { router } from "@/router";
import { settings } from "@/settings";

export const vueAppInsights = (): void => {
    // Setup Application Insights
    if (settings.appInsights.instrumentationKey) {
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: settings.appInsights.instrumentationKey,
                enableCorsCorrelation: true,
            },
        });

        appInsights.loadAppInsights();
        appInsights.trackPageView();

        Vue.use(VueAppInsights, {
            id: settings.appInsights.instrumentationKey,
            appInsights,
            trackInitialPageView: false,
            router,
        });

        // Log all errors
        Vue.config.errorHandler = (err: Error) => {
            appInsights.trackException({ exception: err });
            throw err;
        };
    }
};
