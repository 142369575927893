import { IPolarisBiModule } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiModuleRepository extends BaseRepository<IPolarisBiModule> {
    protected getAllErrorMessage = "Modules cannot be retrieved.";

    protected getOneErrorMessage = "This Module cannot be retrieved.";

    protected addErrorMessage = "This Module cannot be created, please verify fields.";

    protected updateErrorMessage = "This Module cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Module cannot be removed.";

    constructor() {
        super("/polarisbi/modules");
    }
}

const polarisBiModuleRepository = new PolarisBiModuleRepository();

export { PolarisBiModuleRepository, polarisBiModuleRepository };
