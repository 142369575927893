import { IAttribute, ISharePointAccount, ISynchronization } from "@/models";

interface ISgpState {
    attributes: IAttribute[];
    sharePointAccounts: ISharePointAccount[];
    synchronizations: ISynchronization[];
}

const initialState: ISgpState = {
    attributes: [],
    sharePointAccounts: [],
    synchronizations: [],
};

export { ISgpState, initialState };
