import { IUser } from "@/models";
import * as msal from "@azure/msal-browser";

interface IAccountState {
    appAccount: IUser | null;
    msalAccount: msal.AccountInfo | null;
    userPhoto: string | null;
}

const initialState: IAccountState = {
    appAccount: null,
    msalAccount: null,
    userPhoto: null,
};

export { IAccountState, initialState };
