import { MutationTree } from "vuex";
import { IAppState } from "./state";
import * as mutationTypes from "./mutations-types";
import {
    IApp,
    IAppAzureAdGroup,
    IAppCategory,
    IAppCompanyName,
    IAppModel,
    IAppUserRole,
    IEntity,
    IEntityHierarchy,
    IEntityHierarchyType,
    IEntityType,
} from "@/models";
import { Route } from "vue-router";

export const mutations: MutationTree<IAppState> = {
    [mutationTypes.ADD_APPLICATION]: (state, payload: IApp) => {
        state.applications.push(payload);
    },

    [mutationTypes.ADD_CATEGORY]: (state, payload: IAppCategory) => {
        state.categories.push(payload);
    },

    [mutationTypes.ADD_ENTITY]: (state, payload: IEntity) => {
        state.entities.push(payload);
    },

    [mutationTypes.ADD_GROUP]: (state, payload: IAppAzureAdGroup) => {
        state.groups.push(payload);
    },

    [mutationTypes.ADD_HIERARCHY]: (state, payload: IEntityHierarchy) => {
        state.hierarchies.push(payload);
    },

    [mutationTypes.ADD_ROUTE_HISTORY]: (state, payload: Route) => {
        state.routeHistory.unshift(payload);

        if (state.routeHistory.length > 10) {
            state.routeHistory.pop();
        }
    },

    [mutationTypes.REMOVE_COMPANY_NAMES]: (state, payload: IAppCompanyName) => {
        state.companyNames = state.companyNames.filter(cn => cn.appId !== payload.appId);
    },

    [mutationTypes.REMOVE_ROUTE_HISTORY]: (state) => {
        state.routeHistory.splice(0, 1);
    },

    [mutationTypes.SET_ADMINISTRATORS]: (state, payload: IAppUserRole[]) => {
        state.administrators = payload;
    },

    [mutationTypes.SET_APPS]: (state, payload: IApp[]) => {
        state.applications = payload.sort((app1, app2) => app1.displayRank - app2.displayRank);
    },

    [mutationTypes.SET_CATEGORIES]: (state, payload: IAppCategory[]) => {
        state.categories = payload.sort((cat1, cat2) => cat1.displayRank - cat2.displayRank);
    },

    [mutationTypes.SET_COMPANY_NAMES]: (state, payload: IAppCompanyName[]) => {
        state.companyNames = payload;
    },

    [mutationTypes.SET_ENTITIES]: (state, payload: IEntity[]) => {
        state.entities = payload;
        state.entities.forEach(e => e.disabled = !e.isVisible);
    },

    [mutationTypes.SET_ENTITY_TYPES]: (state, payload: IEntityType[]) => {
        state.entityTypes = payload;
    },

    [mutationTypes.SET_GROUPS]: (state, payload: IAppAzureAdGroup[]) => {
        state.groups = payload;
    },

    [mutationTypes.SET_HIERARCHIES]: (state, payload: IEntityHierarchy[]) => {
        state.hierarchies = payload;
    },

    [mutationTypes.SET_HIERARCHY_TYPES]: (state, payload: IEntityHierarchyType[]) => {
        state.hierarchyTypes = payload;
    },

    [mutationTypes.SET_MODELS]: (state, payload: IAppModel[]) => {
        state.models = payload;
    },

    [mutationTypes.TOGGLE_SIDEBAR]: (state, payload: boolean) => {
        state.sidebar = payload;
    },

    [mutationTypes.UPDATE_APPLICATION]: (state, payload: IApp) => {
        const index = state.applications.findIndex(application => application.appId === payload.appId);
        state.applications.splice(index, 1, payload);
    },

    [mutationTypes.UPDATE_CATEGORY]: (state, payload: IAppCategory) => {
        const index = state.categories.findIndex(category => category.appCategoryId === payload.appCategoryId);
        state.categories.splice(index, 1, payload);
    },

    [mutationTypes.UPDATE_ENTITY]: (state, payload: IEntity) => {
        const index = state.entities.findIndex(entity => entity.entityId === payload.entityId);
        payload.disabled = !payload.isVisible;
        state.entities.splice(index, 1, payload);
    },

    [mutationTypes.UPDATE_GROUP]: (state, payload: IAppAzureAdGroup) => {
        const index = state.groups.findIndex(group => group.appAzureAdGroupId === payload.appAzureAdGroupId);
        state.groups.splice(index, 1, payload);
    },

    [mutationTypes.UPDATE_HIERARCHY]: (state, payload: IEntityHierarchy) => {
        const index = state.hierarchies.findIndex(hierarchy => hierarchy.entityHierarchyId === payload.entityHierarchyId);
        state.hierarchies.splice(index, 1, payload);
    },

    [mutationTypes.UPSERT_ADMINISTRATORS]: (state, payload: IAppUserRole[]) => {
        payload.forEach(administrator => {
            const index = state.administrators.findIndex(
                admin => admin.appId === administrator.appId
                && admin.roleId === administrator.roleId
                && admin.userId === administrator.userId);

            if (index > -1) {
                state.administrators.splice(index, 1, administrator);
            }
            else {
                state.administrators.push(administrator);
            }
        });
    },

    [mutationTypes.UPSERT_COMPANY_NAMES]: (state, payload: IAppCompanyName[]) => {
        payload.forEach(appCompanyName => {
            const index = state.companyNames.findIndex(companyName => companyName.appId === appCompanyName.appId);

            if (index > -1) {
                state.companyNames.splice(index, 1, appCompanyName);
            }
            else {
                state.companyNames.push(appCompanyName);
            }
        });
    },
};
