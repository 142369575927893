import { GetterTree } from "vuex";
import { IDlsState } from "./state";
import {
    IAccount,
    IDlsRequest,
    IDlsUserRole,
    IFunction,
    IFunctionCategory,
    IFunctionGroup,
    IPnl,
} from "@/models";

const getters: GetterTree<IDlsState, any> = {
    accounts: (state): IAccount[] => state.accounts,

    functions: (state): IFunction[] => state.functions,

    functionCategories: (state): IFunctionCategory[] => state.functionCategories,

    functionGroups: (state): IFunctionGroup[] => state.functionGroups,

    myRequests: (state): IDlsRequest[] => state.myRequests,

    pendingRequests: (state): IDlsRequest[] => state.pendingRequests,

    pnls: (state): IPnl[] => state.pnls,

    userRoles: (state): IDlsUserRole[] => state.userRoles,

    validatedRequests: (state): IDlsRequest[] => state.validatedRequests,
};

export { getters };
