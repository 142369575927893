import { IEntityType } from "@/models";

import { BaseRepository } from "@/repositories";

class EntityTypeRepository extends BaseRepository<IEntityType> {
    protected getAllErrorMessage = "Entity Types cannot be retrieved.";

    protected getOneErrorMessage = "This Entity Type cannot be retrieved.";

    protected addErrorMessage = "This Entity Type cannot be created, please verify fields.";

    protected updateErrorMessage = "This Entity Type cannot be updated, please verify fields.";

    protected replaceErrorMessage = "This Entity Type cannot be replaced, please verify fields.";

    protected removeErrorMessage = "This Entity Type cannot be removed, please verify it has not Requests.";

    constructor() {
        super("/core/entity-types");
    }
}

const entityTypeRepository = new EntityTypeRepository();

export { EntityTypeRepository, entityTypeRepository };
