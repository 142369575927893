import {  IAppUserRole, IUserGraph } from "@/models";

import { BaseRepository } from "@/repositories";

class AppAdministratorRepository extends BaseRepository<IAppUserRole> {
    protected getAllErrorMessage = "Applications Administrators cannot be retrieved.";

    protected getOneErrorMessage = "Application Administrators cannot be retrieved.";

    protected addErrorMessage = "Application Administrators cannot be created, please verify fields.";

    protected updateErrorMessage = "Application Administrators cannot be updated, please verify fields.";

    protected replaceErrorMessage = "Application Administrators cannot be upserted, please verify fields.";

    protected removeErrorMessage = "Application Administrators cannot be removed.";

    constructor() {
        super("/core/app-administrators");
    }

    public async setAdministrators(appId: number, roleId: number, users: IUserGraph[]): Promise<IAppUserRole[]> {
        const body = {
            roleId,
            users,
        };

        const administrators = await this.replace(body, `/${appId}`);

        return Array.isArray(administrators) ? administrators : [administrators];
    }
}

const appAdministratorRepository = new AppAdministratorRepository();

export { AppAdministratorRepository, appAdministratorRepository };
