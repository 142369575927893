import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#0450a4",
                secondary: "#4494cc",
                accent: "#34acdc",
            },
        },
        options: {
            customProperties: true,
        },
    },
});
