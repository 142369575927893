import Vue from "vue";
import Component from "vue-class-component";

@Component
export class DataTableMixin extends Vue {
    public dtFooterProps = {
        "items-per-page-options": [10, 20, 30, 40, 50, -1],
    };

    public dtItemsPerPage = 30;
}
