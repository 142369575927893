import { IFunctionGroup } from "@/models";

import { BaseRepository } from "@/repositories";

class FunctionGroupRepository extends BaseRepository<IFunctionGroup> {
    protected getAllErrorMessage = "Function Groups cannot be retrieved.";

    protected getOneErrorMessage = "This Function Group cannot be retrieved.";

    protected addErrorMessage = "This Function Group cannot be created, please verify fields.";

    protected updateErrorMessage = "This Function Group cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Function Group cannot be removed.";

    constructor() {
        super("/dls/function-groups");
    }
}

const functionGroupRepository = new FunctionGroupRepository();

export { FunctionGroupRepository, functionGroupRepository };
