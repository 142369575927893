import { IAccount } from "@/models";

import { BaseRepository } from "@/repositories";

class AccountRepository extends BaseRepository<IAccount> {
    protected getAllErrorMessage = "Accounts cannot be retrieved.";

    protected getOneErrorMessage = "This Account cannot be retrieved.";

    protected addErrorMessage = "This Account cannot be created, please verify fields.";

    protected updateErrorMessage = "This Account cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Account cannot be removed.";

    constructor() {
        super("/dls/accounts");
    }
}

const accountRepository = new AccountRepository();

export { AccountRepository, accountRepository };
