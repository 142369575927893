import { ISynchronization } from "@/models";

import { BaseRepository } from "@/repositories";

class SynchronizationRepository extends BaseRepository<ISynchronization> {
    protected getAllErrorMessage = "Synchronizations cannot be retrieved.";

    protected getOneErrorMessage = "This Synchronization cannot be retrieved.";

    protected addErrorMessage = "This Synchronization cannot be created, please verify fields.";

    protected updateErrorMessage = "This Synchronization cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Synchronization cannot be removed.";

    constructor() {
        super("/sgp/synchronizations");
    }
}

const synchronizationRepository = new SynchronizationRepository();

export { SynchronizationRepository, synchronizationRepository };
