import { BaseRepository } from "@/repositories";
import { IXtrtProfile, IXtrtRequest, IXtrtValidatedPermission } from "@/models/xtrt";

class XtrtPermissionRepository extends BaseRepository<IXtrtProfile | IXtrtRequest | IXtrtValidatedPermission> {
    protected getAllErrorMessage = "Requests cannot be retrieved.";

    protected getOneErrorMessage = "Request cannot be retrieved.";

    protected addErrorMessage = "Request cannot be created or validated/removed, please verify fields.";

    protected replaceErrorMessage = "Request files cannot be added to your request(s).";

    constructor() {
        super("/xtrt/permissions");
    }
}

const xtrtPermissionRepository = new XtrtPermissionRepository();

export { XtrtPermissionRepository, xtrtPermissionRepository };
