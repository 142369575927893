const ADD_MY_REQUESTS = "ADD_MY_REQUESTS";
const REMOVE_PENDING_REQUESTS = "REMOVE_PENDING_REQUESTS";
const SET_MASTER_DATAS = "SET_MASTER_DATAS";
const SET_MDH_USER_ROLES = "SET_MDH_USER_ROLES";
const SET_MY_REQUESTS = "SET_MY_REQUESTS";
const SET_PENDING_REQUESTS = "SET_PENDING_REQUESTS";
const SET_ROLES = "SET_ROLES";
const SET_SECRET_KEYS = "SET_SECRET_KEYS";
const SET_VALIDATED_REQUESTS = "SET_VALIDATED_REQUESTS";
const UPDATE_MASTER_ROLE = "UPDATE_MASTER_ROLE";

export {
    ADD_MY_REQUESTS,
    REMOVE_PENDING_REQUESTS,
    SET_MASTER_DATAS,
    SET_MDH_USER_ROLES,
    SET_MY_REQUESTS,
    SET_PENDING_REQUESTS,
    SET_ROLES,
    SET_SECRET_KEYS,
    SET_VALIDATED_REQUESTS,
    UPDATE_MASTER_ROLE,
};
