import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const sgpRoutes: RouteConfig[] = [
    {
        path: "/sgp",
        component: (): any => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "manage/attributes",
                name: RouteNames.sgpSettingsAttributes,
                component: () => import(/* webpackChunkName: "SgpSettingsAttribute" */ "@/views/sgp/settings/SgpSettingsAttribute.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        sgp: true,
                    },
                },
            },
            {
                path: "manage/sharepoint-accounts",
                name: RouteNames.sgpSettingsSharePointAccounts,
                component: () => import(/* webpackChunkName: "SgpSettingsSharePointAccount" */ "@/views/sgp/settings/SgpSettingsSharePointAccount.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        sgp: true,
                    },
                },
            },
            {
                path: "manage/synchronizations",
                name: RouteNames.sgpSettingsSynchronizations,
                component: () => import(/* webpackChunkName: "SgpSettingsSynchronization" */ "@/views/sgp/settings/SgpSettingsSynchronization.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        sgp: true,
                    },
                },
            },
            {
                path: "permissions",
                name: RouteNames.sgpPermissions,
                redirect: {
                    name: RouteNames.sgpSettingsSynchronizations,
                },
            },
        ],
        meta: {
            code: "sgp",
            requiresMinRole: {
                roleId: 1,
                appId: 8,
            },
        },
    },
];
