import { IPolarisBiCostCenter } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiCostCenterRepository extends BaseRepository<IPolarisBiCostCenter> {
    protected getAllErrorMessage = "CostCenters cannot be retrieved.";

    protected getOneErrorMessage = "This CostCenter cannot be retrieved.";

    protected addErrorMessage = "This CostCenter cannot be created, please verify fields.";

    protected updateErrorMessage = "This CostCenter cannot be updated, please verify fields.";

    protected removeErrorMessage = "This CostCenter cannot be removed.";

    constructor() {
        super("/polarisbi/cost-centers");
    }
}

const polarisBiCostCenterRepository = new PolarisBiCostCenterRepository();

export { PolarisBiCostCenterRepository, polarisBiCostCenterRepository };
