import { IAppCompanyName } from "@/models";

import { BaseRepository } from "@/repositories";

class AppCompanyNameRepository extends BaseRepository<IAppCompanyName> {
    protected getAllErrorMessage = "Company Names cannot be retrieved.";

    protected getOneErrorMessage = "This Company Name cannot be retrieved.";

    protected replaceErrorMessage = "Company Names cannot be replaced, please verify fields.";

    constructor() {
        super("/core/app-company-names");
    }

    public async setCompanyNames(appId: number, companyNames: string[]): Promise<IAppCompanyName> {
        return await this.replace(companyNames, `/${appId}`) as IAppCompanyName;
    }
}

const appCompanyNameRepository = new AppCompanyNameRepository();

export { AppCompanyNameRepository, appCompanyNameRepository };
