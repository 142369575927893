import { IGroupValidator, IUserGraph } from "@/models";

import { BaseRepository } from "@/repositories";

class GroupValidatorRepository extends BaseRepository<IGroupValidator> {
    protected getAllErrorMessage = "Validators cannot be retrieved.";

    protected getOneErrorMessage = "This Validator cannot be retrieved.";

    protected addErrorMessage = "This Validator cannot be created, please verify fields.";

    protected updateErrorMessage = "This Validator cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Validator cannot be removed.";

    constructor() {
        super("/group/validators");
    }

    async setValidators(appCode: string, appAzureAdGroupId: number, users: IUserGraph[]): Promise<IGroupValidator[]> {
        return await this.put(`/${appCode}`, {
            appAzureAdGroupId,
            users,
        });
    }
}

const groupValidatorRepository = new GroupValidatorRepository();

export { GroupValidatorRepository, groupValidatorRepository };
