import { ActionTree } from "vuex";
import * as mutation from "./mutations-types";
import { IAppState } from "./state";
import { IApp, IAppAzureAdGroup, IAppCategory, IEntityHierarchy, IUserGraph } from "@/models";
import {
    appAdministratorRepository,
    appAzureAdGroupRepository,
    appCategoryRepository,
    appCompanyNameRepository,
    appModelRepository,
    appRepository,
    entityHierarchyRepository,
    entityHierarchyTypeRepository,
    entityTypeRepository,
} from "@/repositories";
import { Route } from "vue-router";

export const actions: ActionTree<IAppState, any> = {
    addApplication: async (context, payload: IApp): Promise<IApp> => {
        const application = await appRepository.add(payload);
        context.commit(mutation.ADD_APPLICATION, application);
        return application as IApp;
    },

    addCategory: async (context, payload: IAppCategory): Promise<void> => {
        const category = await appCategoryRepository.add(payload);
        context.commit(mutation.ADD_CATEGORY, category);
    },

    addGroup: async (context, payload: { group: IAppAzureAdGroup; appCode: string }): Promise<void> => {
        const group = await appAzureAdGroupRepository.add(payload.group, `/${payload.appCode}`);
        context.commit(mutation.ADD_GROUP, group);
    },

    addHierarchy: async (context, payload: IEntityHierarchy): Promise<void> => {
        const hierarchy = await entityHierarchyRepository.add(payload);
        context.commit(mutation.ADD_HIERARCHY, hierarchy);
    },

    addRouteHistory: async (context, payload: Route): Promise<void> => {
        context.commit(mutation.ADD_ROUTE_HISTORY, payload);
    },

    deleteApplicationImage: async (context, payload: number): Promise<IApp> => {
        const application = await appRepository.remove(payload);
        context.commit(mutation.UPDATE_APPLICATION, application);
        return application as IApp;
    },

    deleteCategory: async (context, category: IAppCategory): Promise<void> => {
        const categories = await appCategoryRepository.remove(category.appCategoryId);
        context.commit(mutation.SET_CATEGORIES, categories);
    },

    deleteGroup: async (context, group: IAppAzureAdGroup): Promise<void> => {
        const groups = await appAzureAdGroupRepository.remove(group.appAzureAdGroupId);
        context.commit(mutation.SET_GROUPS, groups);
    },

    deleteHierarchy: async (context, hierarchy: IEntityHierarchy): Promise<void> => {
        const hierarchies = await entityHierarchyRepository.remove(hierarchy.entityHierarchyId);
        context.commit(mutation.SET_HIERARCHIES, hierarchies);
    },

    loadAdministrators: async (context): Promise<void> => {
        const administrators = await appAdministratorRepository.getAll();
        context.commit(mutation.SET_ADMINISTRATORS, administrators);
    },

    loadApplications: async (context, includeInactive: boolean): Promise<void> => {
        const applications = await appRepository.getAllApplications(includeInactive);
        context.commit(mutation.SET_APPS, applications);
    },

    loadCategories: async (context, includeInvisible: boolean): Promise<void> => {
        const categories = await appCategoryRepository.getAllCategories(includeInvisible);
        context.commit(mutation.SET_CATEGORIES, categories);
    },

    loadCompanyNames: async (context): Promise<void> => {
        const appCompanyNames = await appCompanyNameRepository.getAll();
        context.commit(mutation.SET_COMPANY_NAMES, appCompanyNames);
    },

    loadEntityTypes: async (context): Promise<void> => {
        const entityTypes = await entityTypeRepository.getAll();
        context.commit(mutation.SET_ENTITY_TYPES, entityTypes);
    },

    loadEntityTypesByHierarchy: async (context, entityHierarchyId: number): Promise<void> => {
        const entityTypes = await entityTypeRepository.getAll({}, `/hierarchy/${entityHierarchyId}`);
        context.commit(mutation.SET_ENTITY_TYPES, entityTypes);
    },

    loadGroups: async (context, includeInactive: boolean): Promise<void> => {
        const groups = await appAzureAdGroupRepository.getAll({
            includeInactive,
        });
        context.commit(mutation.SET_GROUPS, groups);
    },

    loadGroupsByApplication: async (context, payload: { appCode: string; includeInactive: boolean }): Promise<void> => {
        const groups = await appAzureAdGroupRepository.getAll({
            includeInactive: payload.includeInactive,
        }, `/${payload.appCode}`);
        context.commit(mutation.SET_GROUPS, groups);
    },

    loadHierarchies: async (context): Promise<void> => {
        const hierarchies = await entityHierarchyRepository.getAll();
        context.commit(mutation.SET_HIERARCHIES, hierarchies);
    },

    loadHierarchyTypes: async (context): Promise<void> => {
        const hierarchyTypes = await entityHierarchyTypeRepository.getAll();
        context.commit(mutation.SET_HIERARCHY_TYPES, hierarchyTypes);
    },

    loadModels: async (context): Promise<void> => {
        const models = await appModelRepository.getAll();
        context.commit(mutation.SET_MODELS, models);
    },

    removeRouteHistory: async (context): Promise<void> => {
        context.commit(mutation.REMOVE_ROUTE_HISTORY);
    },

    setAdministrators: async (context, payload: { appId: number; roleId: number; users: IUserGraph[] }): Promise<void> => {
        const administrators = await appAdministratorRepository.setAdministrators(payload.appId, payload.roleId, payload.users);
        context.commit(mutation.UPSERT_ADMINISTRATORS, administrators);
    },

    setAppCompanyNames: async (context, payload: { appId: number; companyNames: string[] }): Promise<void> => {
        const appCompanyNames = await appCompanyNameRepository.setCompanyNames(payload.appId, payload.companyNames);
        if (appCompanyNames.companyNames.length > 0) {
            context.commit(mutation.UPSERT_COMPANY_NAMES, [appCompanyNames]);
        }
        else {
            context.commit(mutation.REMOVE_COMPANY_NAMES, appCompanyNames);
        }
    },

    toggleSidebar: (context): void => {
        context.commit(mutation.TOGGLE_SIDEBAR, !context.state.sidebar);
    },

    updateApplication: async (context, application: IApp): Promise<IApp> => {
        application.conditionsOfUse = btoa(encodeURIComponent(application.conditionsOfUse));
        application.information = btoa(encodeURIComponent(application.information));
        const updatedApplication = await appRepository.update(application, `/${application.appId}`);
        context.commit(mutation.UPDATE_APPLICATION, updatedApplication);
        return updatedApplication;
    },

    updateApplicationImage: async (context, payload: { appId: number; image: File }): Promise<IApp> => {
        const application = await appRepository.replaceImage(payload.appId, payload.image);
        context.commit(mutation.UPDATE_APPLICATION, application);
        return application;
    },

    updateCategory: async (context, category: IAppCategory): Promise<void> => {
        const updatedCategory = await appCategoryRepository.update(category, `/${category.appCategoryId}`);
        context.commit(mutation.UPDATE_CATEGORY, updatedCategory);
    },

    updateGroup: async (context, group: IAppAzureAdGroup): Promise<void> => {
        const updatedGroup = await appAzureAdGroupRepository.update(group, `/${group.appAzureAdGroupId}`);
        context.commit(mutation.UPDATE_GROUP, updatedGroup);
    },

    updateHierarchy: async (context, hierarchy: IEntityHierarchy): Promise<void> => {
        const updatedHierarchy = await entityHierarchyRepository.update(hierarchy, `/${hierarchy.entityHierarchyId}`);
        context.commit(mutation.UPDATE_HIERARCHY, updatedHierarchy);
    },

    updateOrderApplications: async (context, applications: IApp[]): Promise<void> => {
        let displayRank = 0;
        const body = applications.map(application => ({
            objectId: application.appId,
            displayRank: ++displayRank,
        }));

        const updatedApplications = await appRepository.update(body);
        context.commit(mutation.SET_APPS, updatedApplications);
    },

    updateOrderCategories: async (context, categories: IAppCategory[]): Promise<void> => {
        let displayRank = 0;
        const body = categories.map(category => ({
            objectId: category.appCategoryId,
            displayRank: ++displayRank,
        }));

        const updatedCategories = await appCategoryRepository.update(body);
        context.commit(mutation.SET_CATEGORIES, updatedCategories);
    },
};
