import Component, { mixins } from "vue-class-component";
import { IEntity } from "@/models";
import { ApplicationHierarchyMixin } from "@/mixins";
import { buildLookup } from "@/helpers";

@Component
export class HierarchyEntitiesMixin extends mixins(ApplicationHierarchyMixin) {
    get flattenEntities(): IEntity[] {
        const entities: IEntity[] = this.$store.getters["app/entities"];
        return entities.filter(e => e.entityHierarchyId === this.applicationHierarchy.entityHierarchyId);
    }

    get entities(): IEntity[] {
        const entities = this.flattenEntities;
        const childHashes = buildLookup(entities, entity => entity.parentEntityId);
        const rootEntities = entities.filter(e => e.parentEntityId === null);

        rootEntities.forEach(entity => {
            entity.children = childHashes[entity.entityId]?.flatMap(child => this.getChildren(childHashes, child)) ?? [];
        });

        return rootEntities.flatMap(e => e.isVisible ? [e] : e.children);
    }

    protected getChildren(lookup: Record<number, IEntity[]>, child: IEntity): IEntity[] {
        if (child.isVisible) {
            child.children = lookup[child.entityId]?.flatMap(subChild => this.getChildren(lookup, subChild)) ?? [];
            return [child];
        }
        else {
            return lookup[child.entityId]?.flatMap(subChild => this.getChildren(lookup, subChild)) ?? [];
        }
    }
}
