import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const polarisBiRoutes: RouteConfig[] = [
    {
        path: "/polarisbi",
        component: (): any => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "manage/functions",
                name: RouteNames.polarisBiSettingsFunctions,
                component: () => import(/* webpackChunkName: "PolarisBiSettingsFunction" */ "@/views/polarisBi/settings/PolarisBiSettingsFunction.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "manage/hierarchy",
                name: RouteNames.polarisBiSettingsHierarchy,
                component: () => import(/* webpackChunkName: "PolarisBiSettingsHierarchy" */ "@/views/polarisBi/settings/PolarisBiSettingsHierarchy.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "manage/pnls",
                name: RouteNames.polarisBiSettingsPnl,
                component: () => import(/* webpackChunkName: "PolarisBiSettingsPnl" */ "@/views/polarisBi/settings/PolarisBiSettingsPnl.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "manage/options",
                name: RouteNames.polarisBiSettingsOptions,
                component: () => import(/* webpackChunkName: "PolarisBiSettingsOptions" */ "@/views/polarisBi/settings/PolarisBiSettingsOptions.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "manage/products",
                name: RouteNames.polarisBiSettingsProduct,
                component: () => import(/* webpackChunkName: "PolarisBiSettingsProduct" */ "@/views/polarisBi/settings/PolarisBiSettingsProduct.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "manage/core-projects",
                name: RouteNames.polarisBiSettingsCoreProject,
                component: () => import(/* webpackChunkName: "PolarisBiSettingsCoreProject" */ "@/views/polarisBi/settings/PolarisBiSettingsCoreProject.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "manage/pr-group-validators",
                name: RouteNames.polarisBiSettingsPrGroupValidator,
                component: () => import(/* webpackChunkName: "PolarisBiSettingsPrGroupValidator" */ "@/views/polarisBi/settings/PolarisBiSettingsPrGroupValidator.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "manage/options",
                name: RouteNames.polarisBiSettingsOptions,
                component: () => import(/* webpackChunkName: "PolarisBiSettingsOptions" */ "@/views/polarisBi/settings/PolarisBiSettingsOptions.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "permissions",
                name: RouteNames.polarisBiHome,
                component: () => import(/* webpackChunkName: "PolarisBiHome" */ "@/views/polarisBi/home/PolarisBiHome.vue"),
                meta: {
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "pending-requests",
                name: RouteNames.polarisBiPendingRequests,
                component: () => import(/* webpackChunkName: "PolarisBiPendingRequest" */ "@/views/polarisBi/pendingRequest/PolarisBiPendingRequest.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
            {
                path: "user-access",
                name: RouteNames.polarisBiUserAccess,
                component: () => import(/* webpackChunkName: "PolarisBiUserAccess" */ "@/views/polarisBi/userAccess/PolarisBiUserAccess.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        polarisbi: true,
                    },
                },
            },
        ],
        meta: {
            code: "polarisbi",
            requiresMinRole: {
                roleId: 1,
                appId: 2,
            },
        },
    },
];
