const ADD_FUNCTION = "ADD_FUNCTION";
const ADD_MY_REQUESTS = "ADD_MY_REQUESTS";
const REMOVE_PENDING_REQUESTS = "REMOVE_PENDING_REQUESTS";
const SET_CORE_PROJECTS = "SET_CORE_PROJECTS";
const SET_COST_CENTERS = "SET_COST_CENTERS";
const SET_FUNCTIONS = "SET_FUNCTIONS";
const SET_MARKETS = "SET_MARKETS";
const SET_MODULES = "SET_MODULES";
const SET_MY_REQUESTS = "SET_MY_REQUESTS";
const SET_PENDING_REQUESTS = "SET_PENDING_REQUESTS";
const SET_PNLS = "SET_PNLS";
const SET_POLARIS_PR_GROUP_VALIDATORS = "SET_POLARIS_PR_GROUP_VALIDATORS";
const SET_POLARIS_PRODUCT_USER_ROLES = "SET_POLARIS_PRODUCT_USER_ROLES";
const SET_POLARIS_USER_ROLES = "SET_POLARIS_USER_ROLES";
const SET_PRODUCTS = "SET_PRODUCTS";
const SET_PROJECTS = "SET_PROJECTS";
const SET_VALIDATED_REQUESTS = "SET_VALIDATED_REQUESTS";
const UPDATE_FUNCTION = "UPDATE_FUNCTION";
const UPDATE_PRODUCT = "UPDATE_PRODUCT";
const UPDATE_CORE_PROJECT = "UPDATE_CORE_PROJECT";

export {
    ADD_FUNCTION,
    ADD_MY_REQUESTS,
    REMOVE_PENDING_REQUESTS,
    SET_CORE_PROJECTS,
    SET_COST_CENTERS,
    SET_FUNCTIONS,
    SET_MARKETS,
    SET_MODULES,
    SET_MY_REQUESTS,
    SET_PENDING_REQUESTS,
    SET_PNLS,
    SET_POLARIS_PR_GROUP_VALIDATORS,
    SET_POLARIS_PRODUCT_USER_ROLES,
    SET_POLARIS_USER_ROLES,
    SET_PRODUCTS,
    SET_PROJECTS,
    SET_VALIDATED_REQUESTS,
    UPDATE_FUNCTION,
    UPDATE_PRODUCT,
    UPDATE_CORE_PROJECT,
};
