import { IFunctionCategory } from "@/models";

import { BaseRepository } from "@/repositories";

class FunctionCategoryRepository extends BaseRepository<IFunctionCategory> {
    protected getAllErrorMessage = "Function Categories cannot be retrieved.";

    protected getOneErrorMessage = "This Function Category cannot be retrieved.";

    protected addErrorMessage = "This Function Category cannot be created, please verify fields.";

    protected updateErrorMessage = "This Function Category cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Function Category cannot be removed.";

    constructor() {
        super("/dls/function-categories");
    }
}

const functionCategoryRepository = new FunctionCategoryRepository();

export { FunctionCategoryRepository, functionCategoryRepository };
