import Vue from "vue";
import Component from "vue-class-component";
import { IApp } from "@/models";

@Component
export class ApplicationCodeMixin extends Vue {
    get application(): IApp | null {
        const applications: IApp[] = this.$store.getters["app/applications"];
        let code = this.$route.params.code;
        if (!code) {
            const matchedRoute = this.$route.matched.find(r => r?.meta?.code);
            code = matchedRoute?.meta.code ?? "";
        }
        return applications.find(app => app.code === code);
    }
}
