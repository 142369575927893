import { IEntityHierarchyType } from "@/models";

import { BaseRepository } from "@/repositories";

class EntityHierarchyTypeRepository extends BaseRepository<IEntityHierarchyType> {
    protected getAllErrorMessage = "Entity Hierarchy Types cannot be retrieved.";

    protected getOneErrorMessage = "This Entity Hierarchy Type cannot be retrieved.";

    constructor() {
        super("/core/entity-hierarchy-types");
    }
}

const entityHierarchyTypeRepository = new EntityHierarchyTypeRepository();

export { EntityHierarchyTypeRepository, entityHierarchyTypeRepository };
