import { GetterTree } from "vuex";
import { IGroupState } from "./state";
import { IGroupValidator, IRequest } from "@/models";

const getters: GetterTree<IGroupState, any> = {
    myRequests: (state): IRequest[] => state.myRequests,

    pendingRequests: (state): IRequest[] => state.pendingRequests,

    validatedRequests: (state): IRequest[] => state.validatedRequests,

    validators: (state): IGroupValidator[] => state.validators,
};

export { getters };
