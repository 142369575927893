import { IFunction } from "@/models";

import { BaseRepository } from "@/repositories";

class FunctionRepository extends BaseRepository<IFunction> {
    protected getAllErrorMessage = "Functions cannot be retrieved.";

    protected getOneErrorMessage = "This Function cannot be retrieved.";

    protected addErrorMessage = "This Function cannot be created, please verify fields.";

    protected updateErrorMessage = "This Function cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Function cannot be removed.";

    constructor() {
        super("/dls/functions");
    }
}

const functionRepository = new FunctionRepository();

export { FunctionRepository, functionRepository };
