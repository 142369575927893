import { IPolarisBiProject } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiProjectRepository extends BaseRepository<IPolarisBiProject> {
    protected getAllErrorMessage = "Projects cannot be retrieved.";

    protected getOneErrorMessage = "This Project cannot be retrieved.";

    protected addErrorMessage = "This Project cannot be created, please verify fields.";

    protected updateErrorMessage = "This Project cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Project cannot be removed.";

    constructor() {
        super("/polarisbi/projects");
    }
}

const polarisBiProjectRepository = new PolarisBiProjectRepository();

export { PolarisBiProjectRepository, polarisBiProjectRepository };
