export const settings = {
    environment: process.env.VUE_APP_ENV as string,
    appInsights: {
        instrumentationKey: process.env.VUE_APP_APP_INSIGHTS_INSTRUMENTATION_KEY as string,
    },
    azureAd: {
        frontClientId: process.env.VUE_APP_AZURE_AD_FRONT_CLIENTID as string,
        backClientId: process.env.VUE_APP_AZURE_AD_BACK_CLIENTID as string,
        tenantId: process.env.VUE_APP_AZURE_AD_TENANTID as string,
        instance: process.env.VUE_APP_AZURE_AD_INSTANCE as string,
        callbackPath: process.env.VUE_APP_AZURE_AD_CALLBACKPATH as string,
    },
    graphApi: {
        baseUrl: process.env.VUE_APP_GRAPH_API_BASE_URL as string,
    },
    webApi: {
        baseUrl: process.env.VUE_APP_WEB_API_BASE_URL as string,
    },
};
