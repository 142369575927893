import { IPolarisBiProduct } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiProductRepository extends BaseRepository<IPolarisBiProduct> {
    protected getAllErrorMessage = "Products cannot be retrieved.";

    protected getOneErrorMessage = "This Product cannot be retrieved.";

    protected addErrorMessage = "This Product cannot be created, please verify fields.";

    protected updateErrorMessage = "This Product cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Product cannot be removed.";

    constructor() {
        super("/polarisbi/products");
    }
}

const polarisBiProductRepository = new PolarisBiProductRepository();

export { PolarisBiProductRepository, polarisBiProductRepository };
