import { IMasterRole } from "@/models";

import { BaseRepository } from "@/repositories";

class MasterRoleRepository extends BaseRepository<IMasterRole> {
    protected getAllErrorMessage = "Roles cannot be retrieved.";

    protected getOneErrorMessage = "This Role cannot be retrieved.";

    constructor() {
        super("/mdh/master-roles");
    }
}

const masterRoleRepository = new MasterRoleRepository();

export { MasterRoleRepository, masterRoleRepository };
