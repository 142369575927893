import { IEntity } from "@/models";

import { BaseRepository } from "@/repositories";

class MdhEntityRepository extends BaseRepository<IEntity> {
    protected getAllErrorMessage = "Entities cannot be retrieved.";

    protected getOneErrorMessage = "This Entity cannot be retrieved.";

    protected addErrorMessage = "This Entity cannot be created, please verify fields.";

    protected updateErrorMessage = "This Entity cannot be updated, please verify fields.";

    protected replaceErrorMessage = "This Entity cannot be replaced, please verify fields.";

    protected removeErrorMessage = "This Entity cannot be removed, please verify it has not Requests.";

    constructor() {
        super("/mdh/entities");
    }
}

const mdhEntityRepository = new MdhEntityRepository();

export { MdhEntityRepository, mdhEntityRepository };
