import { BaseRepository } from "../BaseRepository";
import { IXtrtRole } from "@/models/xtrt";

class XtrtRoleRepository extends BaseRepository<IXtrtRole> {
    protected getAllErrorMessage = "Roles cannot be retrieved.";

    protected getOneErrorMessage = "Role cannot be retrieved.";

    protected addErrorMessage = "Role cannot be created, please verify fields.";

    protected replaceErrorMessage = "";

    constructor() {
        super("/xtrt/roles");
    }
}

const xtrtRoleRepository = new XtrtRoleRepository();

export { XtrtRoleRepository, xtrtRoleRepository };
