import { RequestStatuses } from "@/enums";

class RequestStatusService {
    getStatusName(requestStatusId: number): string {
        switch (requestStatusId) {
            case RequestStatuses.Pending:
                return "Pending";
            case RequestStatuses.Validated:
                return "Validated";
            case RequestStatuses.Refused:
                return "Refused";
            case RequestStatuses.Closed:
                return "Closed";
            default:
                return "";
        }
    }

    getStatusColor(requestStatusId: number): string {
        switch (requestStatusId) {
            case RequestStatuses.Pending:
                return "info";
            case RequestStatuses.Validated:
                return "success";
            case RequestStatuses.Refused:
                return "error";
            case RequestStatuses.Closed:
                return "orange";
            default:
                return "";
        }
    }
}

const requestStatusService = new RequestStatusService();

export { RequestStatusService, requestStatusService };
