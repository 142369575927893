import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const coreRoutes: RouteConfig[] = [
    {
        path: "/core/manage",
        component: (): any => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "applications",
                name: RouteNames.coreApplications,
                component: () => import(/* webpackChunkName: "CoreApplications" */ "@/views/core/applications/CoreApplications.vue"),
                meta: {
                    sidebar: {
                        am: true,
                    },
                },
            },
            {
                path: "categories",
                name: RouteNames.coreCategories,
                component: () => import(/* webpackChunkName: "CoreCategories" */ "@/views/core/categories/CoreCategories.vue"),
                meta: {
                    sidebar: {
                        am: true,
                    },
                },
            },
            {
                path: "hierarchies",
                name: RouteNames.coreHierarchies,
                component: () => import(/* webpackChunkName: "CoreHierarchies" */ "@/views/core/hierarchies/CoreHierarchies.vue"),
                meta: {
                    sidebar: {
                        am: true,
                    },
                },
            },
            {
                path: "groups",
                name: RouteNames.coreGroups,
                component: () => import(/* webpackChunkName: "coreGroups" */ "@/views/core/groups/CoreGroups.vue"),
                meta: {
                    sidebar: {
                        am: true,
                    },
                },
            },
        ],
        meta: {
            code: "core",
            requiresMinRole: {
                appId: 1,
                roleId: 4,
            },
        },
    },
];
