import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const powerBiRoutes: RouteConfig[] = [
    {
        path: "/powerbi",
        component: (): any => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "manage/hierarchy",
                name: RouteNames.powerBiSettingsHierarchy,
                component: () => import(/* webpackChunkName: "PowerBiSettingsHierarchy" */ "@/views/powerBi/settings/PowerBiSettingsHierarchy.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        powerbi: true,
                    },
                },
            },
            {
                path: "manage/options",
                name: RouteNames.powerBiSettingsOptions,
                component: () => import(/* webpackChunkName: "PowerBiSettingsOptions" */ "@/views/powerBi/settings/PowerBiSettingsOptions.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        powerbi: true,
                    },
                },
            },
            {
                path: "user-access",
                name: RouteNames.powerBiUserAccess,
                component: () => import(/* webpackChunkName: "PowerBiUserAccess" */ "@/views/powerBi/userAccess/PowerBiUserAccess.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        powerbi: true,
                    },
                },
            },
            {
                path: "pending-requests",
                name: RouteNames.powerBiPendingRequests,
                component: () => import(/* webpackChunkName: "PowerBiPendingRequest" */ "@/views/powerBi/pendingRequest/PowerBiPendingRequest.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        powerbi: true,
                    },
                },
            },
            {
                path: "permissions",
                name: RouteNames.powerBiHome,
                component: () => import(/* webpackChunkName: "PowerBiHome" */ "@/views/powerBi/home/PowerBiHome.vue"),
                meta: {
                    sidebar: {
                        logo: true,
                        description: true,
                        powerbi: true,
                    },
                },
            },
        ],
        meta: {
            code: "powerbi",
            requiresMinRole: {
                roleId: 1,
                appId: 10,
            },
        },
    },
];
