import Component, { mixins } from "vue-class-component";
import { ApplicationCodeMixin } from "@/mixins";

@Component<ApplicationTitleMixin>({
    metaInfo() {
        return {
            title: this?.application?.name ?? "",
        };
    },
})
export class ApplicationTitleMixin extends mixins(ApplicationCodeMixin) { }
