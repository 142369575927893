import { IPolarisBiMarket } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiMarketRepository extends BaseRepository<IPolarisBiMarket> {
    protected getAllErrorMessage = "Markets cannot be retrieved.";

    protected getOneErrorMessage = "This Market cannot be retrieved.";

    protected addErrorMessage = "This Market cannot be created, please verify fields.";

    protected updateErrorMessage = "This Market cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Market cannot be removed.";

    constructor() {
        super("/polarisbi/markets");
    }
}

const polarisBiMarketRepository = new PolarisBiMarketRepository();

export { PolarisBiMarketRepository, polarisBiMarketRepository };
