import { IPowerBiUserRole, IUserGraph } from "@/models";

import { BaseRepository } from "@/repositories";

class PowerBiValidatorRepository extends BaseRepository<IPowerBiUserRole> {
    protected getAllErrorMessage = "Validators cannot be retrieved.";

    protected getOneErrorMessage = "This Validator cannot be retrieved.";

    protected addErrorMessage = "This Validator cannot be created, please verify fields.";

    protected updateErrorMessage = "This Validator cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Validator cannot be removed.";

    constructor() {
        super("/powerbi/validators");
    }

    async importValidators(validatorsFile: File): Promise<IPowerBiUserRole[]> {
        const formData = new FormData();
        formData.append("validatorsFile", validatorsFile);

        return await this.put("/import", formData, "multipart/form-data");
    }

    async setValidators(entityId: number | null, users: IUserGraph[]): Promise<IPowerBiUserRole[]> {
        return await this.put("/", {
            entityId,
            users,
        });
    }
}

const powerBiValidatorRepository = new PowerBiValidatorRepository();

export { PowerBiValidatorRepository, powerBiValidatorRepository };
