import { IAppAzureAdGroup } from "@/models";

import { BaseRepository } from "@/repositories";

class AppAzureAdGroupRepository extends BaseRepository<IAppAzureAdGroup> {
    protected getAllErrorMessage = "Azure AD Groups cannot be retrieved.";

    protected getOneErrorMessage = "This Azure AD Group cannot be retrieved.";

    protected addErrorMessage = "This Azure AD Groups cannot be created, please verify fields.";

    protected updateErrorMessage = "This Azure AD Groups cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Azure AD Groups cannot be removed.";

    constructor() {
        super("/core/app-azure-ad-groups");
    }
}

const appAzureAdGroupRepository = new AppAzureAdGroupRepository();

export { AppAzureAdGroupRepository, appAzureAdGroupRepository };
