class DateService {
    public shouldRenewDate(date: Date): boolean {
        const currentDate = new Date();

        const hasYearChanged = date.getFullYear() !== currentDate.getFullYear();
        const hasMonthChanged = date.getMonth() !== currentDate.getMonth();
        const hasDayChanged = date.getDate() !== currentDate.getDate();

        return hasYearChanged || hasMonthChanged || hasDayChanged;
    }
}

const dateService = new DateService();

export { DateService, dateService };
