import Component, { mixins } from "vue-class-component";
import { IPolarisBiUserRole, IEntity, IEntityType, IPolarisBiCoreProject, IPolarisBiProduct, IPolarisBiProductUserRole } from "@/models";
import { PolarisBiUserRoleMixin, HierarchyEntitiesMixin } from "@/mixins";
import { buildLookup } from "@/helpers";

@Component
export class PolarisBiHierarchyEntitiesMixin extends mixins(PolarisBiUserRoleMixin, HierarchyEntitiesMixin) {
    get userRoles(): IPolarisBiUserRole[] {
        return this.$store.getters["polarisbi/userRoles"];
    }

    get productUserRoles(): IPolarisBiProductUserRole[] {
        return this.$store.getters["polarisbi/productUserRoles"];
    }

    get entityTypes(): IEntityType[] {
        return this.$store.getters["app/entityTypes"];
    }

    get products(): IPolarisBiProduct[] {
        return this.$store.getters["polarisbi/products"];
    }

    get polarisBiFlattenEntities(): IEntity[] {
        return this.flattenEntities.filter(e => {
            const entityType = this.getEntityType(e.entityTypeId);
            return entityType.code;
        });
    }

    get polarisBiEntities(): IEntity[] {
        const entities = this.polarisBiFlattenEntities;
        const entitiesIds = entities.map(e => e.entityId);
        const childHashes = buildLookup(entities, entity => entity.parentEntityId);
        const rootEntities = entities.filter(e => e.parentEntityId === null || !entitiesIds.includes(e.parentEntityId));

        rootEntities.forEach(entity => {
            entity.children = childHashes[entity.entityId]?.flatMap(child => this.getChildren(childHashes, child)) ?? [];
        });

        return rootEntities.flatMap(e => e.isVisible ? [e] : e.children);
    }

    get filteredPolarisBiFlattenEntities(): IEntity[] {
        if (this.isApplicationAdmin) {
            return this.polarisBiFlattenEntities;
        }
        else {
            const userRoles = this.userRoles.filter(ur => ur.userId === this.user.userId);
            const entitiesIds = userRoles.map(ur => ur.entityId);
            return this.polarisBiFlattenEntities.filter(e => entitiesIds.includes(e.entityId));
        }
    }

    get filteredPolarisBiEntities(): IEntity[] {
        const entities = this.filteredPolarisBiFlattenEntities;
        const entitiesIds = entities.map(e => e.entityId);
        const childHashes = buildLookup(entities, entity => entity.parentEntityId);
        const rootEntities = entities.filter(e => e.parentEntityId === null || !entitiesIds.includes(e.parentEntityId));

        rootEntities.forEach(entity => {
            entity.children = childHashes[entity.entityId]?.flatMap(child => this.getChildren(childHashes, child)) ?? [];
        });

        return rootEntities.flatMap(e => e.isVisible ? [e] : e.children);
    }

    getEntityType(entityTypeId: number): IEntityType {
        return this.entityTypes.find(et => et.entityTypeId === entityTypeId);
    }

    async mounted(): Promise<void> {
        await this.$store.dispatch("app/loadEntityTypesByHierarchy", this.applicationHierarchy.entityHierarchyId);
    }

    get polarisBiFlattenProducts(): IPolarisBiProduct[] {
        if (this.isApplicationAdmin) {
            return this.products;
        }
        else {
            const productUserRoles = this.productUserRoles.filter(ur => ur.userId === this.user.userId);
            const productsIds = productUserRoles.map(ur => ur.productId);
            return this.products.filter(e => productsIds.includes(e.productId));
        }
    }

    get polarisBiProducts(): IPolarisBiProduct[] {
        const products = this.products;
        const productsIds = products.map(e => e.productId);
        const childHashes = buildLookup(products, product => product.parentProductId);
        const rootProducts = products.filter(e => e.parentProductId === null || !productsIds.includes(e.parentProductId));

        rootProducts.forEach(product => {
            product.children = childHashes[product.productId]?.flatMap(child => this.getProductChildren(childHashes, child)) ?? [];
        });

        return rootProducts.flatMap(e => e.isVisible ? [e] : e.children);
    }

    get polarisBiFilteredProducts(): IPolarisBiProduct[] {
        const products = this.polarisBiFlattenProducts;
        const productsIds = products.map(e => e.productId);
        const childHashes = buildLookup(products, product => product.parentProductId);
        const rootProducts = products.filter(e => e.parentProductId === null || !productsIds.includes(e.parentProductId));

        rootProducts.forEach(product => {
            product.children = childHashes[product.productId]?.flatMap(child => this.getProductChildren(childHashes, child)) ?? [];
        });

        return rootProducts.flatMap(e => e.isVisible ? [e] : e.children);
    }

    private getProductChildren(lookup: Record<number, IPolarisBiProduct[]>, child: IPolarisBiProduct): IPolarisBiProduct[] {
        if (child.isVisible) {
            child.children = lookup[child.productId]?.flatMap(subChild => this.getProductChildren(lookup, subChild)) ?? [];
            return [child];
        }
        else {
            return lookup[child.productId]?.flatMap(subChild => this.getProductChildren(lookup, subChild)) ?? [];
        }
    }

    get polarisBiFlattenCoreProjects(): IPolarisBiCoreProject[] {
        return this.$store.getters["polarisbi/coreProjects"];
    }

    get polarisBiCoreProjects(): IPolarisBiCoreProject[] {
        const projects = this.polarisBiFlattenCoreProjects;
        const projectsIds = projects.map(e => e.coreProjectId);
        const childHashes = buildLookup(projects, project => project.parentCoreProjectId);
        const rootProjects = projects.filter(e => e.parentCoreProjectId === null || !projectsIds.includes(e.parentCoreProjectId));

        rootProjects.forEach(project => {
            project.children = childHashes[project.coreProjectId]?.flatMap(child => this.getCoreProjectChildren(childHashes, child)) ?? [];
        });

        return rootProjects.flatMap(e => e.isVisible ? [e] : e.children);
    }

    private getCoreProjectChildren(lookup: Record<number, IPolarisBiCoreProject[]>, child: IPolarisBiCoreProject): IPolarisBiCoreProject[] {
        if (child.isVisible) {
            child.children = lookup[child.coreProjectId]?.flatMap(subChild => this.getCoreProjectChildren(lookup, subChild)) ?? [];
            return [child];
        }
        else {
            return lookup[child.coreProjectId]?.flatMap(subChild => this.getCoreProjectChildren(lookup, subChild)) ?? [];
        }
    }
}
