export class WaitKeys {
    public static readonly login = "auth.login";

    public static readonly baseData = "global.baseData";

    public static readonly loadInit = "init.load";

    public static readonly addPermission = "permission.add";

    public static readonly dlsRequests = "dls.loadRequests";

    public static readonly groupRequests = "group.loadRequests";

    public static readonly loadHierarchy = "entityHierarchy.load";

    public static readonly mdhRequests = "mdh.loadRequests";

    public static readonly polarisBiRequests = "polarisbi.loadRequests";

    public static readonly polarisBiProducts = "polarisbi.loadProducts";

    public static readonly powerBiRequests = "powerbi.loadRequests";

    public static readonly simpleRequests = "simple.loadRequests";

    public static readonly xtrtRequests = "xtrt.loadRequests";
}
