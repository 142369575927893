import Component, { mixins } from "vue-class-component";
import { IDlsUserRole, IEntity, IEntityType } from "@/models";
import { DlsUserRoleMixin, HierarchyEntitiesMixin } from "@/mixins";
import { buildLookup } from "@/helpers";
import { DlsEntityTypes } from "@/enums";

@Component
export class DlsHierarchyEntitiesMixin extends mixins(DlsUserRoleMixin, HierarchyEntitiesMixin) {
    get userRoles(): IDlsUserRole[] {
        return this.$store.getters["dls/userRoles"];
    }

    get entityTypes(): IEntityType[] {
        return this.$store.getters["app/entityTypes"];
    }

    get dlsFlattenEntities(): IEntity[] {
        return this.flattenEntities.filter(e => {
            const entityType = this.getEntityType(e.entityTypeId);
            return entityType.code !== DlsEntityTypes.Brand;
        });
    }

    get dlsFlattenBrands(): IEntity[] {
        return this.flattenEntities.filter(e => {
            const entityType = this.getEntityType(e.entityTypeId);
            return entityType.code === DlsEntityTypes.Brand;
        });
    }

    get dlsEntities(): IEntity[] {
        const entities = this.dlsFlattenEntities;
        const entitiesIds = entities.map(e => e.entityId);
        const childHashes = buildLookup(entities, entity => entity.parentEntityId);
        const rootEntities = entities.filter(e => e.parentEntityId === null || !entitiesIds.includes(e.parentEntityId));

        rootEntities.forEach(entity => {
            entity.children = childHashes[entity.entityId]?.flatMap(child => this.getChildren(childHashes, child)) ?? [];
        });

        return rootEntities.flatMap(e => e.isVisible ? [e] : e.children);
    }

    get dlsBrands(): IEntity[] {
        const entities = this.dlsFlattenBrands;
        const entitiesIds = entities.map(e => e.entityId);
        const childHashes = buildLookup(entities, entity => entity.parentEntityId);
        const rootEntities = entities.filter(e => e.parentEntityId === null || !entitiesIds.includes(e.parentEntityId));

        rootEntities.forEach(entity => {
            entity.children = childHashes[entity.entityId]?.flatMap(child => this.getChildren(childHashes, child)) ?? [];
        });

        return rootEntities.flatMap(e => e.isVisible ? [e] : e.children);
    }

    get filteredDlsFlattenEntities(): IEntity[] {
        if (this.isApplicationAdmin) {
            return this.dlsFlattenEntities;
        }
        else {
            const userRoles = this.userRoles.filter(ur => ur.userId === this.user.userId);
            const entitiesIds = userRoles.map(ur => ur.entityId);
            return this.dlsFlattenEntities.filter(e => entitiesIds.includes(e.entityId));
        }
    }

    get filteredDlsEntities(): IEntity[] {
        const entities = this.filteredDlsFlattenEntities;
        const entitiesIds = entities.map(e => e.entityId);
        const childHashes = buildLookup(entities, entity => entity.parentEntityId);
        const rootEntities = entities.filter(e => e.parentEntityId === null || !entitiesIds.includes(e.parentEntityId));

        rootEntities.forEach(entity => {
            entity.children = childHashes[entity.entityId]?.flatMap(child => this.getChildren(childHashes, child)) ?? [];
        });

        return rootEntities.flatMap(e => e.isVisible ? [e] : e.children);
    }

    get filteredDlsFlattenBrands(): IEntity[] {
        if (this.isApplicationAdmin) {
            return this.dlsFlattenBrands;
        }
        else {
            const userRoles = this.userRoles.filter(ur => ur.userId === this.user.userId);
            const entitiesIds = userRoles.map(ur => ur.entityId);
            return this.dlsFlattenBrands.filter(e => entitiesIds.includes(e.entityId));
        }
    }

    get filteredDlsBrands(): IEntity[] {
        const entities = this.filteredDlsFlattenBrands;
        const entitiesIds = entities.map(e => e.entityId);
        const childHashes = buildLookup(entities, entity => entity.parentEntityId);
        const rootEntities = entities.filter(e => e.parentEntityId === null || !entitiesIds.includes(e.parentEntityId));

        rootEntities.forEach(entity => {
            entity.children = childHashes[entity.entityId]?.flatMap(child => this.getChildren(childHashes, child)) ?? [];
        });

        return rootEntities.flatMap(e => e.isVisible ? [e] : e.children);
    }

    getEntityType(entityTypeId: number): IEntityType {
        return this.entityTypes.find(et => et.entityTypeId === entityTypeId);
    }

    async mounted(): Promise<void> {
        await this.$store.dispatch("app/loadEntityTypesByHierarchy", this.applicationHierarchy.entityHierarchyId);
    }
}
