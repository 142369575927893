const ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
const ADD_SHARE_POINT_ACCOUNT = "ADD_SHARE_POINT_ACCOUNT";
const ADD_SYNCHRONIZATION = "ADD_SYNCHRONIZATION";
const SET_ATTRIBUTES = "SET_ATTRIBUTES";
const SET_SHARE_POINT_ACCOUNTS = "SET_SHARE_POINT_ACCOUNTS";
const SET_SYNCHRONIZATIONS = "SET_SYNCHRONIZATIONS";
const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE";
const UPDATE_SHARE_POINT_ACCOUNT = "UPDATE_SHARE_POINT_ACCOUNT";
const UPDATE_SYNCHRONIZATION = "UPDATE_SYNCHRONIZATION";

export {
    ADD_ATTRIBUTE,
    ADD_SHARE_POINT_ACCOUNT,
    ADD_SYNCHRONIZATION,
    SET_ATTRIBUTES,
    SET_SHARE_POINT_ACCOUNTS,
    SET_SYNCHRONIZATIONS,
    UPDATE_ATTRIBUTE,
    UPDATE_SHARE_POINT_ACCOUNT,
    UPDATE_SYNCHRONIZATION,
};
