import { MutationTree } from "vuex";
import { ISgpState } from "./state";
import * as mutationTypes from "./mutations-types";
import { IAttribute, ISharePointAccount, ISynchronization } from "@/models";

export const mutations: MutationTree<ISgpState> = {
    [mutationTypes.ADD_ATTRIBUTE]: (state, payload: IAttribute) => {
        state.attributes.push(payload);
    },

    [mutationTypes.ADD_SHARE_POINT_ACCOUNT]: (state, payload: ISharePointAccount) => {
        state.sharePointAccounts.push(payload);
    },

    [mutationTypes.ADD_SYNCHRONIZATION]: (state, payload: ISynchronization) => {
        state.synchronizations.push(payload);
    },

    [mutationTypes.SET_ATTRIBUTES]: (state, payload: IAttribute[]) => {
        state.attributes = payload;
    },

    [mutationTypes.SET_SHARE_POINT_ACCOUNTS]: (state, payload: ISharePointAccount[]) => {
        state.sharePointAccounts = payload;
    },

    [mutationTypes.SET_SYNCHRONIZATIONS]: (state, payload: ISynchronization[]) => {
        state.synchronizations = payload;
    },

    [mutationTypes.UPDATE_ATTRIBUTE]: (state, payload: IAttribute) => {
        const index = state.attributes.findIndex(attribute => attribute.attributeId === payload.attributeId);
        state.attributes.splice(index, 1, payload);
    },

    [mutationTypes.UPDATE_SHARE_POINT_ACCOUNT]: (state, payload: ISharePointAccount) => {
        const index = state.sharePointAccounts.findIndex(sharePointAccount => sharePointAccount.sharePointAccountId === payload.sharePointAccountId);
        state.sharePointAccounts.splice(index, 1, payload);
    },

    [mutationTypes.UPDATE_SYNCHRONIZATION]: (state, payload: ISynchronization) => {
        const index = state.synchronizations.findIndex(synchronization => synchronization.synchronizationId === payload.synchronizationId);
        state.synchronizations.splice(index, 1, payload);
    },
};
