import { IPolarisBiProductUserRole, IUserGraph } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiProductValidatorRepository extends BaseRepository<IPolarisBiProductUserRole> {
    protected getAllErrorMessage = "Product Validators cannot be retrieved.";

    protected getOneErrorMessage = "This Product Validator cannot be retrieved.";

    protected addErrorMessage = "This Product Validator cannot be created, please verify fields.";

    protected updateErrorMessage = "This Product Validator cannot be updated, please verify fields.";

    protected replaceErrorMessage = "Product Validator cannot be upserted, please verify fields.";

    protected removeErrorMessage = "This Product Validator cannot be removed.";

    constructor() {
        super("/polarisbi/product-validators");
    }

    async setProductValidators(productId: number | null, users: IUserGraph[]): Promise<IPolarisBiProductUserRole[]> {
        return await this.put("/", {
            productId,
            users,
        });
    }
}

const polarisBiProductValidatorRepository = new PolarisBiProductValidatorRepository();

export { PolarisBiProductValidatorRepository, polarisBiProductValidatorRepository };
