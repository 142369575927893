import { ActionTree } from "vuex";
import { IPowerBiPermissionCreate, IUserGraph, IPowerBiRequest, IEntity } from "@/models";
import { powerBiEntityRepository, powerBiOptionRepository, powerBiPermissionRepository, powerBiValidatorRepository } from "@/repositories/powerBi";
import { IPowerBiState } from "./state";
import * as mutation from "./mutations-types";
import * as appMutation from "@/store/app/mutations-types";
import { RequestStatuses } from "@/enums";

export const actions: ActionTree<IPowerBiState, any> = {
    addPermission: async (context, payload: { appCode: string; permission: IPowerBiPermissionCreate }): Promise<IPowerBiRequest[]> => {
        const requests = await powerBiPermissionRepository.add(payload.permission) as IPowerBiRequest[];
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
        return requests;
    },

    addRequestFiles: async (context, payload: { appCode: string; requestIds: number[]; files: File[] }): Promise<void> => {
        const requests = await powerBiPermissionRepository.updateRequestFiles(payload.appCode, payload.requestIds, payload.files);
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
    },

    exportHierarchy: async (): Promise<Blob> => await powerBiEntityRepository.download("export"),

    exportUserAccess: async (): Promise<Blob> => await powerBiPermissionRepository.download("export"),

    importValidators: async (context, payload: File): Promise<number> => {
        const validators = await powerBiValidatorRepository.importValidators(payload);
        context.commit(mutation.SET_POWERBI_USER_ROLES, validators);
        return validators.filter(v => v.isDirectPermission).length;
    },

    loadEntities: async (context): Promise<void> => {
        const entities = await powerBiEntityRepository.getAll();
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    loadMyRequests: async (context): Promise<void> => {
        const myRequests = await powerBiPermissionRepository.getAll({}, "my-requests") as IPowerBiRequest[];
        context.commit(mutation.SET_MY_REQUESTS, myRequests);
    },

    loadOptions: async (context): Promise<void> => {
        const options = await powerBiOptionRepository.getAll();
        context.commit(mutation.SET_POWERBI_OPTIONS, options);
    },

    loadPendingRequests: async (context): Promise<void> => {
        const pendingRequests = await powerBiPermissionRepository.getAll({}, "pending-requests") as IPowerBiRequest[];
        context.commit(mutation.SET_PENDING_REQUESTS, pendingRequests);
    },

    loadValidatedRequests: async (context): Promise<void> => {
        const validatedRequests = await powerBiPermissionRepository.getAll({}, "validated-requests") as IPowerBiRequest[];
        context.commit(mutation.SET_VALIDATED_REQUESTS, validatedRequests);
    },

    loadValidators: async (context): Promise<void> => {
        const validators = await powerBiValidatorRepository.getAll();
        context.commit(mutation.SET_POWERBI_USER_ROLES, validators);
    },

    rejectRequests: async (context, payload: { appCode: string; requestIds: number[]; comment: string | null; fromUserAccess: boolean | null }): Promise<void> => {
        const rejectedRequests = await powerBiPermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
            fromUserAccess: payload.fromUserAccess,
        }, "refuse") as IPowerBiRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, rejectedRequests);
    },

    setEntityValidators: async (context, payload: { entityId: number | null; users: IUserGraph[] }): Promise<void> => {
        const validators = await powerBiValidatorRepository.setValidators(payload.entityId, payload.users);
        context.commit(mutation.SET_POWERBI_USER_ROLES, validators);
    },

    updateEntity: async (context, payload: IEntity): Promise<IEntity> => {
        const updatedEntity = await powerBiEntityRepository.update(payload, `/${payload.entityId}`);
        context.commit(`app/${appMutation.UPDATE_ENTITY}`, updatedEntity, { root: true });
        return updatedEntity;
    },

    validateRequests: async (context, payload: { appCode: string; requestIds: number[]; comment: string | null }): Promise<void> => {
        const validatedRequests = await powerBiPermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
        }, "validate") as IPowerBiRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, validatedRequests);
    },
};
