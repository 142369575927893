import { IMasterData } from "@/models";

import { BaseRepository } from "@/repositories";

class MasterDataRepository extends BaseRepository<IMasterData> {
    protected getAllErrorMessage = "Master Datas cannot be retrieved.";

    protected getOneErrorMessage = "This Master Data cannot be retrieved.";

    constructor() {
        super("/mdh/master-datas");
    }
}

const masterDataRepository = new MasterDataRepository();

export { MasterDataRepository, masterDataRepository };
