import { GetterTree } from "vuex";
import { ISimpleState } from "@/store/simple/state";
import { IRequest, ISimpleUserRole, ISimpleValidatedPermission } from "@/models";

const getters: GetterTree<ISimpleState, any> = {
    myPermissions: (state): ISimpleValidatedPermission[] => state.myPermissions,

    myRequests: (state): IRequest[] => state.myRequests,

    pendingRequests: (state): IRequest[] => state.pendingRequests,

    userRoles: (state): ISimpleUserRole[] => state.userRoles,

    validatedRequests: (state): IRequest[] => state.validatedRequests,
};

export { getters };
