import { ActionTree } from "vuex";
import {
    accountRepository,
    dlsEntityRepository,
    dlsPermissionRepository,
    dlsValidatorRepository,
    functionCategoryRepository,
    functionGroupRepository,
    functionRepository,
    pnlRepository,
} from "@/repositories";
import { IDlsState } from "./state";
import * as mutation from "./mutations-types";
import {
    IAccount,
    IDlsPermissionCreate,
    IDlsRequest,
    IEntity,
    IFunction,
    IFunctionGroup,
    IUserGraph,
} from "@/models";
import { RequestStatuses } from "@/enums";
import * as appMutation from "@/store/app/mutations-types";

export const actions: ActionTree<IDlsState, any> = {
    addAccount: async (context, payload: IAccount): Promise<void> => {
        const account = await accountRepository.add(payload);
        context.commit(mutation.ADD_ACCOUNT, account);
    },

    addEntity: async (context, payload: IEntity): Promise<IEntity> => {
        const entity = await dlsEntityRepository.add(payload);
        context.commit(`app/${appMutation.ADD_ENTITY}`, entity, { root: true });
        return entity as IEntity;
    },

    addFunction: async (context, payload: IFunction): Promise<void> => {
        const func = await functionRepository.add(payload);
        context.commit(mutation.ADD_FUNCTION, func);
    },

    addPermission: async (context, payload: IDlsPermissionCreate): Promise<IDlsRequest[]> => {
        const requests = await dlsPermissionRepository.add(payload) as IDlsRequest[];
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
        return requests;
    },

    addRequestFiles: async (context, payload: { requestIds: number[]; files: File[] }): Promise<void> => {
        const requests = await dlsPermissionRepository.updateRequestFiles(payload.requestIds, payload.files);
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
    },

    deleteAccount: async (context, account: IAccount): Promise<void> => {
        const accounts = await accountRepository.remove(account.accountId);
        context.commit(mutation.SET_ACCOUNTS, accounts);
    },

    deleteEntity: async (context, payload: IEntity): Promise<void> => {
        const entities = await dlsEntityRepository.remove(payload.entityId);
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    exportHierarchy: async (): Promise<Blob> => await dlsEntityRepository.download("export"),

    exportUserAccess: async (): Promise<Blob> => await dlsPermissionRepository.download("export"),

    importValidators: async (context, payload: File): Promise<number> => {
        const validators = await dlsValidatorRepository.importValidators(payload);
        context.commit(mutation.SET_DLS_USER_ROLES, validators);
        return validators.filter(v => v.isDirectPermission).length;
    },

    loadAccounts: async (context): Promise<void> => {
        const accounts = await accountRepository.getAll();
        context.commit(mutation.SET_ACCOUNTS, accounts);
    },

    loadEntities: async (context): Promise<void> => {
        const entities = await dlsEntityRepository.getAll();
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    loadFunctions: async (context): Promise<void> => {
        const functions = await functionRepository.getAll();
        context.commit(mutation.SET_FUNCTIONS, functions);
    },

    loadFunctionCategories: async (context): Promise<void> => {
        const functionCategories = await functionCategoryRepository.getAll();
        context.commit(mutation.SET_FUNCTION_CATEGORIES, functionCategories);
    },

    loadFunctionGroups: async (context): Promise<void> => {
        const functionGroups = await functionGroupRepository.getAll();
        context.commit(mutation.SET_FUNCTION_GROUPS, functionGroups);
    },

    loadMyRequests: async (context): Promise<void> => {
        const myRequests = await dlsPermissionRepository.getAll({}, "my-requests") as IDlsRequest[];
        context.commit(mutation.SET_MY_REQUESTS, myRequests);
    },

    loadPendingRequests: async (context): Promise<void> => {
        const pendingRequests = await dlsPermissionRepository.getAll({}, "pending-requests") as IDlsRequest[];
        context.commit(mutation.SET_PENDING_REQUESTS, pendingRequests);
    },

    loadValidatedRequests: async (context): Promise<void> => {
        const validatedRequests = await dlsPermissionRepository.getAll({}, "validated-requests") as IDlsRequest[];
        context.commit(mutation.SET_VALIDATED_REQUESTS, validatedRequests);
    },

    loadPnls: async (context): Promise<void> => {
        const pnls = await pnlRepository.getAll();
        context.commit(mutation.SET_PNLS, pnls);
    },

    loadValidators: async (context): Promise<void> => {
        const validators = await dlsValidatorRepository.getAll();
        context.commit(mutation.SET_DLS_USER_ROLES, validators);
    },

    setEntityValidators: async (context, payload: { entityId: number | null; users: IUserGraph[] }): Promise<void> => {
        const validators = await dlsValidatorRepository.setValidators(payload.entityId, null, payload.users);
        context.commit(mutation.SET_DLS_USER_ROLES, validators);
    },

    setFunctionGroupValidators: async (context, payload: { functionGroupId: number | null; users: IUserGraph[] }): Promise<void> => {
        const validators = await dlsValidatorRepository.setValidators(null, payload.functionGroupId, payload.users);
        context.commit(mutation.SET_DLS_USER_ROLES, validators);
    },

    updateAccount: async (context, account: IAccount): Promise<void> => {
        const updatedAccount = await accountRepository.update(account, `/${account.accountId}`);
        context.commit(mutation.UPDATE_ACCOUNT, updatedAccount);
    },

    updateEntity: async (context, payload: IEntity): Promise<IEntity> => {
        const updatedEntity = await dlsEntityRepository.update(payload, `/${payload.entityId}`);
        context.commit(`app/${appMutation.UPDATE_ENTITY}`, updatedEntity, { root: true });
        return updatedEntity;
    },

    updateFunction: async (context, func: IFunction): Promise<void> => {
        const updatedFunction = await functionRepository.update(func, `/${func.functionId}`);
        context.commit(mutation.UPDATE_FUNCTION, updatedFunction);
    },

    updateFunctionGroup: async (context, functionGroup: IFunctionGroup): Promise<IFunctionGroup> => {
        const updatedFunctionGroup = await functionGroupRepository.update(functionGroup, `/${functionGroup.functionGroupId}`);
        context.commit(mutation.UPDATE_FUNCTION_GROUP, updatedFunctionGroup);
        return updatedFunctionGroup;
    },

    updatePnlByFunction: async (context, functions: IFunction[]): Promise<void> => {
        const payload = functions.map(func => {
            const pnlIds: number[] = [];

            for (const pnlId in func.tablePnls) {
                if (func.tablePnls[pnlId] && func.tablePnls[pnlId] === true) {
                    pnlIds.push(parseInt(pnlId, 10));
                }
            }

            return {
                functionId: func.functionId,
                pnlIds,
            };
        });
        const updatedFunctions = await functionRepository.replace(payload);
        context.commit(mutation.SET_FUNCTIONS, updatedFunctions);
    },

    rejectRequests: async (context, payload: { requests: IDlsRequest[]; comment: string | null; fromUserAccess: boolean | null }): Promise<void> => {
        const rejectedRequests = await dlsPermissionRepository.add({
            requests: payload.requests,
            comment: payload.comment,
            fromUserAccess: payload.fromUserAccess,
        }, "refuse") as IDlsRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, rejectedRequests);
    },

    validateRequests: async (context, payload: { requests: IDlsRequest[]; comment: string | null }): Promise<void> => {
        const validatedRequests = await dlsPermissionRepository.add({
            requests: payload.requests,
            comment: payload.comment,
        }, "validate") as IDlsRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, validatedRequests);
    },
};
