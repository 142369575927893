const ADD_XTRT_AGENCY = "ADD_XTRT_AGENCY";
const ADD_MY_REQUESTS = "ADD_MY_REQUESTS";
const SET_MY_PROFILE = "SET_MY_PROFILE";
const SET_MY_REQUESTS = "SET_MY_REQUESTS";
const SET_PENDING_REQUESTS = "SET_PENDING_REQUESTS";
const SET_VALIDATED_REQUESTS = "SET_VALIDATED_REQUESTS";
const SET_MY_PERMISSIONS = "SET_MY_PERMISSIONS";
const SET_XTRT_AGENCIES = "SET_XTRT_AGENCIES";
const SET_XTRT_USER_ROLES = "SET_XTRT_USER_ROLES";
const SET_XTRT_ROLES = "SET_XTRT_ROLES";
const REMOVE_PENDING_REQUESTS = "REMOVE_PENDING_REQUESTS";
const REMOVE_XTRT_AGENCY = "REMOVE_XTRT_AGENCY";
const UPDATE_XTRT_AGENCY = "UPDATE_XTRT_AGENCY";

export {
    ADD_XTRT_AGENCY,
    ADD_MY_REQUESTS,
    SET_MY_REQUESTS,
    SET_MY_PROFILE,
    SET_PENDING_REQUESTS,
    SET_VALIDATED_REQUESTS,
    SET_MY_PERMISSIONS,
    SET_XTRT_AGENCIES,
    SET_XTRT_USER_ROLES,
    SET_XTRT_ROLES,
    REMOVE_PENDING_REQUESTS,
    REMOVE_XTRT_AGENCY,
    UPDATE_XTRT_AGENCY,
};
