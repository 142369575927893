import { IPolarisBiFunction } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiFunctionRepository extends BaseRepository<IPolarisBiFunction> {
    protected getAllErrorMessage = "Functions cannot be retrieved.";

    protected getOneErrorMessage = "This Function cannot be retrieved.";

    protected addErrorMessage = "This Function cannot be created, please verify fields.";

    protected updateErrorMessage = "This Function cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Function cannot be removed.";

    constructor() {
        super("/polarisbi/functions");
    }
}

const polarisBiFunctionRepository = new PolarisBiFunctionRepository();

export { PolarisBiFunctionRepository, polarisBiFunctionRepository };
