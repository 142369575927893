import { GetterTree } from "vuex";
import { ISgpState } from "./state";
import { IAttribute, ISharePointAccount, ISynchronization } from "@/models";

const getters: GetterTree<ISgpState, any> = {
    attributes: (state): IAttribute[] => state.attributes,

    sharePointAccounts: (state): ISharePointAccount[] => state.sharePointAccounts,

    synchronizations: (state): ISynchronization[] => state.synchronizations,
};

export { getters };
