import * as msal from "@azure/msal-browser";
import { MutationTree } from "vuex";
import { IAccountState } from "./state";
import * as mutationTypes from "./mutations-types";
import { IUser, IUserNotificationOption } from "@/models";

export const mutations: MutationTree<IAccountState> = {
    [mutationTypes.LOGIN]: (state, payload: msal.AccountInfo) => {
        state.msalAccount = payload;
    },

    [mutationTypes.LOGOUT]: (state) => {
        state.msalAccount = null;
    },

    [mutationTypes.SET_APP_ACCOUNT]: (state, payload: IUser) => {
        state.appAccount = payload;
    },

    [mutationTypes.SET_APP_ACCOUNT_LAST_LOGIN]: (state, payload: IUser) => {
        state.appAccount.lastLogin = payload.lastLogin;
    },

    [mutationTypes.SET_FAVORITES]: (state, payload: number[]) => {
        state.appAccount.favorites = payload;
    },

    [mutationTypes.SET_PHOTO]: (state, payload: string) => {
        state.userPhoto = payload;
    },

    [mutationTypes.UPDATE_CONDITIONS_OF_USE]: (state, payload: number[]) => {
        state.appAccount.conditionsOfUse = payload;
    },

    [mutationTypes.UPDATE_NOTIFICATIONS]: (state, payload: IUserNotificationOption[]) => {
        state.appAccount.notificationOptions = payload;
    },
};
