import { IAttribute } from "@/models";

import { BaseRepository } from "@/repositories";

class AttributeRepository extends BaseRepository<IAttribute> {
    protected getAllErrorMessage = "Attributes cannot be retrieved.";

    protected getOneErrorMessage = "This Attribute cannot be retrieved.";

    protected addErrorMessage = "This Attribute cannot be created, please verify fields.";

    protected updateErrorMessage = "This Attribute cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Attribute cannot be removed.";

    constructor() {
        super("/sgp/attributes");
    }
}

const attributeRepository = new AttributeRepository();

export { AttributeRepository, attributeRepository };
