const ADD_MY_REQUESTS = "ADD_MY_REQUESTS";
const CLEAR_GROUP_STORE = "CLEAR_GROUP_STORE";
const REMOVE_PENDING_REQUESTS = "REMOVE_PENDING_REQUESTS";
const REMOVE_VALIDATORS = "REMOVE_VALIDATORS";
const SET_MY_REQUESTS = "SET_MY_REQUESTS";
const SET_PENDING_REQUESTS = "SET_PENDING_REQUESTS";
const SET_VALIDATED_REQUESTS = "SET_VALIDATED_REQUESTS";
const SET_VALIDATORS = "SET_VALIDATORS";
const UPSERT_VALIDATORS = "UPSERT_VALIDATORS";

export {
    ADD_MY_REQUESTS,
    CLEAR_GROUP_STORE,
    REMOVE_PENDING_REQUESTS,
    REMOVE_VALIDATORS,
    SET_MY_REQUESTS,
    SET_PENDING_REQUESTS,
    SET_VALIDATED_REQUESTS,
    SET_VALIDATORS,
    UPSERT_VALIDATORS,
};
