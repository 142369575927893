import format from "date-fns/format";

export const VueDateFilter = (value: string): string => {
    if (!value) {
        return "";
    }

    const date = new Date(value);

    return format(date, "dd/MM/yyyy");
};
