import { IPolarisBiUserRole, IUserGraph } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiValidatorRepository extends BaseRepository<IPolarisBiUserRole> {
    protected getAllErrorMessage = "Validators cannot be retrieved.";

    protected getOneErrorMessage = "This Validator cannot be retrieved.";

    protected addErrorMessage = "This Validator cannot be created, please verify fields.";

    protected updateErrorMessage = "This Validator cannot be updated, please verify fields.";

    protected replaceErrorMessage = "Validator cannot be upserted, please verify fields.";

    protected removeErrorMessage = "This Validator cannot be removed.";

    constructor() {
        super("/polarisbi/validators");
    }

    async importValidators(validatorsFile: File): Promise<IPolarisBiUserRole[]> {
        const formData = new FormData();
        formData.append("validatorsFile", validatorsFile);

        return await this.put("/import", formData, "multipart/form-data");
    }

    async setValidators(entityId: number | null, users: IUserGraph[]): Promise<IPolarisBiUserRole[]> {
        return await this.put("/", {
            entityId,
            users,
        });
    }
}

const polarisBiValidatorRepository = new PolarisBiValidatorRepository();

export { PolarisBiValidatorRepository, polarisBiValidatorRepository };
