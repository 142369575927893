import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";
import { store } from "@/store";

export const groupRoutes: RouteConfig[] = [
    {
        path: "/group/:code",
        component: (): any => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "manage/group-members",
                name: RouteNames.groupSettingsMembers,
                component: () => import(/* webpackChunkName: "GroupSettingsMember" */ "@/views/group/settings/GroupSettingsMember.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        group: true,
                    },
                },
            },
            {
                path: "manage/group-validators",
                name: RouteNames.groupSettingsValidators,
                component: () => import(/* webpackChunkName: "GroupSettingsValidator" */ "@/views/group/settings/GroupSettingsValidator.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        group: true,
                    },
                },
            },
            {
                path: "manage/options",
                name: RouteNames.groupSettingsOptions,
                component: () => import(/* webpackChunkName: "GroupSettingsOptions" */ "@/views/group/settings/GroupSettingsOptions.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        group: true,
                    },
                },
            },
            {
                path: "user-access",
                name: RouteNames.groupUserAccess,
                component: () => import(/* webpackChunkName: "SimpleUserAccess" */ "@/views/group/userAccess/GroupUserAccess.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        group: true,
                    },
                },
            },
            {
                path: "pending-requests",
                name: RouteNames.groupPendingRequests,
                component: () => import(/* webpackChunkName: "GroupPendingRequest" */ "@/views/group/pendingRequest/GroupPendingRequest.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        group: true,
                    },
                },
            },
            {
                path: "permissions",
                name: RouteNames.groupHome,
                component: () => import(/* webpackChunkName: "GroupHome" */ "@/views/group/home/GroupHome.vue"),
                meta: {
                    sidebar: {
                        logo: true,
                        description: true,
                        group: true,
                    },
                },
            },
        ],
        meta: {
            requiresMinRole: {
                roleId: 1,
            },
        },
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
            store.dispatch("group/clearGroupStore");
            next();
        },
    },
];
