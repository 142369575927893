import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import { settings } from "@/settings";
import { scopes, msalInstance } from "./MsalService";

export class BaseClient {
    protected apiClient: AxiosInstance;

    protected constructor(url: string) {
        const baseUrl = `${settings.webApi.baseUrl}${url}`;

        this.apiClient = axios.create({
            baseURL: baseUrl,
            responseType: "json",
        });

        this.apiClient.interceptors.request.use(this.requestInterceptor);
        this.apiClient.interceptors.response.use(undefined, this.blobErrorResponseInterceptor);
        this.apiClient.interceptors.response.use(undefined, this.errorResponseInterceptor);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected async get<T>(service: string, parameters?: any, errorMessage?: string): Promise<T | null> {
        try {
            const response = await this.apiClient.get<T>(service, {
                params: parameters ?? { },
            });

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw new Error(errorMessage);
            }
        }
        catch (error) {
            throw new Error(errorMessage);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected async post<T>(service: string, data?: any, errorMessage?: string): Promise<T | null> {
        try {
            const response = await this.apiClient.post<T>(service, data, {  });

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw new Error(errorMessage);
            }
        }
        catch (error) {
            throw new Error(errorMessage);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected async patch<T>(service: string, data?: any, errorMessage?: string): Promise<T | null> {
        try {
            const response = await this.apiClient.patch<T>(service, data, {  });

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw new Error(errorMessage);
            }
        }
        catch (error) {
            throw new Error(errorMessage);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected async put<T>(service: string, data?: any, type?: string, errorMessage?: string): Promise<T | null> {
        try {
            const response = await this.apiClient.put<T>(service, data, {
                headers: {
                    "Content-Type": type ?? "application/json",
                },
            });

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw new Error(errorMessage);
            }
        }
        catch (error) {
            throw new Error(errorMessage);
        }
    }

    protected async delete<T>(service: string, errorMessage?: string): Promise<T | null> {
        try {
            const response = await this.apiClient.delete<T>(service);

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw new Error(errorMessage);
            }
        }
        catch (error) {
            throw new Error(errorMessage);
        }
    }

    protected async downloadFile(service: string, errorMessage?: string): Promise<Blob> {
        try {
            const response = await this.apiClient.get<Blob>(service, {
                responseType: "blob",
                timeout: 60_000,
            });
            return response.data;
        }
        catch (error) {
            throw new Error(errorMessage);
        }
    }

    protected isAxiosError(error: Error): error is AxiosError {
        return (error as AxiosError).isAxiosError;
    }

    private async requestInterceptor(request: AxiosRequestConfig) {
        const accessToken = await msalInstance.getAccessToken(scopes);

        // Add Authorization token
        if (accessToken != null) {
            request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return Promise.resolve(request);
    }

    private blobErrorResponseInterceptor(error: AxiosError) {
        const contentType: string = error.response?.headers["content-type"]?.toLowerCase();
        if (error.request?.responseType === "blob" && contentType.includes("json")) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    error.response.data = JSON.parse(reader.result as string);
                    resolve(Promise.reject(error));
                };

                reader.onerror = () => reject(error);

                reader.readAsText(error.response.data);
            });
        }

        return Promise.reject(error);
    }

    private async errorResponseInterceptor(error: AxiosError) {
        // If the user is unauthenticated, retry login
        if (error.response?.status === 401) {
            await msalInstance.getAccessToken(scopes);
        }

        return Promise.reject(error);
    }
}
