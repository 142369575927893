import { IAppCategory } from "@/models";

import { BaseRepository } from "@/repositories";

class AppCategoryRepository extends BaseRepository<IAppCategory> {
    protected getAllErrorMessage = "Categories cannot be retrieved.";

    protected getOneErrorMessage = "This Category cannot be retrieved.";

    protected addErrorMessage = "This Category cannot be created, please verify fields and name unicity.";

    protected updateErrorMessage = "This Category cannot be saved, please verify fields and name unicity.";

    protected replaceErrorMessage = "This Category cannot be replaced, please verify fields and name unicity.";

    protected removeErrorMessage = "This Category cannot be removed, please verify it has not Applications.";

    constructor() {
        super("/core/app-categories");
    }

    public getAllCategories(includeInvisible: boolean): Promise<IAppCategory[]> {
        return this.getAll({
            includeInvisible,
        });
    }
}

const appCategoryRepository = new AppCategoryRepository();

export { AppCategoryRepository, appCategoryRepository };
