import { IRequest, IXtrtAgency, IXtrtProfile, IXtrtRole, IXtrtUserRole, IXtrtValidatedPermission } from "@/models";

interface IXtrtState {
    agencies: IXtrtAgency[];
    myProfile: IXtrtProfile | null;
    myPermissions: IXtrtValidatedPermission[];
    myRequests: IRequest[];
    pendingRequests: IRequest[];
    roles: IXtrtRole[];
    userRoles: IXtrtUserRole[];
    validatedRequests: IRequest[];
}

const initialState: IXtrtState = {
    agencies: [],
    myProfile: null,
    myPermissions: [],
    myRequests: [],
    pendingRequests: [],
    roles: [],
    userRoles: [],
    validatedRequests: [],
};

export { IXtrtState, initialState };
