import { IDlsUserRole, IUserGraph } from "@/models";

import { BaseRepository } from "@/repositories";

class DlsValidatorRepository extends BaseRepository<IDlsUserRole> {
    protected getAllErrorMessage = "Validators cannot be retrieved.";

    protected getOneErrorMessage = "This Validator cannot be retrieved.";

    protected addErrorMessage = "This Validator cannot be created, please verify fields.";

    protected updateErrorMessage = "This Validator cannot be updated, please verify fields.";

    protected replaceErrorMessage = "Validator cannot be upserted, please verify fields.";

    protected removeErrorMessage = "This Validator cannot be removed.";

    constructor() {
        super("/dls/validators");
    }

    async importValidators(validatorsFile: File): Promise<IDlsUserRole[]> {
        const formData = new FormData();
        formData.append("validatorsFile", validatorsFile);

        return await this.put("/import", formData, "multipart/form-data");
    }

    async setValidators(entityId: number | null, functionGroupId: number, users: IUserGraph[]): Promise<IDlsUserRole[]> {
        return await this.put("/", {
            entityId,
            functionGroupId,
            users,
        });
    }
}

const dlsValidatorRepository = new DlsValidatorRepository();

export { DlsValidatorRepository, dlsValidatorRepository };
