import { IUser } from "@/models";

class FilterService {
    public isMatchingUser(user: IUser, search: string): boolean {
        const splitSearch = search.split(";");

        if (splitSearch.length > 0) {
            return splitSearch.some(ss => ss.length > 0 && (user.email.toLowerCase().includes(ss) || user.name.toLowerCase().includes(ss)));
        }

        return user.email.toLowerCase().includes(search) || user.name.toLowerCase().includes(search);
    }
}

const filterService = new FilterService();

export { FilterService, filterService };
