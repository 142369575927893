import { GetterTree } from "vuex";
import { IPowerBiState } from "@/store/powerBi/state";
import { IRequest, IPowerBiUserRole, IPowerBiOption } from "@/models";

const getters: GetterTree<IPowerBiState, any> = {
    myRequests: (state): IRequest[] => state.myRequests,

    pendingRequests: (state): IRequest[] => state.pendingRequests,

    userRoles: (state): IPowerBiUserRole[] => state.userRoles,

    validatedRequests: (state): IRequest[] => state.validatedRequests,

    options: (state): IPowerBiOption[] => state.options,
};

export { getters };
