import { buildLookup, buildRecords } from "@/helpers";
import {
    IApp,
    IAppAzureAdGroup,
    IAppCategory, IAppCompanyName,
    IAppModel,
    IAppUserRole,
    IEntity,
    IEntityHierarchy,
    IEntityHierarchyType, IEntityType,
} from "@/models";
import { Route } from "vue-router";
import { GetterTree } from "vuex";
import { IAppState } from "./state";

const getters: GetterTree<IAppState, any> = {
    applications: (state): IApp[] => {
        const hierarchiesLookup = buildLookup(state.hierarchies, hierarchy => hierarchy.appId);
        return state.applications.map<IApp>(app => ({
            ...app,
            hierarchies: hierarchiesLookup[app.appId] ?? [],
        }));
    },

    administrators: (state): IAppUserRole[] => state.administrators,

    categories: (state): IAppCategory[] => state.categories,

    companyNames: (state): IAppCompanyName[] => state.companyNames,

    entities: (state): IEntity[] => state.entities,

    entityTypes: (state): IEntityType[] => state.entityTypes,

    groups: (state): IAppAzureAdGroup[] => state.groups,

    hierarchies: (state): IEntityHierarchy[] => {
        const appsLookup = buildRecords(state.applications, app => app.appId);
        return state.hierarchies.map<IEntityHierarchy>(hierarchy => ({
            ...hierarchy,
            app: appsLookup[hierarchy.appId] ?? null,
        }));
    },

    hierarchyTypes: (state): IEntityHierarchyType[] => state.hierarchyTypes,

    models: (state): IAppModel[] => state.models,

    routeHistory: (state): Route[] => state.routeHistory,

    sidebar: (state): boolean => state.sidebar,
};

export { getters };
