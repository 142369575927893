import { IUser, IUserGraph } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiPrGroupValidatorRepository extends BaseRepository<IUser> {
    protected getAllErrorMessage = "PR Group Validators cannot be retrieved.";

    protected getOneErrorMessage = "This PR Group Validator cannot be retrieved.";

    protected addErrorMessage = "This PR Group Validator cannot be created, please verify fields.";

    protected updateErrorMessage = "This PR Group Validator cannot be updated, please verify fields.";

    protected replaceErrorMessage = "PR Group Validator cannot be upserted, please verify fields.";

    protected removeErrorMessage = "This PR Group Validator cannot be removed.";

    constructor() {
        super("/polarisbi/pr-group-validators");
    }

    async setPrGroupValidators(users: IUserGraph[]): Promise<IUser[]> {
        return await this.put("/", {
            users,
        });
    }
}

const polarisBiPrGroupValidatorRepository = new PolarisBiPrGroupValidatorRepository();

export { PolarisBiPrGroupValidatorRepository, polarisBiPrGroupValidatorRepository };
