import { IGroupGraph, IUser, IUserGraph } from "@/models";
import { BaseClient } from "./BaseClient";

class WebApiGraphClient extends BaseClient {

    public constructor() {
        super("/core");
    }

    public async getUserById(uniqueId: string): Promise<IUserGraph | null> {
        try {
            return await this.get<IUserGraph>(`/graph/users/${uniqueId}`);
        }
        catch (ex) {
            return null;
        }
    }

    public async getUserPhoto(uniqueId: string): Promise<string> {
        try {
            const photo = await this.get<string>(`graph/users/${uniqueId}/photo`);

            return photo ?? "";
        }
        catch (ex) {
            return "";
        }
    }

    public async getUserGroupsMembership(uniqueId: string, appCode: string): Promise<string[]> {
        try {
            const groups = await this.get<string[]>(`graph/users/${uniqueId}/groups/${appCode}`);

            return groups ?? [];
        }
        catch (ex) {
            return [];
        }
    }

    public async getFilteredUsers(prefix: string): Promise<IUserGraph[]> {
        try {
            const params = new URLSearchParams();
            params.append("prefix", prefix);

            return await this.get<IUserGraph[]>("/graph/users", params);
        }
        catch (ex) {
            return [];
        }
    }

    public async getUsersByEmails(emails: string[]): Promise<IUserGraph[]> {
        try {
            const params = new URLSearchParams();
            emails.forEach((e, index) => {
                params.set(`emails[${index}]`, e);
            });

            return await this.get<IUserGraph[]>("/graph/users-by-emails", params);
        }
        catch (ex) {
            return [];
        }
    }

    public async getGroupsByPrefix(prefix: string): Promise<IGroupGraph[]> {
        try {
            const params = new URLSearchParams();
            params.append("prefix", prefix);

            return await this.get<IGroupGraph[]>("/graph/groups", params);
        }
        catch (ex) {
            return [];
        }
    }

    public async getGroupMembers(azureAdId: string, page = 1): Promise<IUserGraph[]> {
        try {
            const params = new URLSearchParams();
            params.append("page", page.toString());

            return await this.get<IUserGraph[]>(`/graph/groups/${azureAdId}/members`, params);
        }
        catch (ex) {
            return [];
        }
    }

    public convertToUserGraph(users: IUser[]): IUserGraph[] {
        return users.map(user => ({
            id: user.uniqueId,
            companyName: user.companyName,
            displayName: user.name,
            mail: user.email,
        } as IUserGraph));
    }
}

const webApiGraphClient = new WebApiGraphClient();

export { WebApiGraphClient, webApiGraphClient };
