const ADD_ACCOUNT = "ADD_ACCOUNT";
const ADD_FUNCTION = "ADD_FUNCTION";
const ADD_MY_REQUESTS = "ADD_MY_REQUESTS";
const REMOVE_PENDING_REQUESTS = "REMOVE_PENDING_REQUESTS";
const SET_ACCOUNTS = "SET_ACCOUNTS";
const SET_DLS_USER_ROLES = "SET_DLS_USER_ROLES";
const SET_FUNCTIONS = "SET_FUNCTIONS";
const SET_FUNCTION_CATEGORIES = "SET_FUNCTION_CATEGORIES";
const SET_FUNCTION_GROUPS = "SET_FUNCTION_GROUPS";
const SET_MY_REQUESTS = "SET_MY_REQUESTS";
const SET_PENDING_REQUESTS = "SET_PENDING_REQUESTS";
const SET_PNLS = "SET_PNLS";
const SET_VALIDATED_REQUESTS = "SET_VALIDATED_REQUESTS";
const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
const UPDATE_FUNCTION = "UPDATE_FUNCTION";
const UPDATE_FUNCTION_GROUP = "UPDATE_FUNCTION_GROUP";

export {
    ADD_ACCOUNT,
    ADD_FUNCTION,
    ADD_MY_REQUESTS,
    REMOVE_PENDING_REQUESTS,
    SET_ACCOUNTS,
    SET_DLS_USER_ROLES,
    SET_FUNCTIONS,
    SET_FUNCTION_CATEGORIES,
    SET_FUNCTION_GROUPS,
    SET_MY_REQUESTS,
    SET_PENDING_REQUESTS,
    SET_PNLS,
    SET_VALIDATED_REQUESTS,
    UPDATE_ACCOUNT,
    UPDATE_FUNCTION,
    UPDATE_FUNCTION_GROUP,
};
