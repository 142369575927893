import { IRequest, ISimpleUserRole, ISimpleValidatedPermission } from "@/models";

interface ISimpleState {
    myPermissions: ISimpleValidatedPermission[];
    myRequests: IRequest[];
    pendingRequests: IRequest[];
    userRoles: ISimpleUserRole[];
    validatedRequests: IRequest[];
}

const initialState: ISimpleState = {
    myPermissions: [],
    myRequests: [],
    pendingRequests: [],
    userRoles: [],
    validatedRequests: [],
};

export { ISimpleState, initialState };
