import { BaseClient } from "@/services";

export abstract class BaseRepository<T> extends BaseClient {
    protected getAllErrorMessage = "";

    protected getOneErrorMessage = "";

    protected addErrorMessage = "";

    protected updateErrorMessage = "";

    protected replaceErrorMessage = "";

    protected removeErrorMessage = "";

    protected constructor(url: string) {
        super(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async getAll(parameters?: any, url?: string): Promise<T[]> {
        return await this.get<T[]>(url, parameters, this.getAllErrorMessage);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async getOne(key: string | number, parameters?: any): Promise<T> {
        return await this.get<T>(`/${key}`, parameters, this.getOneErrorMessage);
    }

    public async download(key: string | number): Promise<Blob> {
        return await this.downloadFile(`${key}`, this.getOneErrorMessage);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async add(data: any, url?: string): Promise<T | T[]> {
        return await this.post<T>(url, data, this.addErrorMessage);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async update(data: any, url?: string): Promise<T> {
        return await this.patch<T>(url, data, this.updateErrorMessage);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async replace(data: any, url?: string, type?: string): Promise<T | T[]> {
        return await this.put<T | T[]>(url, data, type, this.replaceErrorMessage);
    }

    public async remove(id: number, url?: string): Promise<T | T[]> {
        let path = `/${id}`;
        if (url) {
            path = `${url}${path}`;
        }

        return await this.delete(path, this.removeErrorMessage);
    }
}
