import { ActionTree } from "vuex";
import {
    masterDataRepository,
    masterRoleRepository,
    mdhEntityRepository,
    mdhPermissionRepository,
    mdhValidatorRepository,
} from "@/repositories";
import { IMdhState } from "./state";
import * as mutation from "./mutations-types";
import { IEntity, IMasterRole, IMdhPermissionCreate, IMdhRequest } from "@/models";
import { RequestStatuses } from "@/enums";
import * as appMutation from "@/store/app/mutations-types";

export const actions: ActionTree<IMdhState, any> = {
    addEntity: async (context, payload: IEntity): Promise<IEntity> => {
        const entity = await mdhEntityRepository.add(payload);
        context.commit(`app/${appMutation.ADD_ENTITY}`, entity, { root: true });
        return entity as IEntity;
    },

    addPermission: async (context, payload: IMdhPermissionCreate): Promise<IMdhRequest[]> => {
        const requests = await mdhPermissionRepository.add(payload) as IMdhRequest[];
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
        return requests;
    },

    addRequestFiles: async (context, payload: { requestIds: number[]; files: File[] }): Promise<void> => {
        const requests = await mdhPermissionRepository.updateRequestFiles(payload.requestIds, payload.files);
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
    },

    deleteEntity: async (context, payload: IEntity): Promise<void> => {
        const entities = await mdhEntityRepository.remove(payload.entityId);
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    exportHierarchy: async (): Promise<Blob> => await mdhEntityRepository.download("export"),

    exportUserAccess: async (): Promise<Blob> => await mdhPermissionRepository.download("export"),

    loadEntities: async (context): Promise<void> => {
        const entities = await mdhEntityRepository.getAll();
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    loadMasterDatas: async (context, includeInactive = false): Promise<void> => {
        const masterDatas = await masterDataRepository.getAll({ includeInactive });
        context.commit(mutation.SET_MASTER_DATAS, masterDatas);
    },

    loadMasterRoles: async (context, includeInactive = false): Promise<void> => {
        const roles = await masterRoleRepository.getAll({ includeInactive });
        context.commit(mutation.SET_ROLES, roles);
    },

    loadMyRequests: async (context): Promise<void> => {
        const myRequests = await mdhPermissionRepository.getAll({}, "my-requests") as IMdhRequest[];
        context.commit(mutation.SET_MY_REQUESTS, myRequests);
    },

    loadPendingRequests: async (context): Promise<void> => {
        const pendingRequests = await mdhPermissionRepository.getAll({}, "pending-requests") as IMdhRequest[];
        context.commit(mutation.SET_PENDING_REQUESTS, pendingRequests);
    },

    loadSecretKeys: async (context): Promise<void> => {
        const secretKeys = await mdhEntityRepository.getAll({}, "secret-keys") as IEntity[];
        context.commit(mutation.SET_SECRET_KEYS, secretKeys);
    },

    loadValidatedRequests: async (context): Promise<void> => {
        const validatedRequests = await mdhPermissionRepository.getAll({}, "validated-requests") as IMdhRequest[];
        context.commit(mutation.SET_VALIDATED_REQUESTS, validatedRequests);
    },

    loadValidators: async (context): Promise<void> => {
        const validators = await mdhValidatorRepository.getAll();
        context.commit(mutation.SET_MDH_USER_ROLES, validators);
    },

    rejectRequests: async (context, payload: { requestIds: number[]; comment: string | null; fromUserAccess: boolean | null }): Promise<void> => {
        const rejectedRequests = await mdhPermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
            fromUserAccess: payload.fromUserAccess,
        }, "refuse") as IMdhRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, rejectedRequests);
    },

    updateEntity: async (context, payload: IEntity): Promise<IEntity> => {
        const updatedEntity = await mdhEntityRepository.update(payload, `/${payload.entityId}`);
        context.commit(`app/${appMutation.UPDATE_ENTITY}`, updatedEntity, { root: true });
        return updatedEntity;
    },

    updateMasterRole: async (context, payload: IMasterRole): Promise<IMasterRole> => {
        const updatedMasterRole = await masterRoleRepository.update(payload, `/${payload.masterRoleId}`);
        context.commit(mutation.UPDATE_MASTER_ROLE, updatedMasterRole);
        return updatedMasterRole;
    },

    validateRequests: async (context, payload: { requestIds: number[]; comment: string | null }): Promise<void> => {
        const validatedRequests = await mdhPermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
        }, "validate") as IMdhRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, validatedRequests);
    },
};
