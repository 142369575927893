import { IUser, IUserNotificationOption } from "@/models";

import { BaseRepository } from "@/repositories";

class UserRepository extends BaseRepository<IUser | IUserNotificationOption> {
    protected getAllErrorMessage = "Users cannot be retrieved.";

    protected getOneErrorMessage = "This User cannot be retrieved.";

    protected addErrorMessage = "This User cannot be created, please verify fields and UniqueId unicity.";

    protected updateErrorMessage = "This User cannot be updated, please verify fields and UniqueId unicity.";

    protected replaceErrorMessage = "This User cannot be replaced, please verify fields and UniqueId unicity.";

    protected removeErrorMessage = "This User cannot be removed.";

    constructor() {
        super("/core/users");
    }

    public async getCurrentUser(): Promise<IUser | null> {
        return await this.getOne("me") as IUser | null;
    }

    public async setNotificationOptions(notificationOptions: IUserNotificationOption[]): Promise<IUserNotificationOption[]> {
        return await this.replace(notificationOptions, "/notifications") as IUserNotificationOption[];
    }
}

const userRepository = new UserRepository();

export { UserRepository, userRepository };
