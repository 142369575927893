import { ActionTree } from "vuex";
import { IGroupState } from "./state";
import * as mutation from "./mutations-types";
import { groupMemberRepository, groupPermissionRepository, groupValidatorRepository } from "@/repositories";
import { IGroupPermissionCreate, IRequest, IUserGraph } from "@/models";
import { RequestStatuses } from "@/enums";

export const actions: ActionTree<IGroupState, any> = {
    addPermission: async (context, payload: { appCode: string; permission: IGroupPermissionCreate }): Promise<IRequest[]> => {
        const requests = await groupPermissionRepository.add(payload.permission, `/${payload.appCode}`) as IRequest[];
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
        return requests;
    },

    addRequestFiles: async (context, payload: { appCode: string; requestIds: number[]; files: File[] }): Promise<void> => {
        const requests = await groupPermissionRepository.updateRequestFiles(payload.appCode, payload.requestIds, payload.files);
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
    },

    clearGroupStore: async (context): Promise<void> => context.commit(mutation.CLEAR_GROUP_STORE),

    exportUserAccess: async (context, appCode: string): Promise<Blob> => await groupPermissionRepository.download(`/${appCode}/export`),

    loadMyRequests: async (context, appCode: string): Promise<void> => {
        const myRequests = await groupPermissionRepository.getAll({}, `/${appCode}/my-requests`) as IRequest[];
        context.commit(mutation.SET_MY_REQUESTS, myRequests);
    },

    loadPendingRequests: async (context, appCode: string): Promise<void> => {
        const pendingRequests = await groupPermissionRepository.getAll({}, `/${appCode}/pending-requests`) as IRequest[];
        context.commit(mutation.SET_PENDING_REQUESTS, pendingRequests);
    },

    loadValidatedRequests: async (context, appCode: string): Promise<void> => {
        const validatedRequests = await groupPermissionRepository.getAll({}, `/${appCode}/validated-requests`) as IRequest[];
        context.commit(mutation.SET_VALIDATED_REQUESTS, validatedRequests);
    },

    loadValidators: async (context, appCode: string): Promise<void> => {
        const validators = await groupValidatorRepository.getAll({}, `/${appCode}`);
        context.commit(mutation.SET_VALIDATORS, validators);
    },

    rejectRequests: async (context, payload: { appCode: string; requestIds: number[]; comment: string | null; fromUserAccess: boolean | null }): Promise<void> => {
        const rejectedRequests = await groupPermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
            fromUserAccess: payload.fromUserAccess,
        }, `/${payload.appCode}/refuse`) as IRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, rejectedRequests);
    },

    removeUserFromGroup: async (context, payload: { appCode: string; appAzureAdGroupId: number; user: IUserGraph }): Promise<IUserGraph[]> =>
        await groupMemberRepository.removeUserFromGroup(payload.appCode, payload.appAzureAdGroupId, payload.user),

    setValidators: async (context, payload: { appCode: string; appAzureAdGroupId: number; users: IUserGraph[] }): Promise<void> => {
        const validators = await groupValidatorRepository.setValidators(payload.appCode, payload.appAzureAdGroupId, payload.users);
        if (validators.length > 0) {
            context.commit(mutation.UPSERT_VALIDATORS, validators);
        }
        else {
            context.commit(mutation.REMOVE_VALIDATORS, payload.appAzureAdGroupId);
        }
    },

    validateRequests: async (context, payload: { appCode: string; requestIds: number[]; comment: string | null }): Promise<void> => {
        const validatedRequests = await groupPermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
        }, `/${payload.appCode}/validate`) as IRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, validatedRequests);
    },
};
