import Vue from "vue";
import Vuex from "vuex";
import { account } from "./account";
import { IAccountState } from "./account/state";
import { app } from "./app";
import { IAppState } from "./app/state";
import { group } from "./group";
import { IGroupState } from "./group/state";
import { simple } from "./simple";
import { ISimpleState } from "./simple/state";
import { mdh } from "./mdh";
import { IMdhState } from "./mdh/state";
import { sgp } from "./sgp";
import { ISgpState } from "./sgp/state";
import { dls } from "./dls";
import { IDlsState } from "./dls/state";
import { xtrt } from "./xtrt";
import { IXtrtState } from "./xtrt/state";
import { powerbi } from "./powerBi";
import { IPowerBiState } from "./powerBi/state";
import { polarisbi } from "./polarisBi";
import { IPolarisBiState } from "./polarisBi/state";

Vue.use(Vuex);

export interface IState {
    account: IAccountState;
    app: IAppState;
    dls: IDlsState;
    group: IGroupState;
    mdh: IMdhState;
    polarisbi: IPolarisBiState;
    powerbi: IPowerBiState;
    sgp: ISgpState;
    simple: ISimpleState;
    xtrt: IXtrtState;
}

export const store = new Vuex.Store<IState>({
    modules: {
        account,
        app,
        dls,
        group,
        mdh,
        polarisbi,
        powerbi,
        sgp,
        simple,
        xtrt,
    },
});
