import { MutationTree } from "vuex";
import { IXtrtState } from "./state";
import * as mutationTypes from "./mutations-types";
import { IXtrtAgency, IXtrtProfile, IXtrtRequest, IXtrtRole, IXtrtUserRole, IXtrtValidatedPermission } from "@/models/xtrt";

export const mutations: MutationTree<IXtrtState> = {
    [mutationTypes.ADD_MY_REQUESTS]: (state, payload: IXtrtRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.myRequests = state.myRequests.filter(r => !removedIds.includes(r.requestId));
        state.myRequests.unshift(...payload);
    },

    [mutationTypes.SET_MY_PROFILE]: (state, payload: IXtrtProfile) => {
        state.myProfile = payload;
    },

    [mutationTypes.SET_MY_REQUESTS]: (state, payload: IXtrtRequest[]) => {
        state.myRequests = payload;
    },

    [mutationTypes.SET_PENDING_REQUESTS]: (state, payload: IXtrtRequest[]) => {
        state.pendingRequests = payload;
    },

    [mutationTypes.SET_VALIDATED_REQUESTS]: (state, payload: IXtrtRequest[]) => {
        state.validatedRequests = payload;
    },

    [mutationTypes.SET_MY_PERMISSIONS]: (state, payload: IXtrtValidatedPermission[]) => {
        state.myPermissions = payload;
    },

    [mutationTypes.ADD_XTRT_AGENCY]: (state, payload: IXtrtAgency): void => {
        state.agencies.push(payload);
    },

    [mutationTypes.UPDATE_XTRT_AGENCY]: (state, payload: IXtrtAgency): void => {
        state.agencies = [
            ...state.agencies.filter(a => a.xtrtAgencyId !== payload.xtrtAgencyId),
            payload,
        ];
    },

    [mutationTypes.REMOVE_XTRT_AGENCY]: (state, agencyId: number): void => {
        state.agencies = state.agencies.filter(a => a.xtrtAgencyId !== agencyId);
    },

    [mutationTypes.SET_XTRT_AGENCIES]: (state, payload: IXtrtAgency[]) => {
        state.agencies = payload;
    },

    [mutationTypes.SET_XTRT_USER_ROLES]: (state, payload: IXtrtUserRole[]) => {
        state.userRoles = payload;
    },

    [mutationTypes.SET_XTRT_ROLES]: (state, payload: IXtrtRole[]) => {
        state.roles = payload;
    },

    [mutationTypes.REMOVE_PENDING_REQUESTS]: (state, payload: IXtrtRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.pendingRequests = state.pendingRequests.filter(r => !removedIds.includes(r.requestId));
        state.validatedRequests = state.validatedRequests.filter(r => !removedIds.includes(r.requestId));
    },
};
