import Component, { mixins } from "vue-class-component";
import { IEntityHierarchy } from "@/models";
import { ApplicationCodeMixin } from "@/mixins";

@Component
export class ApplicationHierarchyMixin extends mixins(ApplicationCodeMixin) {
    get applicationHierarchy(): IEntityHierarchy | null {
        if (!this.application) {
            return null;
        }

        const entityHierarchies: IEntityHierarchy[] = this.$store.getters["app/hierarchies"];

        const entityHierarchyId = this.application.entityHierarchyIds[0];
        return entityHierarchies.find(eh => eh.entityHierarchyId === entityHierarchyId);
    }
}
