import { IEntity } from "@/models";

import { BaseRepository } from "@/repositories";

class PowerBiEntityRepository extends BaseRepository<IEntity> {
    protected getAllErrorMessage = "Entities cannot be retrieved.";

    protected getOneErrorMessage = "This Entity cannot be retrieved.";

    protected updateErrorMessage = "This Entity cannot be updated, please verify fields.";

    protected replaceErrorMessage = "This Entity cannot be replaced, please verify fields.";

    constructor() {
        super("/powerbi/entities");
    }
}

const powerBiEntityRepository = new PowerBiEntityRepository();

export { PowerBiEntityRepository, powerBiEntityRepository };
