import {
    IApp,
    IAppAzureAdGroup,
    IAppCategory, IAppCompanyName,
    IAppModel,
    IAppUserRole,
    IEntity,
    IEntityHierarchy,
    IEntityHierarchyType,
    IEntityType,
} from "@/models";
import { Route } from "vue-router";

interface IAppState {
    administrators: IAppUserRole[];
    applications: IApp[];
    categories: IAppCategory[];
    companyNames: IAppCompanyName[];
    entities: IEntity[];
    entityTypes: IEntityType[];
    groups: IAppAzureAdGroup[];
    hierarchies: IEntityHierarchy[];
    hierarchyTypes: IEntityHierarchyType[];
    models: IAppModel[];
    routeHistory: Route[];
    sidebar: boolean;
}

const initialState: IAppState = {
    administrators: [],
    applications: [],
    categories: [],
    companyNames: [],
    entities: [],
    entityTypes: [],
    groups: [],
    hierarchies: [],
    hierarchyTypes: [],
    models: [],
    routeHistory: [],
    sidebar: true,
};

export { IAppState, initialState };
