const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const SET_APP_ACCOUNT = "SET_APP_ACCOUNT";
const SET_APP_ACCOUNT_LAST_LOGIN = "SET_APP_ACCOUNT_LAST_LOGIN";
const SET_FAVORITES = "SET_FAVORITES";
const SET_PHOTO = "SET_PHOTO";
const UPDATE_CONDITIONS_OF_USE = "UPDATE_CONDITIONS_OF_USE";
const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";

export {
    LOGIN,
    LOGOUT,
    SET_APP_ACCOUNT,
    SET_APP_ACCOUNT_LAST_LOGIN,
    SET_FAVORITES,
    SET_PHOTO,
    UPDATE_CONDITIONS_OF_USE,
    UPDATE_NOTIFICATIONS,
};
