import { GetterTree } from "vuex";
import { IMdhState } from "./state";
import { IEntity, IMasterData, IMasterRole, IMdhRequest, IMdhUserRole } from "@/models";

const getters: GetterTree<IMdhState, any> = {
    masterDatas: (state): IMasterData[] => state.masterDatas,

    myRequests: (state): IMdhRequest[] => state.myRequests,

    pendingRequests: (state): IMdhRequest[] => state.pendingRequests,

    roles: (state): IMasterRole[] => state.roles,

    secretKeys: (state): IEntity[] => state.secretKeys,

    userRoles: (state): IMdhUserRole[] => state.userRoles,

    validatedRequests: (state): IMdhRequest[] => state.validatedRequests,
};

export { getters };
