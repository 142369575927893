const ADD_MY_REQUESTS = "ADD_MY_REQUESTS";
const CLEAR_POWERBI_STORE = "CLEAR_POWERBI_STORE";
const REMOVE_PENDING_REQUESTS = "REMOVE_PENDING_REQUESTS";
const SET_MY_PERMISSIONS = "SET_MY_PERMISSIONS";
const SET_MY_REQUESTS = "SET_MY_REQUESTS";
const SET_PENDING_REQUESTS = "SET_PENDING_REQUESTS";
const SET_POWERBI_USER_ROLES = "SET_POWERBI_USER_ROLES";
const SET_VALIDATED_REQUESTS = "SET_VALIDATED_REQUESTS";
const SET_POWERBI_OPTIONS = "SET_POWERBI_OPTIONS";

export {
    ADD_MY_REQUESTS,
    CLEAR_POWERBI_STORE,
    REMOVE_PENDING_REQUESTS,
    SET_MY_PERMISSIONS,
    SET_MY_REQUESTS,
    SET_PENDING_REQUESTS,
    SET_POWERBI_USER_ROLES,
    SET_VALIDATED_REQUESTS,
    SET_POWERBI_OPTIONS,
};
