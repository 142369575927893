import {
    IAccount,
    IDlsRequest,
    IDlsUserRole,
    IFunction,
    IFunctionCategory,
    IFunctionGroup,
    IPnl,
} from "@/models";

interface IDlsState {
    accounts: IAccount[];
    functions: IFunction[];
    functionCategories: IFunctionCategory[];
    functionGroups: IFunctionGroup[];
    myRequests: IDlsRequest[];
    pendingRequests: IDlsRequest[];
    pnls: IPnl[];
    userRoles: IDlsUserRole[];
    validatedRequests: IDlsRequest[];
}

const initialState: IDlsState = {
    accounts: [],
    functions: [],
    functionCategories: [],
    functionGroups: [],
    myRequests: [],
    pendingRequests: [],
    pnls: [],
    userRoles: [],
    validatedRequests: [],
};

export { IDlsState, initialState };
