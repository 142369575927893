import Vue from "vue";
import { NavigationGuard, RouteRecord } from "vue-router";
import { WaitKeys } from "@/helpers/WaitKeys";
import { RouteNames } from "@/router";
import { msalInstance } from "@/services";
import { store } from "@/store";
import { IUser } from "@/models";

export const authGuard: NavigationGuard = async (to, from, next) => {
    Vue.nextTick(async () => {
        store.dispatch("wait/start", WaitKeys.login);

        // Login with Azure AD
        const loggedIn = await msalInstance.login();
        if (!loggedIn) {
            return next({ name: RouteNames.unauthorized, params: { errorCode: "We cannot login you from your Microsoft account." } });
        }

        // Load user account
        await store.dispatch("account/loadAccount");
        if (!store.getters["account/isAuthenticated"]) {
            return next({ name: RouteNames.unauthorized, params: { errorCode: "You do not have an account." } });
        }

        // Check user role
        const requiredRoleRoutes: RouteRecord[] = to.matched.filter(route => route.meta.requiresMinRole);
        if (requiredRoleRoutes.length > 0) {
            const requiredRoleRoute = requiredRoleRoutes[requiredRoleRoutes.length - 1];
            if (!store.getters["account/user"]) {
                await store.dispatch("account/loadUser");
            }

            const user: IUser = store.getters["account/user"];
            let requiredAppId = requiredRoleRoute.meta.requiresMinRole?.appId;
            let applicationCode;
            // If the code is in the URL (for Simple & Group apps)
            if (to.params.code) {
                applicationCode = to.params.code;
            }
            // Else (for Custom apps) the code is in the meta of the root parent route
            else {
                const requiredCodeRoute = requiredRoleRoutes[0];
                applicationCode = requiredCodeRoute.meta.code;
            }

            if (store.getters["app/applications"].length === 0) {
                await store.dispatch("app/loadApplications", false);
            }
            const application = store.getters["app/applications"].find(app => app.code === applicationCode);
            requiredAppId = application?.appId ?? 0;

            const requiredRoleId = requiredRoleRoute.meta.requiresMinRole.roleId;
            const hasUserRole = user && user.roles.find(role => role.appId === requiredAppId && role.roleId >= requiredRoleId);
            if (!hasUserRole) {
                return next({ name: RouteNames.unauthorized, params: { errorCode: "You do not have access to this page." } });
            }
        }

        store.dispatch("wait/end", WaitKeys.login);

        return next();
    });
};
