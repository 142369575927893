import { MutationTree } from "vuex";
import { IPolarisBiState } from "./state";
import * as mutationTypes from "./mutations-types";
import {
    IPolarisBiCoreProject,
    IPolarisBiCostCenter,
    IPolarisBiFunction,
    IPolarisBiMarket,
    IPolarisBiModule,
    IPolarisBiPnl,
    IPolarisBiProduct,
    IPolarisBiProductUserRole,
    IPolarisBiProject,
    IPolarisBiRequest,
    IPolarisBiUserRole,
    IUserGraph,
} from "@/models";

export const mutations: MutationTree<IPolarisBiState> = {
    [mutationTypes.ADD_FUNCTION]: (state, payload: IPolarisBiFunction) => {
        state.functions.push(payload);
    },

    [mutationTypes.ADD_MY_REQUESTS]: (state, payload: IPolarisBiRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.myRequests = state.myRequests.filter(r => !removedIds.includes(r.requestId));
        state.myRequests.unshift(...payload);
    },

    [mutationTypes.REMOVE_PENDING_REQUESTS]: (state, payload: IPolarisBiRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.pendingRequests = state.pendingRequests.filter(r => !removedIds.includes(r.requestId));
        state.validatedRequests = state.validatedRequests.filter(r => !removedIds.includes(r.requestId));
    },

    [mutationTypes.SET_CORE_PROJECTS]: (state, payload: IPolarisBiCoreProject[]) => {
        state.coreProjects = payload;
        state.coreProjects.forEach(p => p.disabled = !p.isVisible);
    },

    [mutationTypes.SET_COST_CENTERS]: (state, payload: IPolarisBiCostCenter[]) => {
        state.costCenters = payload;
    },

    [mutationTypes.SET_FUNCTIONS]: (state, payload: IPolarisBiFunction[]) => {
        state.functions = payload;
    },

    [mutationTypes.SET_MARKETS]: (state, payload: IPolarisBiMarket[]) => {
        state.markets = payload;
    },

    [mutationTypes.SET_MODULES]: (state, payload: IPolarisBiModule[]) => {
        state.modules = payload;
    },

    [mutationTypes.SET_MY_REQUESTS]: (state, payload: IPolarisBiRequest[]) => {
        state.myRequests = payload;
    },

    [mutationTypes.SET_PENDING_REQUESTS]: (state, payload: IPolarisBiRequest[]) => {
        state.pendingRequests = payload;
    },

    [mutationTypes.SET_PNLS]: (state, payload: IPolarisBiPnl[]) => {
        state.pnls = payload;
    },

    [mutationTypes.SET_POLARIS_PR_GROUP_VALIDATORS]: (state, payload: IUserGraph[]) => {
        state.prGroupValidators = payload;
    },

    [mutationTypes.SET_POLARIS_PRODUCT_USER_ROLES]: (state, payload: IPolarisBiProductUserRole[]) => {
        state.productUserRoles = payload;
    },

    [mutationTypes.SET_POLARIS_USER_ROLES]: (state, payload: IPolarisBiUserRole[]) => {
        state.userRoles = payload;
    },

    [mutationTypes.SET_PRODUCTS]: (state, payload: IPolarisBiProduct[]) => {
        state.products = payload;
        state.products.forEach(p => p.disabled = !p.isVisible);
    },

    [mutationTypes.SET_PROJECTS]: (state, payload: IPolarisBiProject[]) => {
        state.projects = payload;
    },

    [mutationTypes.SET_VALIDATED_REQUESTS]: (state, payload: IPolarisBiRequest[]) => {
        state.validatedRequests = payload;
    },

    [mutationTypes.UPDATE_FUNCTION]: (state, payload: IPolarisBiFunction) => {
        const index = state.functions.findIndex(func => func.functionId === payload.functionId);
        state.functions.splice(index, 1, payload);
    },

    [mutationTypes.UPDATE_CORE_PROJECT]: (state, payload: IPolarisBiCoreProject) => {
        const index = state.coreProjects.findIndex(coreProject => coreProject.coreProjectId === payload.coreProjectId);
        state.coreProjects.splice(index, 1, payload);
        state.coreProjects.forEach(p => p.disabled = !p.isVisible);
    },

    [mutationTypes.UPDATE_PRODUCT]: (state, payload: IPolarisBiProduct) => {
        const index = state.products.findIndex(product => product.productId === payload.productId);
        state.products.splice(index, 1, payload);
        state.products.forEach(p => p.disabled = !p.isVisible);
    },
};
