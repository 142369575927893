import { IPowerBiOption } from "@/models/powerBi";

import { BaseRepository } from "@/repositories";

class PowerBiOptionRepository extends BaseRepository<IPowerBiOption> {
    protected getAllErrorMessage = "Options cannot be retrieved.";

    constructor() {
        super("/powerbi/options");
    }
}

const powerBiOptionRepository = new PowerBiOptionRepository();

export { PowerBiOptionRepository, powerBiOptionRepository };
