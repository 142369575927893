import { ActionTree } from "vuex";
import { IEntity, IRequest, ISimplePermissionCreate, ISimpleValidatedPermission, IUserGraph } from "@/models";
import { simpleEntityRepository, simplePermissionRepository, simpleValidatorRepository } from "@/repositories";
import { ISimpleState } from "./state";
import * as mutation from "./mutations-types";
import * as appMutation from "../app/mutations-types";
import { RequestStatuses } from "@/enums";

export const actions: ActionTree<ISimpleState, any> = {
    addEntity: async (context, payload: { appCode: string; entity: IEntity }): Promise<IEntity> => {
        const entity = await simpleEntityRepository.add(payload.entity, `/${payload.appCode}`);
        context.commit(`app/${appMutation.ADD_ENTITY}`, entity, { root: true });
        return entity as IEntity;
    },

    addPermission: async (context, payload: { appCode: string; permission: ISimplePermissionCreate }): Promise<IRequest[]> => {
        const requests = await simplePermissionRepository.add(payload.permission, `/${payload.appCode}`) as IRequest[];
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
        return requests;
    },

    addRequestFiles: async (context, payload: { appCode: string; requestIds: number[]; files: File[] }): Promise<void> => {
        const requests = await simplePermissionRepository.updateRequestFiles(payload.appCode, payload.requestIds, payload.files);
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }
    },

    clearSimpleStore: async (context): Promise<void> => context.commit(mutation.CLEAR_SIMPLE_STORE),

    deleteEntity: async (context, payload: { appCode: string; entity: IEntity }): Promise<void> => {
        const entities = await simpleEntityRepository.remove(payload.entity.entityId, `/${payload.appCode}`);
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    exportHierarchy: async (context, appCode: string): Promise<Blob> => await simpleEntityRepository.download(`/${appCode}/export`),

    exportUserAccess: async (context, appCode: string): Promise<Blob> => await simplePermissionRepository.download(`/${appCode}/export`),

    importValidators: async (context, payload: { appCode: string; validatorsFile: File }): Promise<number> => {
        const validators = await simpleValidatorRepository.importValidators(payload.appCode, payload.validatorsFile);
        context.commit(mutation.SET_SIMPLE_USER_ROLES, validators);
        return validators.filter(v => v.isDirectPermission).length;
    },

    loadEntities: async (context, appCode: string): Promise<void> => {
        const entities = await simpleEntityRepository.getAll({}, `/${appCode}`);
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    loadMyPermissions: async (context, appCode: string): Promise<void> => {
        const myPermissions = await simplePermissionRepository.getAll({}, `/${appCode}/my-permissions`) as ISimpleValidatedPermission[];
        context.commit(mutation.SET_MY_PERMISSIONS, myPermissions);
    },

    loadMyRequests: async (context, appCode: string): Promise<void> => {
        const myRequests = await simplePermissionRepository.getAll({}, `/${appCode}/my-requests`) as IRequest[];
        context.commit(mutation.SET_MY_REQUESTS, myRequests);
    },

    loadPendingRequests: async (context, appCode: string): Promise<void> => {
        const pendingRequests = await simplePermissionRepository.getAll({}, `/${appCode}/pending-requests`) as IRequest[];
        context.commit(mutation.SET_PENDING_REQUESTS, pendingRequests);
    },

    loadValidatedRequests: async (context, appCode: string): Promise<void> => {
        const validatedRequests = await simplePermissionRepository.getAll({}, `/${appCode}/validated-requests`) as IRequest[];
        context.commit(mutation.SET_VALIDATED_REQUESTS, validatedRequests);
    },

    loadValidators: async (context, appCode: string): Promise<void> => {
        const validators = await simpleValidatorRepository.getAll({}, `/${appCode}`);
        context.commit(mutation.SET_SIMPLE_USER_ROLES, validators);
    },

    rejectRequests: async (context, payload: { appCode: string; requestIds: number[]; comment: string | null; fromUserAccess: boolean | null }): Promise<void> => {
        const rejectedRequests = await simplePermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
            fromUserAccess: payload.fromUserAccess,
        }, `/${payload.appCode}/refuse`) as IRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, rejectedRequests);
    },

    setEntityValidators: async (context, payload: { appCode: string; entityId: number | null; users: IUserGraph[] }): Promise<void> => {
        const validators = await simpleValidatorRepository.setValidators(payload.appCode, payload.entityId, payload.users);
        context.commit(mutation.SET_SIMPLE_USER_ROLES, validators);
    },

    updateEntity: async (context, payload: { appCode: string; entity: IEntity }): Promise<IEntity> => {
        const updatedEntity = await simpleEntityRepository.update(payload.entity, `/${payload.appCode}/${payload.entity.entityId}`);
        context.commit(`app/${appMutation.UPDATE_ENTITY}`, updatedEntity, { root: true });
        return updatedEntity;
    },

    validateRequests: async (context, payload: { appCode: string; requestIds: number[]; comment: string | null }): Promise<void> => {
        const validatedRequests = await simplePermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
        }, `/${payload.appCode}/validate`) as IRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, validatedRequests);
    },
};
