import { MutationTree } from "vuex";
import { IDlsState } from "./state";
import * as mutationTypes from "./mutations-types";
import {
    IAccount,
    IDlsRequest,
    IDlsUserRole,
    IFunction,
    IFunctionCategory,
    IFunctionGroup,
    IPnl,
} from "@/models";

export const mutations: MutationTree<IDlsState> = {
    [mutationTypes.ADD_ACCOUNT]: (state, payload: IAccount) => {
        state.accounts.push(payload);
    },

    [mutationTypes.ADD_FUNCTION]: (state, payload: IFunction) => {
        state.functions.push(payload);
    },

    [mutationTypes.ADD_MY_REQUESTS]: (state, payload: IDlsRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.myRequests = state.myRequests.filter(r => !removedIds.includes(r.requestId));
        state.myRequests.unshift(...payload);
    },

    [mutationTypes.REMOVE_PENDING_REQUESTS]: (state, payload: IDlsRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.pendingRequests = state.pendingRequests.filter(r => !removedIds.includes(r.requestId));
        state.validatedRequests = state.validatedRequests.filter(r => !removedIds.includes(r.requestId));
    },

    [mutationTypes.SET_ACCOUNTS]: (state, payload: IAccount[]) => {
        state.accounts = payload;
    },

    [mutationTypes.SET_DLS_USER_ROLES]: (state, payload: IDlsUserRole[]) => {
        state.userRoles = payload;
    },

    [mutationTypes.SET_FUNCTIONS]: (state, payload: IFunction[]) => {
        state.functions = payload;
    },

    [mutationTypes.SET_FUNCTION_CATEGORIES]: (state, payload: IFunctionCategory[]) => {
        state.functionCategories = payload;
    },

    [mutationTypes.SET_FUNCTION_GROUPS]: (state, payload: IFunctionGroup[]) => {
        state.functionGroups = payload;
    },

    [mutationTypes.SET_MY_REQUESTS]: (state, payload: IDlsRequest[]) => {
        state.myRequests = payload;
    },

    [mutationTypes.SET_PENDING_REQUESTS]: (state, payload: IDlsRequest[]) => {
        state.pendingRequests = payload;
    },

    [mutationTypes.SET_PNLS]: (state, payload: IPnl[]) => {
        state.pnls = payload;
    },

    [mutationTypes.SET_VALIDATED_REQUESTS]: (state, payload: IDlsRequest[]) => {
        state.validatedRequests = payload;
    },

    [mutationTypes.UPDATE_ACCOUNT]: (state, payload: IAccount) => {
        const index = state.accounts.findIndex(a => a.accountId === payload.accountId);
        state.accounts.splice(index, 1, payload);
    },

    [mutationTypes.UPDATE_FUNCTION]: (state, payload: IFunction) => {
        const index = state.functions.findIndex(func => func.functionId === payload.functionId);
        state.functions.splice(index, 1, payload);
    },

    [mutationTypes.UPDATE_FUNCTION_GROUP]: (state, payload: IFunctionGroup) => {
        const index = state.functionGroups.findIndex(functionGroup => functionGroup.functionGroupId === payload.functionGroupId);
        state.functionGroups.splice(index, 1, payload);
    },
};
