import Component, { mixins } from "vue-class-component";
import { IPolarisBiCoreProject } from "@/models";
import { PolarisBiUserRoleMixin, HierarchyEntitiesMixin } from "@/mixins";
import { buildLookup } from "@/helpers";

@Component
export class PolarisBiCoreProjectMixin extends mixins(PolarisBiUserRoleMixin, HierarchyEntitiesMixin) {
    get polarisBiFlattenCoreProjects(): IPolarisBiCoreProject[] {
        return this.$store.getters["polarisbi/coreProjects"];
    }

    get polarisBiCoreProjects(): IPolarisBiCoreProject[] {
        const projects = this.polarisBiFlattenCoreProjects;
        const projectsIds = projects.map(e => e.coreProjectId);
        const childHashes = buildLookup(projects, project => project.parentCoreProjectId);
        const rootProjects = projects.filter(e => e.parentCoreProjectId === null || !projectsIds.includes(e.parentCoreProjectId));

        rootProjects.forEach(project => {
            project.children = childHashes[project.coreProjectId]?.flatMap(child => this.getCoreProjectChildren(childHashes, child)) ?? [];
        });

        return rootProjects.flatMap(e => [e]);
    }

    private getCoreProjectChildren(lookup: Record<number, IPolarisBiCoreProject[]>, child: IPolarisBiCoreProject): IPolarisBiCoreProject[] {
        child.children = lookup[child.coreProjectId]?.flatMap(subChild => this.getCoreProjectChildren(lookup, subChild)) ?? [];
        return [child];
    }
}
