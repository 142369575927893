import Component, { mixins } from "vue-class-component";
import { IPolarisBiProduct, IPolarisBiProductUserRole } from "@/models";
import { PolarisBiUserRoleMixin, HierarchyEntitiesMixin } from "@/mixins";
import { buildLookup } from "@/helpers";

@Component
export class PolarisBiProductMixin extends mixins(PolarisBiUserRoleMixin, HierarchyEntitiesMixin) {
    get userRoles(): IPolarisBiProductUserRole[] {
        return this.$store.getters["polarisbi/productUserRoles"];
    }

    get polarisBiFlattenProducts(): IPolarisBiProduct[] {
        return this.$store.getters["polarisbi/products"];
    }

    get polarisBiProducts(): IPolarisBiProduct[] {
        const products = this.polarisBiFlattenProducts;
        const productsIds = products.map(e => e.productId);
        const childHashes = buildLookup(products, product => product.parentProductId);
        const rootProducts = products.filter(e => e.parentProductId === null || !productsIds.includes(e.parentProductId));

        rootProducts.forEach(product => {
            product.children = childHashes[product.productId]?.flatMap(child => this.getProductChildren(childHashes, child)) ?? [];
        });

        return rootProducts.flatMap(p => [p]);
    }

    private getProductChildren(lookup: Record<number, IPolarisBiProduct[]>, child: IPolarisBiProduct): IPolarisBiProduct[] {
        child.children = lookup[child.productId]?.flatMap(subChild => this.getProductChildren(lookup, subChild)) ?? [];
        return [child];
    }
}
