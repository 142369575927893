import { BaseRepository } from "../BaseRepository";
import { IXtrtAgency } from "@/models/xtrt";

class XtrtAgencyRepository extends BaseRepository<IXtrtAgency> {
    protected getAllErrorMessage = "Agencies cannot be retrieved.";

    protected getOneErrorMessage = "Agency cannot be retrieved.";

    protected addErrorMessage = "Agency cannot be created, please verify fields.";

    protected updateErrorMessage = "Agency cannot be updated, please verify fields.";

    protected removeErrorMessage = "Agency cannot be removed.";

    constructor() {
        super("/xtrt/agencies");
    }
}

const xtrtAgencyRepository = new XtrtAgencyRepository();

export { XtrtAgencyRepository, xtrtAgencyRepository };
