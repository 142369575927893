import { MutationTree } from "vuex";
import { IMdhState } from "./state";
import * as mutationTypes from "./mutations-types";
import { IEntity, IMasterData, IMasterRole, IMdhRequest, IMdhUserRole } from "@/models";

export const mutations: MutationTree<IMdhState> = {
    [mutationTypes.ADD_MY_REQUESTS]: (state, payload: IMdhRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.myRequests = state.myRequests.filter(r => !removedIds.includes(r.requestId));
        state.myRequests.unshift(...payload);
    },

    [mutationTypes.REMOVE_PENDING_REQUESTS]: (state, payload: IMdhRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.pendingRequests = state.pendingRequests.filter(r => !removedIds.includes(r.requestId));
        state.validatedRequests = state.validatedRequests.filter(r => !removedIds.includes(r.requestId));
    },

    [mutationTypes.SET_MASTER_DATAS]: (state, payload: IMasterData[]) => {
        state.masterDatas = payload;
    },

    [mutationTypes.SET_MDH_USER_ROLES]: (state, payload: IMdhUserRole[]) => {
        state.userRoles = payload;
    },

    [mutationTypes.SET_MY_REQUESTS]: (state, payload: IMdhRequest[]) => {
        state.myRequests = payload;
    },

    [mutationTypes.SET_PENDING_REQUESTS]: (state, payload: IMdhRequest[]) => {
        state.pendingRequests = payload;
    },

    [mutationTypes.SET_ROLES]: (state, payload: IMasterRole[]) => {
        state.roles = payload;
    },

    [mutationTypes.SET_SECRET_KEYS]: (state, payload: IEntity[]) => {
        state.secretKeys = payload;
    },

    [mutationTypes.SET_VALIDATED_REQUESTS]: (state, payload: IMdhRequest[]) => {
        state.validatedRequests = payload;
    },

    [mutationTypes.UPDATE_MASTER_ROLE]: (state, payload: IMasterRole) => {
        const index = state.roles.findIndex(role => role.masterRoleId === payload.masterRoleId);
        state.roles.splice(index, 1, payload);
    },
};
