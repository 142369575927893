class FileService {
    public forceDownloadFile(blob: Blob, fileName: string): void {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
    }
}

const fileService = new FileService();

export { FileService, fileService };
