export class RouteNames {
    public static readonly coreApplications = "CoreApplications";

    public static readonly coreCategories = "CoreCategories";

    public static readonly coreHierarchies = "CoreHierarchies";

    public static readonly coreGroups = "CoreGroups";

    public static readonly dlsHome = "DlsHome";

    public static readonly dlsPendingRequests = "DlsPendingRequest";

    public static readonly dlsSettingsAccounts = "DlsSettingsAccount";

    public static readonly dlsSettingsFunctions = "DlsSettingsFunction";

    public static readonly dlsSettingsFunctionGroups = "DlsSettingsFunctionGroup";

    public static readonly dlsSettingsHierarchy = "DlsSettingsHierarchy";

    public static readonly dlsSettingsOptions = "DlsSettingsOptions";

    public static readonly dlsSettingsPnl = "DlsSettingsPnl";

    public static readonly dlsUserAccess = "DlsUserAccess";

    public static readonly groupHome = "GroupHome";

    public static readonly groupPendingRequests = "GroupPendingRequest";

    public static readonly groupSettingsMembers = "GroupSettingsMember";

    public static readonly groupSettingsOptions = "GroupSettingsOptions";

    public static readonly groupSettingsValidators = "GroupSettingsValidator";

    public static readonly groupUserAccess = "GroupUserAccess";

    public static readonly home = "Home";

    public static readonly mdhHome = "MdhHome";

    public static readonly mdhPendingRequests = "MdhPendingRequest";

    public static readonly mdhUserAccess = "MdhUserAccess";

    public static readonly mdhSettingsHierarchy = "MdhSettingsHierarchy";

    public static readonly mdhSettingsMasterData = "MdhSettingsMasterData";

    public static readonly mdhSettingsOptions = "MdhSettingsOptions";

    public static readonly mdhSettingsRole = "MdhSettingsRole";

    public static readonly myProfile = "MyProfile";

    public static readonly notFound = "NotFound";

    public static readonly polarisBiHome = "PolarisBiHome";

    public static readonly polarisBiPendingRequests = "PolarisBiPendingRequest";

    public static readonly polarisBiSettingsAccounts = "PolarisBiSettingsAccount";

    public static readonly polarisBiSettingsFunctions = "PolarisBiSettingsFunction";

    public static readonly polarisBiSettingsHierarchy = "PolarisBiSettingsHierarchy";

    public static readonly polarisBiSettingsOptions = "PolarisBiSettingsOptions";

    public static readonly polarisBiSettingsMarkets = "PolarisBiSettingsMarket";

    public static readonly polarisBiSettingsCostCenters = "PolarisBiSettingsCostCenter";

    public static readonly polarisBiSettingsProjects = "PolarisBiSettingsProject";

    public static readonly polarisBiSettingsPnl = "PolarisBiSettingsPnl";

    public static readonly polarisBiSettingsProduct = "PolarisBiSettingsProduct";

    public static readonly polarisBiSettingsCoreProject = "PolarisBiSettingsCoreProject";

    public static readonly polarisBiSettingsPrGroupValidator = "PolarisBiSettingsPrGroupValidator";

    public static readonly polarisBiUserAccess = "PolarisBiUserAccess";

    public static readonly powerBiHome = "PowerBiHome";

    public static readonly powerBiPendingRequests = "PowerBiPendingRequest";

    public static readonly powerBiUserAccess = "PowerBiUserAccess";

    public static readonly powerBiSettingsHierarchy = "PowerBiSettingsHierarchy";

    public static readonly powerBiSettingsOptions = "PowerBiSettingsOptions";

    public static readonly sgpPermissions = "SgpPermission";

    public static readonly sgpSettingsAttributes = "SgpSettingsAttribute";

    public static readonly sgpSettingsSharePointAccounts = "SgpSettingsSharePointAccount";

    public static readonly sgpSettingsSynchronizations = "SgpSettingsSynchronization";

    public static readonly simpleHome = "SimpleHome";

    public static readonly simplePendingRequests = "SimplePendingRequest";

    public static readonly simpleUserAccess = "SimpleUserAccess";

    public static readonly simpleSettingsHierarchy = "SimpleSettingsHierarchy";

    public static readonly simpleSettingsGlobalValidators = "SimpleSettingsGlobalValidator";

    public static readonly simpleSettingsOptions = "SimpleSettingsOptions";

    public static readonly xtrtUserAccess = "XtrtUserAccess";

    public static readonly xtrtPendingRequests = "XtrtPendingRequests";

    public static readonly xtrtSettingsAgency = "XtrtSettingsAgency";

    public static readonly xtrtSettingsHierarchy = "XtrtSettingsHierarchy";

    public static readonly xtrtSettingsOptions = "XtrtSettingsOptions";

    public static readonly xtrtHome = "XtrtHome";

    public static readonly unauthorized = "Unauthorized";
}
