import Component, { mixins } from "vue-class-component";
import { IEntity, IPowerBiUserRole } from "@/models";
import { ApplicationUserRoleMixin, HierarchyEntitiesMixin } from "@/mixins";
import { buildLookup } from "@/helpers";

@Component
export class PowerBiHierarchyEntitiesMixin extends mixins(ApplicationUserRoleMixin, HierarchyEntitiesMixin) {
    get userRoles(): IPowerBiUserRole[] {
        return this.$store.getters["powerbi/userRoles"];
    }

    get filteredFlattenEntities(): IEntity[] {
        if (this.isApplicationAdmin) {
            return this.flattenEntities;
        }
        else {
            const userRoles = this.userRoles.filter(ur => ur.userId === this.user.userId);
            const entitiesIds = userRoles.map(ur => ur.entityId);
            return this.flattenEntities.filter(e => entitiesIds.includes(e.entityId));
        }
    }

    get filteredEntities(): IEntity[] {
        const entities = this.filteredFlattenEntities;
        const entitiesIds = entities.map(e => e.entityId);
        const childHashes = buildLookup(entities, entity => entity.parentEntityId);
        const rootEntities = entities.filter(e => e.parentEntityId === null || !entitiesIds.includes(e.parentEntityId));

        rootEntities.forEach(entity => {
            entity.children = childHashes[entity.entityId]?.flatMap(child => this.getChildren(childHashes, child)) ?? [];
        });

        return rootEntities.flatMap(e => e.isVisible ? [e] : e.children);
    }
}
