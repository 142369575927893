import {
    IPolarisBiCoreProject,
    IPolarisBiCostCenter,
    IPolarisBiFunction,
    IPolarisBiMarket,
    IPolarisBiModule,
    IPolarisBiPnl,
    IPolarisBiProduct,
    IPolarisBiProductUserRole,
    IPolarisBiProject,
    IPolarisBiRequest,
    IPolarisBiUserRole,
    IUserGraph,
} from "@/models";

interface IPolarisBiState {
    coreProjects: IPolarisBiCoreProject[];
    costCenters: IPolarisBiCostCenter[];
    functions: IPolarisBiFunction[];
    markets: IPolarisBiMarket[];
    modules: IPolarisBiModule[];
    myRequests: IPolarisBiRequest[];
    pendingRequests: IPolarisBiRequest[];
    pnls: IPolarisBiPnl[];
    prGroupValidators: IUserGraph[];
    products: IPolarisBiProduct[];
    projects: IPolarisBiProject[];
    userRoles: IPolarisBiUserRole[];
    productUserRoles: IPolarisBiProductUserRole[];
    validatedRequests: IPolarisBiRequest[];
}

const initialState: IPolarisBiState = {
    coreProjects: [],
    costCenters: [],
    functions: [],
    markets: [],
    modules: [],
    myRequests: [],
    pendingRequests: [],
    pnls: [],
    prGroupValidators: [],
    products: [],
    projects: [],
    userRoles: [],
    productUserRoles: [],
    validatedRequests: [],
};

export { IPolarisBiState, initialState };
