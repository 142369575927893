import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const mdhRoutes: RouteConfig[] = [
    {
        path: "/mdh",
        component: (): any => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "manage/master-data",
                name: RouteNames.mdhSettingsMasterData,
                component: () => import(/* webpackChunkName: "MdhSettingsMasterData" */ "@/views/mdh/settings/MdhSettingsMasterData.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        mdh: true,
                    },
                },
            },
            {
                path: "manage/hierarchy",
                name: RouteNames.mdhSettingsHierarchy,
                component: () => import(/* webpackChunkName: "MdhSettingsHierarchy" */ "@/views/mdh/settings/MdhSettingsHierarchy.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        mdh: true,
                    },
                },
            },
            {
                path: "manage/role",
                name: RouteNames.mdhSettingsRole,
                component: () => import(/* webpackChunkName: "MdhSettingsRole" */ "@/views/mdh/settings/MdhSettingsRole.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        mdh: true,
                    },
                },
            },
            {
                path: "manage/options",
                name: RouteNames.mdhSettingsOptions,
                component: () => import(/* webpackChunkName: "MdhSettingsOptions" */ "@/views/mdh/settings/MdhSettingsOptions.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        mdh: true,
                    },
                },
            },
            {
                path: "user-access",
                name: RouteNames.mdhUserAccess,
                component: () => import(/* webpackChunkName: "MdhUserAccess" */ "@/views/mdh/userAccess/MdhUserAccess.vue"),
                meta: {
                    sidebar: {
                        logo: true,
                        description: true,
                        mdh: true,
                    },
                },
            },
            {
                path: "pending-requests",
                name: RouteNames.mdhPendingRequests,
                component: () => import(/* webpackChunkName: "MdhPendingRequest" */ "@/views/mdh/pendingRequest/MdhPendingRequest.vue"),
                meta: {
                    sidebar: {
                        logo: true,
                        description: true,
                        mdh: true,
                    },
                },
            },
            {
                path: "permissions",
                name: RouteNames.mdhHome,
                component: () => import(/* webpackChunkName: "MdhHome" */ "@/views/mdh/home/MdhHome.vue"),
                meta: {
                    sidebar: {
                        logo: true,
                        description: true,
                        mdh: true,
                    },
                },
            },
        ],
        meta: {
            code: "mdh",
            requiresMinRole: {
                roleId: 1,
                appId: 6,
            },
        },
    },
];
