import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import VueMeta from "vue-meta";
import { authGuard } from "./AuthGuard";
import { RouteNames } from "./RouteNames";
import { coreRoutes } from "./CoreRoutes";
import { simpleRoutes } from "./SimpleRoutes";
import { groupRoutes } from "./GroupRoutes";
import { dlsRoutes } from "./DlsRoutes";
import { mdhRoutes } from "./MdhRoutes";
import { polarisBiRoutes } from "./PolarisBiRoutes";
import { powerBiRoutes } from "./PowerBiRoutes";
import { sgpRoutes } from "./SgpRoutes";
import { xtrtRoutes } from "./XtrtRoutes";
import { store } from "@/store";

Vue.use(VueRouter);
Vue.use(VueMeta);

const otherRoutes: RouteConfig[] = [
    {
        path: "/",
        component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "my-profile",
                name: RouteNames.myProfile,
                component: () => import(/* webpackChunkName: "myProfile" */ "@/views/myProfile/MyProfile.vue"),
                meta: {
                    sidebar: {
                        am: true,
                    },
                },
            },
            {
                path: "",
                name: RouteNames.home,
                component: () => import(/* webpackChunkName: "home" */ "@/views/home/Home.vue"),
                meta: {
                    sidebar: {
                        am: true,
                        home: true,
                    },
                },
            },
        ],
    },
    {
        path: "/auth",
        redirect: "/",
    },
    {
        path: "/unauthorized",
        name: RouteNames.unauthorized,
        component: () => import(/* webpackChunkName: "unauthorized" */ "@/views/unauthorized/Unauthorized.vue"),
    },
    {
        path: "*",
        name: RouteNames.notFound,
        component: () => import(/* webpackChunkName: "notFound" */ "@/views/notFound/NotFound.vue"),
    },
];

const allRoutes =
    coreRoutes
        .concat(simpleRoutes)
        .concat(groupRoutes)
        .concat(dlsRoutes)
        .concat(mdhRoutes)
        .concat(polarisBiRoutes)
        .concat(powerBiRoutes)
        .concat(sgpRoutes)
        .concat(xtrtRoutes)
        .concat(otherRoutes)
;

const router = new VueRouter({
    mode: "history",
    routes: allRoutes,
    scrollBehavior: (to) => {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: "smooth",
            };
        }
    },
});

router.beforeEach(authGuard);
router.afterEach((to) => {
    store.dispatch("app/addRouteHistory", to);
});

export { router, RouteNames };
