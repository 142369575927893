import { IRequest, IPowerBiUserRole, IPowerBiOption } from "@/models";

interface IPowerBiState {
    myRequests: IRequest[];
    pendingRequests: IRequest[];
    userRoles: IPowerBiUserRole[];
    validatedRequests: IRequest[];
    options: IPowerBiOption[];
}

const initialState: IPowerBiState = {
    myRequests: [],
    pendingRequests: [],
    userRoles: [],
    validatedRequests: [],
    options: [],
};

export { IPowerBiState, initialState };
