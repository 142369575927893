import { IGroupValidator, IRequest } from "@/models";

interface IGroupState {
    myRequests: IRequest[];
    pendingRequests: IRequest[];
    validatedRequests: IRequest[];
    validators: IGroupValidator[];
}

const initialState: IGroupState = {
    myRequests: [],
    pendingRequests: [],
    validatedRequests: [],
    validators: [],
};

export { IGroupState, initialState };
