import { ActionTree } from "vuex";
import { IXtrtState } from "./state";
import { IEntity, IUserGraph, IXtrtAgency, IXtrtPermissionCreate, IXtrtProfile, IXtrtRequest, IXtrtValidatedPermission } from "@/models";
import { xtrtAgencyRepository, xtrtEntityRepository, xtrtPermissionRepository, xtrtRoleRepository, xtrtValidatorRepository } from "@/repositories/xtrt";
import * as appMutation from "@/store/app/mutations-types";
import * as mutation from "./mutations-types";
import { RequestStatuses } from "@/enums";

export const actions: ActionTree<IXtrtState, any> = {
    loadAgencies: async (context): Promise<void> => {
        const agencies = await xtrtAgencyRepository.getAll();
        context.commit(mutation.SET_XTRT_AGENCIES, agencies);
    },

    addAgency: async (context, agency: IXtrtAgency): Promise<void> => {
        const newAgency = await xtrtAgencyRepository.add(agency);
        context.commit(mutation.ADD_XTRT_AGENCY, newAgency);
    },

    updateAgency: async (context, agency: IXtrtAgency): Promise<void> => {
        const updatedAgency = await xtrtAgencyRepository.update(agency);
        context.commit(mutation.UPDATE_XTRT_AGENCY, updatedAgency);
    },

    removeAgency: async (context, agencyId: number): Promise<void> => {
        await xtrtAgencyRepository.remove(agencyId);
        context.commit(mutation.REMOVE_XTRT_AGENCY, agencyId);
    },

    loadRoles: async (context): Promise<void> => {
        const agencies = await xtrtRoleRepository.getAll();
        context.commit(mutation.SET_XTRT_ROLES, agencies);
    },

    loadMyProfile: async (context): Promise<void> => {
        const myProfile = await xtrtPermissionRepository.getOne("my-profile") as IXtrtProfile;
        context.commit(mutation.SET_MY_PROFILE, myProfile);
    },

    loadMyRequests: async (context): Promise<void> => {
        const myRequests = await xtrtPermissionRepository.getAll({}, "my-requests") as IXtrtRequest[];
        context.commit(mutation.SET_MY_REQUESTS, myRequests);
    },

    loadPendingRequests: async (context): Promise<void> => {
        const pendingRequests = await xtrtPermissionRepository.getAll({}, "pending-requests") as IXtrtRequest[];
        context.commit(mutation.SET_PENDING_REQUESTS, pendingRequests);
    },

    loadValidatedRequests: async (context): Promise<void> => {
        const validatedRequests = await xtrtPermissionRepository.getAll({}, "validated-requests") as IXtrtRequest[];
        context.commit(mutation.SET_VALIDATED_REQUESTS, validatedRequests);
    },

    exportHierarchy: async (): Promise<Blob> => await xtrtEntityRepository.download("export"),

    exportUserAccess: async (): Promise<Blob> => await xtrtPermissionRepository.download("export"),

    importValidators: async (context, payload: File): Promise<number> => {
        const validators = await xtrtValidatorRepository.importValidators(payload);
        context.commit(mutation.SET_XTRT_USER_ROLES, validators);
        return validators.filter(v => v.isDirectPermission).length;
    },

    addPermission: async (context, payload: { appCode: string; permission: IXtrtPermissionCreate }): Promise<IXtrtRequest[]> => {
        const requests = await xtrtPermissionRepository.add(payload.permission) as IXtrtRequest[];
        if (requests.length > 0 && requests[0].requestStatusId === RequestStatuses.Pending) {
            context.commit(mutation.ADD_MY_REQUESTS, requests);
        }

        return requests;
    },

    loadMyPermissions: async (context): Promise<void> => {
        const myPermissions = await xtrtPermissionRepository.getAll({}, "my-permissions") as IXtrtValidatedPermission[];
        context.commit(mutation.SET_MY_PERMISSIONS, myPermissions);
    },

    loadEntities: async (context): Promise<void> => {
        const entities = await xtrtEntityRepository.getAll();
        context.commit(`app/${appMutation.SET_ENTITIES}`, entities, { root: true });
    },

    updateEntity: async (context, payload: IEntity): Promise<IEntity> => {
        const updatedEntity = await xtrtEntityRepository.update(payload, `/${payload.entityId}`);
        context.commit(`app/${appMutation.UPDATE_ENTITY}`, updatedEntity, { root: true });
        return updatedEntity;
    },

    loadValidators: async (context): Promise<void> => {
        const validators = await xtrtValidatorRepository.getAll();
        context.commit(mutation.SET_XTRT_USER_ROLES, validators);
    },

    setEntityValidators: async (context, payload: { entityId: number | null; users: IUserGraph[] }): Promise<void> => {
        const validators = await xtrtValidatorRepository.setValidators(payload.entityId, payload.users);
        context.commit(mutation.SET_XTRT_USER_ROLES, validators);
    },

    rejectRequests: async (context, payload: { appCode: string; requestIds: number[]; comment: string | null; fromUserAccess: boolean | null }): Promise<void> => {
        const rejectedRequests = await xtrtPermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
            fromUserAccess: payload.fromUserAccess,
        }, "refuse") as IXtrtRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, rejectedRequests);
    },

    validateRequests: async (context, payload: { appCode: string; requestIds: number[]; comment: string | null }): Promise<void> => {
        const validatedRequests = await xtrtPermissionRepository.add({
            requestIds: payload.requestIds,
            comment: payload.comment,
        }, "validate") as IXtrtRequest[];
        context.commit(mutation.REMOVE_PENDING_REQUESTS, validatedRequests);
    },
};
