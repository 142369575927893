import { MutationTree } from "vuex";
import { IGroupState } from "./state";
import * as mutationTypes from "./mutations-types";
import { IGroupValidator, IRequest } from "@/models";

export const mutations: MutationTree<IGroupState> = {
    [mutationTypes.ADD_MY_REQUESTS]: (state, payload: IRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.myRequests = state.myRequests.filter(r => !removedIds.includes(r.requestId));
        state.myRequests.unshift(...payload);
    },

    [mutationTypes.CLEAR_GROUP_STORE]: (state) => {
        state.myRequests = [];
        state.pendingRequests = [];
        state.validatedRequests = [];
    },

    [mutationTypes.REMOVE_PENDING_REQUESTS]: (state, payload: IRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.pendingRequests = state.pendingRequests.filter(r => !removedIds.includes(r.requestId));
        state.validatedRequests = state.validatedRequests.filter(r => !removedIds.includes(r.requestId));
    },

    [mutationTypes.REMOVE_VALIDATORS]: (state, payload: number) => {
        state.validators = state.validators.filter(v => v.appAzureAdGroupId !== payload);
    },

    [mutationTypes.SET_MY_REQUESTS]: (state, payload: IRequest[]) => {
        state.myRequests = payload;
    },

    [mutationTypes.SET_PENDING_REQUESTS]: (state, payload: IRequest[]) => {
        state.pendingRequests = payload;
    },

    [mutationTypes.SET_VALIDATED_REQUESTS]: (state, payload: IRequest[]) => {
        state.validatedRequests = payload;
    },

    [mutationTypes.SET_VALIDATORS]: (state, payload: IGroupValidator[]) => {
        state.validators = payload;
    },

    [mutationTypes.UPSERT_VALIDATORS]: (state, payload: IGroupValidator[]) => {
        const validators = state.validators.filter(v => v.appAzureAdGroupId !== payload[0].appAzureAdGroupId);
        validators.push(...payload);
        state.validators = validators;
    },
};
