import { MutationTree } from "vuex";
import { IPowerBiState } from "./state";
import * as mutationTypes from "./mutations-types";
import { IRequest, IPowerBiUserRole, IPowerBiOption } from "@/models";

export const mutations: MutationTree<IPowerBiState> = {
    [mutationTypes.ADD_MY_REQUESTS]: (state, payload: IRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.myRequests = state.myRequests.filter(r => !removedIds.includes(r.requestId));
        state.myRequests.unshift(...payload);
    },

    [mutationTypes.CLEAR_POWERBI_STORE]: (state) => {
        state.myRequests = [];
        state.pendingRequests = [];
        state.userRoles = [];
        state.validatedRequests = [];
        state.options = [];
    },

    [mutationTypes.REMOVE_PENDING_REQUESTS]: (state, payload: IRequest[]) => {
        const removedIds = payload.map(r => r.requestId);
        state.pendingRequests = state.pendingRequests.filter(r => !removedIds.includes(r.requestId));
        state.validatedRequests = state.validatedRequests.filter(r => !removedIds.includes(r.requestId));
    },

    [mutationTypes.SET_MY_REQUESTS]: (state, payload: IRequest[]) => {
        state.myRequests = payload;
    },

    [mutationTypes.SET_PENDING_REQUESTS]: (state, payload: IRequest[]) => {
        state.pendingRequests = payload;
    },

    [mutationTypes.SET_POWERBI_USER_ROLES]: (state, payload: IPowerBiUserRole[]) => {
        state.userRoles = payload;
    },

    [mutationTypes.SET_VALIDATED_REQUESTS]: (state, payload: IRequest[]) => {
        state.validatedRequests = payload;
    },

    [mutationTypes.SET_POWERBI_OPTIONS]: (state, payload: IPowerBiOption[]) => {
        state.options = payload;
    },
};
