import { ActionTree } from "vuex";
import { webApiGraphClient, msalInstance } from "@/services";
import { userRepository } from "@/repositories";
import { IApp, IUserNotificationOption } from "@/models";
import { IAccountState } from "./state";
import * as mutation from "./mutations-types";

export const actions: ActionTree<IAccountState, any> = {
    logout: async (context): Promise<void> => {
        msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        context.commit(mutation.LOGOUT);
    },

    loadAccount: (context): void => {
        const account = msalInstance.getActiveAccount();
        context.commit(mutation.LOGIN, account);
    },

    loadUser: async (context): Promise<void> => {
        const user = await userRepository.getCurrentUser();
        context.commit(mutation.SET_APP_ACCOUNT, user);
    },

    loadUserPhoto: async (context): Promise<void> => {
        const uniqueId = context.state.msalAccount.localAccountId;
        const photo = await webApiGraphClient.getUserPhoto(uniqueId);
        context.commit(mutation.SET_PHOTO, photo);
    },

    setNotificationOptions: async (context, notificationOptions: IUserNotificationOption[]): Promise<void> => {
        const dbNotificationOptions = await userRepository.setNotificationOptions(notificationOptions);
        context.commit(mutation.UPDATE_NOTIFICATIONS, dbNotificationOptions);
    },

    setConditionsOfUse: async (context, appId: number): Promise<void> => {
        const conditionsOfUse = await userRepository.add({}, `/conditions-of-use/${appId}`);
        context.commit(mutation.UPDATE_CONDITIONS_OF_USE, conditionsOfUse);
    },

    toggleFavorite: async (context, payload: IApp): Promise<void> => {
        const favorites = await userRepository.replace({}, `/favorites/${payload.appId}`);
        context.commit(mutation.SET_FAVORITES, favorites);
    },

    updateUserLastLogin: async (context): Promise<void> => {
        const user = await userRepository.add({}, "me/last-login");
        context.commit(mutation.SET_APP_ACCOUNT_LAST_LOGIN, user);
    },
};
