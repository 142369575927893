import Component, { mixins } from "vue-class-component";
import { ApplicationCodeMixin } from "@/mixins";
import { IUser } from "@/models";
import { Roles } from "@/enums";

@Component
export class ApplicationUserRoleMixin extends mixins(ApplicationCodeMixin) {
    get user(): IUser {
        return this.$store.getters["account/user"];
    }

    get isApplicationAdmin(): boolean {
        const user = this.user;
        return user.roles.some(aur => aur.appId === this.application.appId && aur.roleId >= Roles.ToolAdmin);
    }

    get isApplicationValidator(): boolean {
        const user = this.user;
        return user.roles.some(aur => aur.appId === this.application.appId && aur.roleId >= Roles.SuperUser);
    }
}
