import { Module } from "vuex";
import { IGroupState, initialState } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const group: Module<IGroupState, any> = {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

export { group };
