import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const xtrtRoutes: RouteConfig[] = [
    {
        path: "/xtrt",
        component: (): any => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "manage/agency",
                name: RouteNames.xtrtSettingsAgency,
                component: () => import(/* webpackChunkName: "XtrtSettingsAgency" */ "@/views/xtrt/settings/agency/XtrtSettingsAgency.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        xtrt: true,
                    },
                },
            },
            {
                path: "manage/hierarchy",
                name: RouteNames.xtrtSettingsHierarchy,
                component: () => import(/* webpackChunkName: "XtrtSettingsHierarchy" */ "@/views/xtrt/settings/hierarchy/XtrtSettingsHierarchy.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        xtrt: true,
                    },
                },
            },
            {
                path: "manage/options",
                name: RouteNames.xtrtSettingsOptions,
                component: () => import(/* webpackChunkName: "XtrtSettingsOptions" */ "@/views/xtrt/settings/options/XtrtSettingsOptions.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 3,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        xtrt: true,
                    },
                },
            },
            {
                path: "user-access",
                name: RouteNames.xtrtUserAccess,
                component: () => import(/* webpackChunkName: "xtrtUserAccess" */ "@/views/xtrt/userAccess/XtrtUserAccess.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        xtrt: true,
                    },
                },
            },
            {
                path: "pending-requests",
                name: RouteNames.xtrtPendingRequests,
                component: () => import(/* webpackChunkName: "xtrtPendingRequest" */ "@/views/xtrt/pendingRequest/XtrtPendingRequest.vue"),
                meta: {
                    requiresMinRole: {
                        roleId: 2,
                    },
                    sidebar: {
                        logo: true,
                        description: true,
                        xtrt: true,
                    },
                },
            },
            {
                path: "permissions",
                name: RouteNames.xtrtHome,
                component: () => import(/* webpackChunkName: "xtrtHome" */ "@/views/xtrt/home/XtrtHome.vue"),
                meta: {
                    sidebar: {
                        logo: true,
                        description: true,
                        xtrt: true,
                    },
                },
            },
        ],
        meta: {
            code: "xtrt",
            requiresMinRole: {
                roleId: 1,
                appId: 9,
            },
        },
    },
];
