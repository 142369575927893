import { ActionTree } from "vuex";
import { ISgpState } from "./state";
import * as mutation from "./mutations-types";
import { attributeRepository, sharePointAccountRepository, synchronizationRepository } from "@/repositories";
import { IAttribute, ISharePointAccount, ISynchronization } from "@/models";

export const actions: ActionTree<ISgpState, any> = {
    addAttribute: async (context, payload: IAttribute): Promise<void> => {
        const attribute = await attributeRepository.add(payload);
        context.commit(mutation.ADD_ATTRIBUTE, attribute);
    },

    addSharePointAccount: async (context, payload: ISharePointAccount): Promise<void> => {
        const sharePointAccount = await sharePointAccountRepository.add(payload);
        context.commit(mutation.ADD_SHARE_POINT_ACCOUNT, sharePointAccount);
    },

    addSynchronization: async (context, payload: ISynchronization): Promise<void> => {
        const synchronization = await synchronizationRepository.add(payload);
        context.commit(mutation.ADD_SYNCHRONIZATION, synchronization);
    },

    deleteAttribute: async (context, attribute: IAttribute): Promise<void> => {
        const attributes = await attributeRepository.remove(attribute.attributeId);
        context.commit(mutation.SET_ATTRIBUTES, attributes);
    },

    deleteSharePointAccount: async (context, sharePointAccount: ISharePointAccount): Promise<void> => {
        const sharePointAccounts = await sharePointAccountRepository.remove(sharePointAccount.sharePointAccountId);
        context.commit(mutation.SET_SHARE_POINT_ACCOUNTS, sharePointAccounts);
    },

    deleteSynchronization: async (context, synchronization: ISynchronization): Promise<void> => {
        const synchronizations = await synchronizationRepository.remove(synchronization.synchronizationId);
        context.commit(mutation.SET_SYNCHRONIZATIONS, synchronizations);
    },

    loadAttributes: async (context): Promise<void> => {
        const attributes = await attributeRepository.getAll();
        context.commit(mutation.SET_ATTRIBUTES, attributes);
    },

    loadSharePointAccounts: async (context): Promise<void> => {
        const sharePointAccounts = await sharePointAccountRepository.getAll();
        context.commit(mutation.SET_SHARE_POINT_ACCOUNTS, sharePointAccounts);
    },

    loadSynchronizations: async (context): Promise<void> => {
        const synchronizations = await synchronizationRepository.getAll();
        context.commit(mutation.SET_SYNCHRONIZATIONS, synchronizations);
    },

    updateAttribute: async (context, attribute: IAttribute): Promise<void> => {
        const updatedAttribute = await attributeRepository.update(attribute, `/${attribute.attributeId}`);
        context.commit(mutation.UPDATE_ATTRIBUTE, updatedAttribute);
    },

    updateSharePointAccount: async (context, sharePointAccount: ISharePointAccount): Promise<void> => {
        const updatedSharePointAccount = await sharePointAccountRepository.update(sharePointAccount, `/${sharePointAccount.sharePointAccountId}`);
        context.commit(mutation.UPDATE_SHARE_POINT_ACCOUNT, updatedSharePointAccount);
    },

    updateSynchronization: async (context, synchronization: ISynchronization): Promise<void> => {
        const updatedSynchronization = await synchronizationRepository.update(synchronization, `/${synchronization.synchronizationId}`);
        context.commit(mutation.UPDATE_SYNCHRONIZATION, updatedSynchronization);
    },
};
