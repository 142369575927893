import { IEntity, IMasterData, IMasterRole, IMdhRequest, IMdhUserRole } from "@/models";

interface IMdhState {
    masterDatas: IMasterData[];
    myRequests: IMdhRequest[];
    pendingRequests: IMdhRequest[];
    roles: IMasterRole[];
    secretKeys: IEntity[];
    userRoles: IMdhUserRole[];
    validatedRequests: IMdhRequest[];
}

const initialState: IMdhState = {
    masterDatas: [],
    myRequests: [],
    pendingRequests: [],
    roles: [],
    secretKeys: [],
    userRoles: [],
    validatedRequests: [],
};

export { IMdhState, initialState };
