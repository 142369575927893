import { IPolarisBiCoreProject } from "@/models";

import { BaseRepository } from "@/repositories";

class PolarisBiCoreProjectRepository extends BaseRepository<IPolarisBiCoreProject> {
    protected getAllErrorMessage = "Core Projects cannot be retrieved.";

    protected getOneErrorMessage = "This Core Project cannot be retrieved.";

    protected addErrorMessage = "This Core Project cannot be created, please verify fields.";

    protected updateErrorMessage = "This Core Project cannot be updated, please verify fields.";

    protected removeErrorMessage = "This Core Project cannot be removed.";

    constructor() {
        super("/polarisbi/core-projects");
    }
}

const polarisBiCoreProjectRepository = new PolarisBiCoreProjectRepository();

export { PolarisBiCoreProjectRepository, polarisBiCoreProjectRepository };
