import { GetterTree } from "vuex";
import { IPolarisBiState } from "./state";
import {
    IPolarisBiCoreProject,
    IPolarisBiCostCenter,
    IPolarisBiFunction,
    IPolarisBiMarket,
    IPolarisBiModule,
    IPolarisBiPnl,
    IPolarisBiProduct,
    IPolarisBiProductUserRole,
    IPolarisBiProject,
    IPolarisBiRequest,
    IPolarisBiUserRole,
    IUserGraph,
} from "@/models";

const getters: GetterTree<IPolarisBiState, any> = {

    coreProjects: (state): IPolarisBiCoreProject[] => state.coreProjects,

    costCenters: (state): IPolarisBiCostCenter[] => state.costCenters,

    functions: (state): IPolarisBiFunction[] => state.functions,

    markets: (state): IPolarisBiMarket[] => state.markets,

    modules: (state): IPolarisBiModule[] => state.modules,

    myRequests: (state): IPolarisBiRequest[] => state.myRequests,

    pendingRequests: (state): IPolarisBiRequest[] => state.pendingRequests,

    pnls: (state): IPolarisBiPnl[] => state.pnls,

    prGroupValidators: (state): IUserGraph[] => state.prGroupValidators,

    products: (state): IPolarisBiProduct[] => state.products,

    productUserRoles: (state): IPolarisBiProductUserRole[] => state.productUserRoles,

    projects: (state): IPolarisBiProject[] => state.projects,

    userRoles: (state): IPolarisBiUserRole[] => state.userRoles,

    validatedRequests: (state): IPolarisBiRequest[] => state.validatedRequests,
};

export { getters };
